import {
  NotificationType,
  showNotification,
} from 'src/Redux/Slices/notificationSlice'
import { Button, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { Key, useEffect, useState } from 'react'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import { AddButton, ApplicationIntroBox, StarText } from 'src/Components/Fields'
import { MedicalProfessionalDetailType } from 'src/Redux/Services/Types/applicationType'
import { AddMedicalProfessionalDetail } from 'src/Redux/Slices/ApplicationSlices/MedicalDetailsSlice'
import { setMedicalProfessionalDetail } from 'src/Redux/Slices/ApplicationStore/MedicalDetailsStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import Style from './index.module.scss'
import MentalDetailsBox from './MentalDetailsBox'
import PhysicalDetailsBox from './PhysicalDetailsBox'

const MedicalProfessionals = ({
  handleEditToggle,
}: {
  setDetailId?: React.Dispatch<React.SetStateAction<string>>
  handleEditToggle: () => void
}) => {
  const dispatch = useAppDispatch()

  const MedicalDetailId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.MedicalDetailId,
  )
  const MedicalProfessionalsState = useAppSelector(
    (state) =>
      state?.applicationStore?.MedicalDetails?.MedicalProfessionalDetail,
  )

  const [formValues, setFormValues] = useState<MedicalProfessionalDetailType>({
    medicalDetailId: MedicalDetailId,
    isMentalIllness: undefined,
    mentalIllnessMedicalProfessionals: [
      {
        suffix: '',
        doctorFirstName: '',
        doctorLastName: '',
        doctorMedicalSpecialty: '',
        officeOrClinicName: '',
        address: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        phoneNumber: '',
        firstAppointmentDate: '',
        lastAppointmentDate: '',
        nextAppointmentDate: '',
        treatmentType: [],
        otherTreatment: '',
        typeOfTherapyDoctorProvided: [],
        otherTypeOfTherapyDetails: '',
        specialProcedureDetail: '',
      },
    ],
    isPhysicalIllness: undefined,
    physicalIllnessMedicalProfessionals: [
      {
        suffix: '',
        doctorFirstName: '',
        doctorLastName: '',
        doctorMedicalSpecialty: '',
        officeOrClinicName: '',
        address: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        phoneNumber: '',
        firstAppointmentDate: '',
        lastAppointmentDate: '',
        nextAppointmentDate: '',
        treatmentType: [],
        otherTreatment: '',
        typeOfTherapyDoctorProvided: [],
        otherTypeOfTherapyDetails: '',
        specialProcedureDetail: '',
      },
    ],
  })

  useEffect(() => {
    setFormValues({
      ...MedicalProfessionalsState,
      medicalDetailId: MedicalDetailId,
    })
  }, [])

  const [errors, setErrors] = useState<
    Record<string, string | Array<Record<string, string>>>
  >({
    mentalIllnessMedicalProfessionals: [{}],
    physicalIllnessMedicalProfessionals: [{}],
  })

  // const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target
  //   setFormValues({ ...formValues, [name]: value })
  //   // You may perform validation here and update errors state accordingly
  // }

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string | Array<Record<string, string>>> = {
      mentalIllnessMedicalProfessionals: [{}],
      physicalIllnessMedicalProfessionals: [{}],
    }
    const currentDate = new Date().toISOString().split('T')[0]
    if (formValues?.isMentalIllness?.valueOf() === undefined) {
      newErrors.isMentalIllness = 'Please select an option'
      valid = false
    }
    if (formValues?.isPhysicalIllness?.valueOf() === undefined) {
      newErrors.isPhysicalIllness = 'Please select an option'
      valid = false
    }

    if (formValues?.isMentalIllness) {
      for (
        let i = 0;
        i < formValues.mentalIllnessMedicalProfessionals.length;
        i++
      ) {
        if (!formValues.mentalIllnessMedicalProfessionals[i].suffix.trim()) {
          newErrors.mentalIllnessMedicalProfessionals =
            newErrors.mentalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    suffix: 'Suffix is required',
                  }
                return error
              },
            )
          valid = false
        }
        if (
          !formValues.mentalIllnessMedicalProfessionals[
            i
          ].doctorMedicalSpecialty.trim()
        ) {
          newErrors.mentalIllnessMedicalProfessionals =
            newErrors.mentalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    doctorMedicalSpecialty:
                      'Medical professional Speciality is required',
                  }
                return error
              },
            )
          valid = false
        }
        if (
          formValues.mentalIllnessMedicalProfessionals[i].treatmentType
            .length === 0
        ) {
          newErrors.mentalIllnessMedicalProfessionals =
            newErrors.mentalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    treatmentType: 'Treatment Type is required',
                  }
                return error
              },
            )
          valid = false
        }
        if (
          !formValues.mentalIllnessMedicalProfessionals[
            i
          ].doctorFirstName.trim()
        ) {
          newErrors.mentalIllnessMedicalProfessionals =
            newErrors.mentalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return { ...error, doctorFirstName: 'First Name is required' }
                return error
              },
            )
          valid = false
        }
        if (
          !formValues.mentalIllnessMedicalProfessionals[i].doctorLastName.trim()
        ) {
          newErrors.mentalIllnessMedicalProfessionals =
            newErrors.mentalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return { ...error, doctorLastName: 'Last Name is required' }
                return error
              },
            )
          valid = false
        }
        if (
          !formValues.mentalIllnessMedicalProfessionals[
            i
          ].doctorMedicalSpecialty.trim()
        ) {
          newErrors.mentalIllnessMedicalProfessionals =
            newErrors.mentalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    doctorMedicalSpecialty: 'Specialty is required',
                  }
                return error
              },
            )
          valid = false
        }

        if (
          !formValues.mentalIllnessMedicalProfessionals[
            i
          ].officeOrClinicName.trim()
        ) {
          newErrors.mentalIllnessMedicalProfessionals =
            newErrors.mentalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    officeOrClinicName: 'ClinicName is required',
                  }
                return error
              },
            )
          valid = false
        }

        if (
          !formValues.mentalIllnessMedicalProfessionals[i].phoneNumber.trim()
        ) {
          newErrors.mentalIllnessMedicalProfessionals =
            newErrors.mentalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return { ...error, phoneNumber: 'Phone number is required' }
                return error
              },
            )
          valid = false
        }

        if (
          !formValues.mentalIllnessMedicalProfessionals[i].firstAppointmentDate
        ) {
          newErrors.mentalIllnessMedicalProfessionals =
            newErrors.mentalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    firstAppointmentDate: 'First Appointment Date is required',
                  }
                return error
              },
            )
          valid = false
        }
        if (
          formValues.mentalIllnessMedicalProfessionals[i].firstAppointmentDate >
          currentDate
        ) {
          newErrors.mentalIllnessMedicalProfessionals =
            newErrors.mentalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    firstAppointmentDate:
                      'First Appointment Date cannot be in the future',
                  }
                return error
              },
            )
          valid = false
        }
        if (
          !formValues.mentalIllnessMedicalProfessionals[i].lastAppointmentDate
        ) {
          newErrors.mentalIllnessMedicalProfessionals =
            newErrors.mentalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    lastAppointmentDate: 'Last Appointment Date is required',
                  }
                return error
              },
            )
          valid = false
        }
        if (
          formValues.mentalIllnessMedicalProfessionals[i].lastAppointmentDate >
          currentDate
        ) {
          newErrors.mentalIllnessMedicalProfessionals =
            newErrors.mentalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    lastAppointmentDate:
                      'Last Appointment Date cannot be in the future',
                  }
                return error
              },
            )
          valid = false
        }
        if (
          !formValues.mentalIllnessMedicalProfessionals[i].nextAppointmentDate
        ) {
          newErrors.mentalIllnessMedicalProfessionals =
            newErrors.mentalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    nextAppointmentDate: 'Next Appointment Date is required',
                  }
                return error
              },
            )
          valid = false
        }
        if (
          formValues.mentalIllnessMedicalProfessionals[i].nextAppointmentDate >
          currentDate
        ) {
          newErrors.mentalIllnessMedicalProfessionals =
            newErrors.mentalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    nextAppointmentDate:
                      'Next Appointment Date cannot be in the future',
                  }
                return error
              },
            )
          valid = false
        }

        if (
          !formValues.mentalIllnessMedicalProfessionals[
            i
          ].address.streetAddress.trim()
        ) {
          newErrors.mentalIllnessMedicalProfessionals =
            newErrors.mentalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    streetAddress: 'Street Address is required',
                  }
                return error
              },
            )
          valid = false
        }
        if (
          !formValues.mentalIllnessMedicalProfessionals[i].address.city.trim()
        ) {
          newErrors.mentalIllnessMedicalProfessionals =
            newErrors.mentalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index) return { ...error, city: 'City is required' }
                return error
              },
            )
          valid = false
        }
        if (
          !formValues.mentalIllnessMedicalProfessionals[i].address.state.trim()
        ) {
          newErrors.mentalIllnessMedicalProfessionals =
            newErrors.mentalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index) return { ...error, state: 'State is required' }
                return error
              },
            )
          valid = false
        }
        if (
          !formValues.mentalIllnessMedicalProfessionals[
            i
          ].address.postalCode.trim()
        ) {
          newErrors.mentalIllnessMedicalProfessionals =
            newErrors.mentalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return { ...error, postalCode: 'Postal Code is required' }
                return error
              },
            )
          valid = false
        }
        if (
          !formValues.mentalIllnessMedicalProfessionals[
            i
          ].address.country.trim()
        ) {
          newErrors.mentalIllnessMedicalProfessionals =
            newErrors.mentalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return { ...error, country: 'Country is required' }
                return error
              },
            )
          valid = false
        }
      }
    }
    if (formValues?.isPhysicalIllness) {
      for (
        let i = 0;
        i < formValues.physicalIllnessMedicalProfessionals.length;
        i++
      ) {
        if (!formValues.physicalIllnessMedicalProfessionals[i].suffix.trim()) {
          newErrors.physicalIllnessMedicalProfessionals =
            newErrors.physicalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    suffix: 'Suffix is required',
                  }
                return error
              },
            )
          valid = false
        }
        if (
          !formValues.physicalIllnessMedicalProfessionals[
            i
          ].doctorMedicalSpecialty.trim()
        ) {
          newErrors.physicalIllnessMedicalProfessionals =
            newErrors.physicalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    doctorMedicalSpecialty:
                      'Medical professional Speciality is required',
                  }
                return error
              },
            )
          valid = false
        }
        if (
          formValues.physicalIllnessMedicalProfessionals[i].treatmentType
            .length === 0
        ) {
          newErrors.physicalIllnessMedicalProfessionals =
            newErrors.physicalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    treatmentType: 'Treatment Type is required',
                  }
                return error
              },
            )
          valid = false
        }
        if (
          !formValues.physicalIllnessMedicalProfessionals[
            i
          ].doctorFirstName.trim()
        ) {
          newErrors.physicalIllnessMedicalProfessionals =
            newErrors.physicalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return { ...error, doctorFirstName: 'First Name is required' }
                return error
              },
            )
          valid = false
        }
        if (
          !formValues.physicalIllnessMedicalProfessionals[
            i
          ].doctorLastName.trim()
        ) {
          newErrors.physicalIllnessMedicalProfessionals =
            newErrors.physicalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return { ...error, doctorLastName: 'Last Name is required' }
                return error
              },
            )
          valid = false
        }
        if (
          !formValues.physicalIllnessMedicalProfessionals[
            i
          ].doctorMedicalSpecialty.trim()
        ) {
          newErrors.physicalIllnessMedicalProfessionals =
            newErrors.physicalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    doctorMedicalSpecialty: 'Specialty is required',
                  }
                return error
              },
            )
          valid = false
        }

        if (
          !formValues.physicalIllnessMedicalProfessionals[
            i
          ].officeOrClinicName.trim()
        ) {
          newErrors.physicalIllnessMedicalProfessionals =
            newErrors.physicalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    officeOrClinicName: 'ClinicName is required',
                  }
                return error
              },
            )
          valid = false
        }

        if (
          !formValues.physicalIllnessMedicalProfessionals[i].phoneNumber.trim()
        ) {
          newErrors.physicalIllnessMedicalProfessionals =
            newErrors.physicalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return { ...error, phoneNumber: 'Phone number is required' }
                return error
              },
            )
          valid = false
        }

        if (
          !formValues.physicalIllnessMedicalProfessionals[i]
            .firstAppointmentDate
        ) {
          newErrors.physicalIllnessMedicalProfessionals =
            newErrors.physicalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    firstAppointmentDate: 'First Appointment Date is required',
                  }
                return error
              },
            )
          valid = false
        }
        if (
          formValues.physicalIllnessMedicalProfessionals[i]
            .firstAppointmentDate > currentDate
        ) {
          newErrors.physicalIllnessMedicalProfessionals =
            newErrors.physicalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    firstAppointmentDate:
                      'First Appointment Date cannot be in the future',
                  }
                return error
              },
            )
          valid = false
        }
        if (
          !formValues.physicalIllnessMedicalProfessionals[i].lastAppointmentDate
        ) {
          newErrors.physicalIllnessMedicalProfessionals =
            newErrors.physicalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    lastAppointmentDate: 'Last Appointment Date is required',
                  }
                return error
              },
            )
          valid = false
        }
        if (
          formValues.physicalIllnessMedicalProfessionals[i]
            .lastAppointmentDate > currentDate
        ) {
          newErrors.physicalIllnessMedicalProfessionals =
            newErrors.physicalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    lastAppointmentDate:
                      'Last Appointment Date cannot be in the future',
                  }
                return error
              },
            )
          valid = false
        }
        if (
          !formValues.physicalIllnessMedicalProfessionals[i].nextAppointmentDate
        ) {
          newErrors.physicalIllnessMedicalProfessionals =
            newErrors.physicalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    nextAppointmentDate: 'Next Appointment Date is required',
                  }
                return error
              },
            )
          valid = false
        }
        if (
          formValues.physicalIllnessMedicalProfessionals[i]
            .nextAppointmentDate > currentDate
        ) {
          newErrors.physicalIllnessMedicalProfessionals =
            newErrors.physicalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    nextAppointmentDate:
                      'Next Appointment Date cannot be in the future',
                  }
                return error
              },
            )
          valid = false
        }

        if (
          !formValues.physicalIllnessMedicalProfessionals[
            i
          ].address.streetAddress.trim()
        ) {
          newErrors.physicalIllnessMedicalProfessionals =
            newErrors.physicalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    streetAddress: 'Street Address is required',
                  }
                return error
              },
            )
          valid = false
        }
        if (
          !formValues.physicalIllnessMedicalProfessionals[i].address.city.trim()
        ) {
          newErrors.physicalIllnessMedicalProfessionals =
            newErrors.physicalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index) return { ...error, city: 'City is required' }
                return error
              },
            )
          valid = false
        }
        if (
          !formValues.physicalIllnessMedicalProfessionals[
            i
          ].address.state.trim()
        ) {
          newErrors.physicalIllnessMedicalProfessionals =
            newErrors.physicalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index) return { ...error, state: 'State is required' }
                return error
              },
            )
          valid = false
        }
        if (
          !formValues.physicalIllnessMedicalProfessionals[
            i
          ].address.postalCode.trim()
        ) {
          newErrors.physicalIllnessMedicalProfessionals =
            newErrors.physicalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return { ...error, postalCode: 'Postal Code is required' }
                return error
              },
            )
          valid = false
        }
        if (
          !formValues.physicalIllnessMedicalProfessionals[
            i
          ].address.country.trim()
        ) {
          newErrors.physicalIllnessMedicalProfessionals =
            newErrors.physicalIllnessMedicalProfessionals?.map(
              (error: any, index: number) => {
                if (i === index)
                  return { ...error, country: 'Country is required' }
                return error
              },
            )
          valid = false
        }
      }
    }
    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    // Handle form submission here, you can access formValues to submit data
    if (!validateForm()) {
      dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            'Some fields are incorrect or empty. Please review form again.',
        }),
      )
    } else {
      const submitValues: MedicalProfessionalDetailType = {}
      submitValues.medicalDetailId = MedicalDetailId
      submitValues.isMentalIllness = formValues.isMentalIllness
      submitValues.isPhysicalIllness = formValues.isPhysicalIllness
      if (formValues?.isPhysicalIllness) {
        submitValues.physicalIllnessMedicalProfessionals =
          formValues.physicalIllnessMedicalProfessionals
      }
      if (formValues?.isMentalIllness) {
        submitValues.mentalIllnessMedicalProfessionals =
          formValues.mentalIllnessMedicalProfessionals
      }
      dispatch(AddMedicalProfessionalDetail(submitValues)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res?.payload?.success) {
            dispatch(setMedicalProfessionalDetail(formValues))
            handleEditToggle()
          }
        },
      )
    }
  }

  // const handleDetailBoxValuesChange = (
  //   index: number,
  //   data: { name: string; value: string },
  // ) => {
  //   const newProfileForms = [...formValues?.medicalProfessionals]
  //   newProfileForms[`${index}`][`${data.name}`] = data.value
  //   setFormValues({ ...formValues, medicalProfessionals: newProfileForms })
  // }

  const handleMentalBoxValuesChange = (
    index: number,
    data: { name: string; value: string },
  ) => {
    let newProfileForms = [...formValues.mentalIllnessMedicalProfessionals]
    newProfileForms = newProfileForms?.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [data.name]: data.value,
        }
      }
      return item
    })
    setFormValues({
      ...formValues,
      mentalIllnessMedicalProfessionals: newProfileForms,
    })
  }

  const handleMentalBoxRemove = (index: number) => {
    const newProfileForms = [...formValues.mentalIllnessMedicalProfessionals]
    newProfileForms.splice(index, 1)
    setFormValues({
      ...formValues,
      mentalIllnessMedicalProfessionals: newProfileForms,
    })
  }

  const addMentalBox = () => {
    if (formValues.mentalIllnessMedicalProfessionals?.length)
      setFormValues({
        ...formValues,
        mentalIllnessMedicalProfessionals: [
          ...formValues.mentalIllnessMedicalProfessionals,
          {
            suffix: '',
            doctorFirstName: '',
            doctorLastName: '',
            doctorMedicalSpecialty: '',
            officeOrClinicName: '',
            address: {
              streetAddress: '',
              city: '',
              state: '',
              postalCode: '',
              country: '',
            },
            phoneNumber: '',
            firstAppointmentDate: '',
            lastAppointmentDate: '',
            nextAppointmentDate: '',
            treatmentType: [],
            typeOfTherapyDoctorProvided: [],
            otherTreatment: '',
            otherTypeOfTherapyDetails: '',
            specialProcedureDetail: '',
          },
        ],
      })
    else
      setFormValues({
        ...formValues,
        mentalIllnessMedicalProfessionals: [
          {
            suffix: '',
            doctorFirstName: '',
            doctorLastName: '',
            doctorMedicalSpecialty: '',
            officeOrClinicName: '',
            address: {
              streetAddress: '',
              city: '',
              state: '',
              postalCode: '',
              country: '',
            },
            phoneNumber: '',
            firstAppointmentDate: '',
            lastAppointmentDate: '',
            nextAppointmentDate: '',
            treatmentType: [],
            typeOfTherapyDoctorProvided: [],
            otherTreatment: '',
            otherTypeOfTherapyDetails: '',
            specialProcedureDetail: '',
          },
        ],
      })
  }

  // -----------

  const handlePhysicalBoxValuesChange = (
    index: number,
    data: { name: string; value: string },
  ) => {
    let newProfileForms = [...formValues.physicalIllnessMedicalProfessionals]
    newProfileForms = newProfileForms?.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [data.name]: data.value,
        }
      }
      return item
    })
    setFormValues({
      ...formValues,
      physicalIllnessMedicalProfessionals: newProfileForms,
    })
  }

  const handlePhysicalBoxRemove = (index: number) => {
    const newProfileForms = [...formValues.physicalIllnessMedicalProfessionals]
    newProfileForms.splice(index, 1)
    setFormValues({
      ...formValues,
      physicalIllnessMedicalProfessionals: newProfileForms,
    })
  }

  const addPhysicalBox = () => {
    if (formValues.physicalIllnessMedicalProfessionals?.length)
      setFormValues({
        ...formValues,
        physicalIllnessMedicalProfessionals: [
          ...formValues.physicalIllnessMedicalProfessionals,
          {
            suffix: '',
            doctorFirstName: '',
            doctorLastName: '',
            doctorMedicalSpecialty: '',
            officeOrClinicName: '',
            address: {
              streetAddress: '',
              city: '',
              state: '',
              postalCode: '',
              country: '',
            },
            phoneNumber: '',
            firstAppointmentDate: '',
            lastAppointmentDate: '',
            nextAppointmentDate: '',
            treatmentType: [],
            typeOfTherapyDoctorProvided: [],
            otherTreatment: '',
            otherTypeOfTherapyDetails: '',
            specialProcedureDetail: '',
          },
        ],
      })
    else
      setFormValues({
        ...formValues,
        physicalIllnessMedicalProfessionals: [
          {
            suffix: '',
            doctorFirstName: '',
            doctorLastName: '',
            doctorMedicalSpecialty: '',
            officeOrClinicName: '',
            address: {
              streetAddress: '',
              city: '',
              state: '',
              postalCode: '',
              country: '',
            },
            phoneNumber: '',
            firstAppointmentDate: '',
            lastAppointmentDate: '',
            nextAppointmentDate: '',
            treatmentType: [],
            typeOfTherapyDoctorProvided: [],
            otherTreatment: '',
            otherTypeOfTherapyDetails: '',
            specialProcedureDetail: '',
          },
        ],
      })
  }

  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <ApplicationIntroBox
            heading="We appreciate you sharing these details with us.
        Now, let's switch gears to better understand if you've seen or been treated
        by at least one medical professional or medical professional for any conditions since
        you've been unable to work."
          />

          {/* <StarText
            text={`Be sure to have your medical records handy for this section
              because specific names, dates, and types of treatment or tests
              will be gathered.`}
          /> */}

          <CommonRadioBox
            error={errors?.isMentalIllness}
            title='Have you seen a medical professional or been treated for any mental illnesses, injuries, or conditions since youve been unable to work?'
            commonState={formValues.isMentalIllness}
            setcommonState={(value: boolean) => {
              setFormValues({ ...formValues, isMentalIllness: value })
            }}
            YesTitle='I have seen a medical professional and have been treated for mental illnesses, injuries, or conditions since I have been unable to work'
            NoTitle='I have not seen a medical professional or been treated for any mental illnesses, injuries, or conditions since I have been unable to work'
          />

          {formValues.isMentalIllness ? (
            <>
              <Box
                style={{
                  marginTop: '20px',
                  marginBottom: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {/* <Typography className={Style.label}>
                You said that you've seen or been treated for mental and/or
                physical illnesses, injuries, or conditions. Please provide
                details for each medical professional who has evaluated,
                treated, ordered testing, or prescribed medication to you
                since being diagnosed or unable to work. Repeat this step for
                each medical professional.
              </Typography> */}

                {formValues?.mentalIllnessMedicalProfessionals?.map(
                  (form, index: Key | null | undefined) => (
                    <MentalDetailsBox
                      key={index}
                      realValue={formValues}
                      handleRemove={() => {
                        handleMentalBoxRemove(index)
                      }}
                      errors={errors.mentalIllnessMedicalProfessionals[index]}
                      index={index}
                      formValues={form}
                      changeHandler={(data: {
                        name: string
                        value: string
                      }) => {
                        handleMentalBoxValuesChange(index, data)
                      }}
                      setFormValues={setFormValues}
                    />
                  ),
                )}

                <AddButton
                  title='Medical professionals for mental illness, injury, or conditions'
                  subTitle='For comprehensive representation of your situation, It is important that all
                service be shown below'
                  button='Add Medical Professional'
                  onClick={addMentalBox}
                />
              </Box>
            </>
          ) : null}

          {/* <StarText
            text={`We're sorry to hear this. What was the date you were no longer physically able to work?`}
          /> */}

          <CommonRadioBox
            error={errors?.isPhysicalIllness}
            title='Have you seen at least one medical professional or been treated for any physical illnesses, injuries, or conditions since you have been unable to work?'
            commonState={formValues.isPhysicalIllness}
            setcommonState={(value: boolean) => {
              setFormValues({ ...formValues, isPhysicalIllness: value })
            }}
            YesTitle='I have seen at least one medical professional and have been treated for physical illnesses, injuries, or conditions since I have been unable to work'
            NoTitle='I have not seen at least one medical professional or been treated for any physical illnesses, injuries, or conditions since I have been unable to work'
          />
          {formValues.isPhysicalIllness ? (
            <>
              <StarText
                text={`Physical conditions, illnesses, or injuries refer to those that impact one or more body systems.`}
              />
              <Box sx={{ width: '100%', height: '20px' }} />
              <Typography className={Style.label}>
                Now, we need to gather information on all of the medical
                professionals who have evaluated, treated, ordered testing, or
                prescribed medication to you for your conditions.
              </Typography>

              <Box
                style={{
                  marginTop: '20px',
                  marginBottom: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography className={Style.label}>
                  You said that you've seen or been treated for mental and/or
                  physical illnesses, injuries, or conditions. Please provide
                  details for each medical professional who has evaluated,
                  treated, ordered testing, or prescribed medication to you
                  since being diagnosed or unable to work. Repeat this step for
                  each medical professional.
                </Typography>

                {formValues?.physicalIllnessMedicalProfessionals?.map(
                  (form, index: Key | null | undefined) => (
                    <PhysicalDetailsBox
                      key={index}
                      realValue={formValues}
                      handleRemove={() => {
                        handlePhysicalBoxRemove(index)
                      }}
                      errors={errors.physicalIllnessMedicalProfessionals[index]}
                      index={index}
                      formValues={form}
                      changeHandler={(data: {
                        name: string
                        value: string
                      }) => {
                        handlePhysicalBoxValuesChange(index, data)
                      }}
                      setFormValues={setFormValues}
                    />
                  ),
                )}

                <AddButton
                  title='Medical professionals for physical illness, injury, or conditions'
                  subTitle='For comprehensive representation of your situation, It is important that all
                service be shown below'
                  button='Add Medical Professional'
                  onClick={addPhysicalBox}
                />
              </Box>
            </>
          ) : null}
          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={() => {
                handleEditToggle()
              }}
            >
              Discard
            </Button>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
            >
              Save & Update
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default MedicalProfessionals
