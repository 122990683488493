import { Box, Button, Grid, InputLabel, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { ApplicationIntroBox } from 'src/Components/Fields'
import { UploadDocumentType } from 'src/Redux/Services/Types/uploadDocumentType'
import {
  AddUploadDocument,
  EditUploadDocument,
  GetUploadDocument,
} from 'src/Redux/Slices/ApplicationSlices/uploadDocumentSlice'
import { setUploadDocumentId } from 'src/Redux/Slices/ApplicationStore/ApplicationIdStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import UploadField from './UploadField'
import Style from './index.module.scss'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/Utils/Routes'

const UploadDocuments = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const titles = [
    'Add Employer W2 (Up to 1 year prior ro being diagnosed or being out of work)',
    'Marriage Documentation',
    'Address Documentation',
    'Birth Documentation',
    'Employment Documentation',
    'Education or Training Documentation',
    'Citizenship Documentation (If U.S. resident or naturalized citizen)',
  ]
  const documentId: string = useAppSelector(
    (state) => state.applicationStore.ApplicationId?.uploadDocumentId,
  )
  const applicationId: string = useAppSelector(
    (state) => state.applicationStore.ApplicationId?.applicationId,
  )
  const [formValues, setFormValues] = useState<UploadDocumentType>({
    employerW2: [],
    marriage: [],
    address: [],
    birth: [],
    employment: [],
    educationOrTraining: [],
    citizenship: [],
  })
  const [getData, setGetData] = useState<{
    documentUrls?: Array<{
      url: string
      name: string
      isUpdated: boolean
    }>
  }>({})
  const [errors, setErrors] = useState<Record<string, string>>({
    employerW2: '',
  })

  const validate = () => {
    const newErrors: Record<string, string> = {}
    let valid = true
    if (formValues.employerW2.length === 0) {
      newErrors.employerW2 = 'Employer W2 document is required'
      valid = false
    }
    setErrors(newErrors)
    return valid
  }

  useEffect(() => {
    const keys = Object.keys(formValues)
    const newData = { ...formValues }
    for (let index = 0; index < keys.length; index++) {
      const key = keys[index] as keyof typeof formValues
      newData[key] =
        getData.documentUrls
          ?.filter((item) => item.name === key)
          .map((item) => {
            return item.url
          }) ?? []
    }
    setFormValues(newData)
  }, [getData])
  useEffect(() => {
    if (documentId)
      dispatch(GetUploadDocument(documentId)).then((res) => {
        if (res.payload?.success) {
          setGetData(res.payload?.data)
        }
      })
  }, [])

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('applicationId', applicationId)
    const urls = getData.documentUrls
    const notRemoved: typeof urls = []
    for (let index = 0; index < Object.keys(formValues).length; index++) {
      const element = Object.keys(formValues)[index] as keyof typeof formValues
      for (let index = 0; index < formValues[element].length; index++) {
        typeof formValues[element][index] === 'string'
          ? notRemoved.push(
              urls?.find(
                (item) => item.url === formValues[element][index],
              ) as any,
            )
          : formData.append(element, formValues[element][index])
      }
    }
    const toBeRemoved = urls?.filter((item) => !notRemoved.includes(item))
    if (toBeRemoved?.length) {
      formData.append('removedDocuments', JSON.stringify(toBeRemoved))
    }
    if (validate()) {
      if (documentId) {
        formData.append('uploadDocumentId', documentId)
        dispatch(EditUploadDocument(formData)).then((res) => {
          if (res.payload?.success) {
            navigate(ROUTES.SIGN_CONSENT_FORM)
          }
        })
      } else {
        dispatch(AddUploadDocument(formData)).then((res) => {
          if (res.payload?.success) {
            dispatch(setUploadDocumentId(res.payload?.data?.uploadDocumentId))
            navigate(ROUTES.SIGN_CONSENT_FORM)
          }
        })
      }
    }
    // setUpload([])
    // setUploadPreview([])
    // setMarriage([])
    // setMarriagePreview([])
  }
  return (
    <>
      <Box className={Style.ContactBox}>
        <Grid className={Style.ContactnGrid}>
          <Typography className={Style.ContactTitle}>
            Upload Documents
          </Typography>
        </Grid>
      </Box>
      {process.env.REACT_APP_MILESTONE_4 ? (
        <Box className={Style.DialogContentBox}>
          <Box className={Style.GridBox}>
            <form className={Style.Form} onSubmit={handleSubmit}>
              <ApplicationIntroBox heading='Please upload the following documentation to assist in your disability application review.' />

              <InputLabel className={Style.label}>
                Required Documents
              </InputLabel>
              {Object.keys(formValues).map((key, index) => {
                return (
                  <>
                    <UploadField
                      key={index}
                      title={titles[index]}
                      setUpload={(upload: Array<File | string>) => {
                        setFormValues({ ...formValues, [key]: upload })
                        setErrors({ ...errors, [key]: '' })
                      }}
                      upload={formValues[key as keyof typeof formValues]}
                      error={errors[key]}
                    />
                    {index === 0 && (
                      <>
                        <Box width={'100%'} height={'20px'} />
                        <InputLabel className={Style.label}>
                          Additional Documents
                        </InputLabel>
                      </>
                    )}
                  </>
                )
              })}
              <Box className={Style.AddButton}>
                <div></div>
                <Button
                  variant='contained'
                  type='submit'
                  className={Style.ButtonTwo}
                >
                  Save & Continue
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  )
}

export default UploadDocuments
