import {
  NotificationType,
  showNotification,
} from 'src/Redux/Slices/notificationSlice'
import {
  Button,
  InputLabel,
  TextField,
  Typography,
  Select,
  styled,
  IconButton,
  MenuItem,
  FormHelperText,
} from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Style from './index.module.scss'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import quote from 'src/Assets/Icons/quots.svg'
import star from 'src/Assets/Icons/star.svg'
import IconChevronDown from 'src/Assets/Icons/IconChevronDown.svg'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import { AddReceivingDisability } from 'src/Redux/Slices/ApplicationSlices/LegalDetailsSlice'
import { setReceivingDisability } from 'src/Redux/Slices/ApplicationStore/LegalDetailsStore'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'

interface ReceivingDisabilityType {
  legalId: string | undefined
  isHavingBankAccount: boolean | undefined
  accountType: string
  routingNumber: string
  accountNumber: string
}

const StyledSelect = styled(Select)({
  borderRadius: '10px', // Set the border radius to your desired value
  // Add any other custom styles here
})

const ReceivingDisability = ({
  handleEditToggle,
}: {
  setLegalId?: (value: string) => void
  handleEditToggle: () => void
}) => {
  const dispatch = useAppDispatch()

  const ReceivingDisabilityState = useAppSelector(
    (state) => state?.applicationStore?.LegalDetails?.ReceivingDisability,
  )
  const legalId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.legalId,
  )

  const [formValues, setFormValues] = useState<ReceivingDisabilityType>({
    legalId,
    isHavingBankAccount: undefined,
    accountType: '',
    routingNumber: '',
    accountNumber: '',
  })

  useEffect(() => {
    setFormValues({
      ...ReceivingDisabilityState,
    })
  }, [legalId])

  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
    // You may perform validation here and update errors state accordingly
  }

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}
    if (formValues?.isHavingBankAccount === undefined) {
      newErrors.isHavingBankAccount = 'Please select an option'
      valid = false
    }
    if (formValues?.isHavingBankAccount) {
      // Validation logic for Full Name
      if (!formValues.accountType.trim()) {
        newErrors.accountType = 'Account Type is required'
        valid = false
      }
      if (!formValues.routingNumber.trim()) {
        newErrors.routingNumber = 'Routing Number is required'
        valid = false
      } else if (formValues.routingNumber.length !== 10) {
        newErrors.routingNumber = 'Routing Number should be 10 digits'
        valid = false
      }
      if (!formValues.accountNumber.trim()) {
        newErrors.accountNumber = 'Account Number is required'
        valid = false
      } else if (formValues.accountNumber.length !== 15) {
        newErrors.accountNumber = 'Account Number should be 15 digits'
        valid = false
      }
    }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    // Conditionally construct the object to be passed based on haveUsedOtherName value
    const formValuesToSubmit =
      formValues.isHavingBankAccount === true
        ? formValues
        : {
            legalId,
            isHavingBankAccount: formValues.isHavingBankAccount,
          }

    // Handle form submission here, you can access formValues to submit data
    if (!validateForm()) {
      dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            'Some fields are incorrect or empty. Please review form again.',
        }),
      )
    } else {
      dispatch(AddReceivingDisability(formValuesToSubmit)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res?.payload?.success) {
            dispatch(setReceivingDisability(formValues))
            handleEditToggle()
          }
        },
      )
    }
  }

  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'flex-start'}
            sx={{
              py: 2,
              my: 3,
              borderRadius: '10px',
            }}
          >
            <img src={quote} className={Style.star} alt='' />
            <Typography className={Style.Heading}>
              Let's discuss the best way for you to receive your disability
              payments
            </Typography>
          </Box>

          {/* Common radio */}
          <CommonRadioBox
            error={errors?.isHavingBankAccount}
            title='Do you have a bank account that you can use to receive direct deposit payments?'
            commonState={formValues?.isHavingBankAccount}
            setcommonState={(value: boolean) => {
              setFormValues({
                ...formValues,
                isHavingBankAccount: value,
              })
            }}
            YesTitle='I have a bank account that I can use to receive direct deposit payments'
            NoTitle='I do not have a bank account that I can use to receive direct deposit payments'
          />
          {/* <FormHelperText>{errors?.isHavingBankAccount}</FormHelperText> */}
          {formValues?.isHavingBankAccount ? (
            <>
              <Box
                width={'100%'}
                display={'flex'}
                alignItems={'flex-start'}
                sx={{
                  p: 2,
                  my: 4,
                  backgroundColor: '#ECF6FF',
                  borderRadius: '10px',
                }}
              >
                <img src={star} className={Style.star} alt='' />
                <Typography>
                  <i>You must be an owner or co-owner of this account.</i>
                </Typography>
              </Box>

              {/* address */}
              <Box my={'60px'}>
                <InputLabel className={Style.label}>
                  Great! Now, let's gather some details so that we can make sure
                  your benefits are deposited into the right account.
                  <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      Account Type <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <StyledSelect
                      onChange={(e) => {
                        handleFieldChange(e)
                      }}
                      className={Style.AnsTxt}
                      IconComponent={(props) => (
                        <IconButton {...props}>
                          <img src={IconChevronDown} alt='IconChevronDown' />
                        </IconButton>
                      )}
                      displayEmpty
                      renderValue={
                        formValues.accountType !== ''
                          ? () => formValues.accountType
                          : () => 'Select'
                      }
                      value={formValues.accountType}
                      fullWidth
                      id='accountType'
                      name='accountType'
                      error={!!errors.accountType}
                    >
                      <MenuItem value=''>
                        <em>Select an Answer</em>
                      </MenuItem>
                      <MenuItem value='Checking'>Checking</MenuItem>
                      <MenuItem value='Savings'>Savings</MenuItem>
                    </StyledSelect>
                    <FormHelperText sx={{ mx: 1.5 }}>
                      {errors.accountType}
                    </FormHelperText>
                  </Grid>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label} htmlFor='BillingName'>
                      Routing Number <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      type='text'
                      id='routingNumber'
                      name='routingNumber'
                      placeholder='Enter Routing Number'
                      inputProps={{ maxLength: 10 }}
                      className={Style.AnsTxt}
                      value={formValues?.routingNumber}
                      onChange={handleFieldChange}
                      error={!!errors.routingNumber}
                      helperText={errors.routingNumber}
                    />
                  </Grid>
                </Grid>
                <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      Account Number <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      type='text'
                      id='accountNumber'
                      name='accountNumber'
                      inputProps={{ maxLength: 15 }}
                      placeholder='Enter Account Number'
                      className={Style.AnsTxt}
                      value={formValues?.accountNumber}
                      onChange={handleFieldChange}
                      error={!!errors.accountNumber}
                      helperText={errors.accountNumber}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            <></>
          )}

          {/* <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={handleBack}
            >
              Previous
            </Button>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
            >
              Save & Continue
            </Button>
          </Box> */}
          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={() => {
                // setIsSubmitted(false)
                handleEditToggle()
              }}
              // sx={{ visibility: 'hidden' }}
            >
              Discard
            </Button>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
              // onClick={handleEditToggle}
            >
              Save & Update
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default ReceivingDisability
