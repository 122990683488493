import {
  NotificationType,
  showNotification,
} from 'src/Redux/Slices/notificationSlice'
import CloseIcon from '@mui/icons-material/Close'
import {
  Button,
  Chip,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import IconChevronDown from 'src/Assets/Icons/IconChevronDown.svg'
import quote from 'src/Assets/Icons/quots.svg'
import star from 'src/Assets/Icons/star.svg'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import { CitizenshipType } from 'src/Redux/Services/Types/applicationType'
import { AddCitizenship } from 'src/Redux/Slices/ApplicationSlices/BasicDetailsSlice'
import { setCitizenship } from 'src/Redux/Slices/ApplicationStore/BasicDetailsStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import Style from './index.module.scss'
import {
  setDisqualification,
  setIsDisqualified,
} from 'src/Redux/Slices/ApplicationStore/ApplicationIdStore'
import { DynamicObj, UsStates } from 'src/Utils/Constants'
import { Calander, CommonDropdown, Name } from 'src/Components/Fields'

const StyledSelect = styled(Select)({
  borderRadius: '10px', // Set the border radius to your desired value
})

const Citizenship = ({
  handleEditToggle,
}: {
  handleEditToggle: () => void
}) => {
  const dispatch = useAppDispatch()
  const detailId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.basicId,
  )
  const countrySelect = useAppSelector(
    (state: { country: { data: Array<{ name: { common: string } }> } }) =>
      state.country?.data,
  )

  const CitizenshipState = useAppSelector(
    (state) => state?.applicationStore?.BasicDetails?.Citizenship,
  )

  const [formValues, setFormValues] = useState<CitizenshipType>({
    basicDetailId: detailId,
    wereBornInUSA: undefined,
    confirmUSACitizen: '',
    citizenshipCountryName: [],
    isGoingThroughProcessOfUSACitizenship: undefined,
    isLegalResidentOfUSA: undefined,
    residentCardNumber: '',

    bornUSACityName: '',
    bornUSAStateName: '',
    bornCountry: '',
    qualifiedUSACitizenship: '',
    dateOfCitizenship: '',
  })

  // disqualification
  useEffect(() => {
    if (
      formValues?.isLegalResidentOfUSA !== undefined ||
      formValues?.isLegalResidentOfUSA !== 'Boolean'
    ) {
      if (formValues?.isLegalResidentOfUSA === false) {
        dispatch(
          setDisqualification({
            isLegalResidentOfUSA: false,
          }),
        )
        dispatch(setIsDisqualified(true))
      }
      if (formValues?.isLegalResidentOfUSA === true) {
        dispatch(
          setDisqualification({
            isLegalResidentOfUSA: true,
          }),
        )
        dispatch(setIsDisqualified(false))
      }
    }
  }, [formValues?.isLegalResidentOfUSA])

  useEffect(() => {
    setFormValues({ ...CitizenshipState, basicDetailId: detailId })
  }, [])
  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  useEffect(() => {
    if (formValues.wereBornInUSA !== undefined) {
      setFormValues((prevState) => ({
        ...prevState,
        wereBornInUSA: formValues.wereBornInUSA,
      }))
    }
  }, [formValues.wereBornInUSA])

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}

    if (formValues?.wereBornInUSA === undefined) {
      newErrors.wereBornInUSA = 'please select option'
      valid = false
    }

    if (formValues?.wereBornInUSA === true) {
      if (!formValues?.bornUSACityName?.trim()) {
        newErrors.bornUSACityName = 'Please enter city name'
        valid = false
      }

      if (!formValues?.bornUSAStateName?.trim()) {
        newErrors.bornUSAStateName = 'Please enter state name'
        valid = false
      }
    }

    if (formValues?.wereBornInUSA === false) {
      if (!formValues?.bornCountry?.trim()) {
        newErrors.bornCountry = 'please select country'
        valid = false
      }
      if (!formValues.confirmUSACitizen?.trim()) {
        newErrors.confirmUSACitizen = 'please select option'
        valid = false
      }

      if (formValues?.confirmUSACitizen === 'Yes, I am a U.S.citizen') {
        if (!formValues?.qualifiedUSACitizenship?.trim()) {
          newErrors.qualifiedUSACitizenship =
            'Please enter qualifiedUSACitizenship'
          valid = false
        }
        if (formValues?.qualifiedUSACitizenship === 'Naturalized Citizen') {
          if (!formValues?.dateOfCitizenship?.trim()) {
            newErrors.dateOfCitizenship = 'Please enter date of citizenship'
            valid = false
          }
        }
      }

      if (formValues?.confirmUSACitizen === 'No, I am not a U.S.citizen') {
        if (formValues?.citizenshipCountryName?.length === 0) {
          newErrors.citizenshipCountryName =
            'Please select at least one country for citizenship'
          valid = false
        }

        if (formValues?.isGoingThroughProcessOfUSACitizenship === undefined) {
          newErrors.isGoingThroughProcessOfUSACitizenship =
            'please select option'
          valid = false
        }
        if (formValues?.isLegalResidentOfUSA === undefined) {
          newErrors.isLegalResidentOfUSA = 'please select option'
          valid = false
        }

        // if (formValues?.isLegalResidentOfUSA === true) {
        //   if (!formValues.residentCardNumber?.trim()) {
        //     newErrors.residentCardNumber = 'please enter card number'
        //     valid = false
        //   }
        // }
      }
    }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    // Handle form submission here, you can access formValues to submit data
    if (!validateForm()) {
      dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            'Some fields are incorrect or empty. Please review form again.',
        }),
      )
    } else {
      const formValuesToSubmit: typeof formValues = {}
      formValuesToSubmit.basicDetailId = detailId
      formValuesToSubmit.wereBornInUSA = formValues?.wereBornInUSA
      if (formValues?.wereBornInUSA === true) {
        formValuesToSubmit.bornUSACityName = formValues?.bornUSACityName
        formValuesToSubmit.bornUSAStateName = formValues?.bornUSAStateName
      }
      if (formValues?.wereBornInUSA === false) {
        formValuesToSubmit.bornCountry = formValues?.bornCountry
        formValuesToSubmit.confirmUSACitizen = formValues?.confirmUSACitizen

        if (formValues?.confirmUSACitizen === 'Yes, I am a U.S.citizen') {
          formValuesToSubmit.qualifiedUSACitizenship =
            formValues?.qualifiedUSACitizenship
          if (formValues?.qualifiedUSACitizenship === 'Naturalized Citizen') {
            formValuesToSubmit.dateOfCitizenship = formValues?.dateOfCitizenship
          }
        }

        if (formValues?.confirmUSACitizen === 'No, I am not a U.S.citizen') {
          formValuesToSubmit.citizenshipCountryName =
            formValues?.citizenshipCountryName
          formValuesToSubmit.isGoingThroughProcessOfUSACitizenship =
            formValues?.isGoingThroughProcessOfUSACitizenship
          formValuesToSubmit.isLegalResidentOfUSA =
            formValues?.isLegalResidentOfUSA
          if (formValues?.isLegalResidentOfUSA === true) {
            formValuesToSubmit.residentCardNumber =
              formValues?.residentCardNumber
          }
        }
      }
      dispatch(AddCitizenship(formValuesToSubmit)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res?.payload?.success) {
            dispatch(setCitizenship(formValues))
            handleEditToggle()
          }
        },
      )
    }
  }

  const handleSelectChange = (name: string) => {
    // Check if the item is already selected
    const isSelected =
      formValues?.citizenshipCountryName?.includes(name) ?? false

    if (isSelected) {
      // Remove the item from the array

      setFormValues((prevValues) => ({
        ...prevValues,
        citizenshipCountryName: prevValues?.citizenshipCountryName?.filter(
          (country) => country !== name,
        ),
      }))
    } else {
      // Add the item to the array
      setFormValues((prevValues) => ({
        ...prevValues,
        citizenshipCountryName: [
          ...(prevValues?.citizenshipCountryName ?? []),
          name,
        ],
      }))
    }
  }

  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'flex-start'}
            sx={{
              py: 2,
              my: 3,
              borderRadius: '10px',
            }}
          >
            <img src={quote} className={Style.star} alt='' />
            <Typography className={Style.Heading}>
              Amazing! Next topic: Citizenship.
            </Typography>
          </Box>

          <CommonRadioBox
            title='Were you born in the U.S. or a U.S. territory?'
            commonState={formValues?.wereBornInUSA}
            setcommonState={(value: boolean) => {
              setFormValues({ ...formValues, wereBornInUSA: value })
            }}
            YesTitle='I was born in the U.S. or a U.S. territory'
            NoTitle='I was not born in the U.S. or a U.S. territory'
          />
          <FormHelperText sx={{ mx: 1.5 }}>
            {errors?.wereBornInUSA}
          </FormHelperText>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'flex-start'}
            sx={{
              p: 2,
              my: 4,
              backgroundColor: '#ECF6FF',
              borderRadius: '10px',
            }}
          >
            <img src={star} className={Style.star} alt='' />
            <Typography>
              <i>
                U.S. territories include American Samoa, Guam, Northern Mariana
                Islands, Puerto Rico, and U.S. Virgin Islands.
              </i>
            </Typography>
          </Box>

          {formValues?.wereBornInUSA === true && (
            <>
              <Box>
                <InputLabel className={Style.label}>
                  Understood! Please share the U.S. cıty and state where you
                  were born.<span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                  <Grid width={'100%'}>
                    <Name
                      name='bornUSACityName'
                      title='U.S. City/Town:'
                      value={formValues?.bornUSACityName}
                      error={errors?.bornUSACityName}
                      handleChange={(values) => {
                        handleFieldChange({
                          target: { name: values.name, value: values.value },
                        })
                      }}
                    />
                  </Grid>
                  <Grid width={'100%'}>
                    <CommonDropdown
                      label='U.S. State/Territory:'
                      name='bornUSAStateName'
                      value={formValues?.bornUSAStateName}
                      menulist={UsStates}
                      handleChange={(values) => {
                        handleFieldChange({
                          target: { name: values.name, value: values.value },
                        })
                      }}
                      error={errors?.bornUSAStateName}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          )}

          {formValues?.wereBornInUSA === false && (
            <>
              <Box my={'60px'}>
                <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      Please select the country where you were born.
                      <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <StyledSelect
                      onChange={(e: any) => {
                        handleFieldChange(e)
                      }}
                      className={Style.AnsTxt}
                      IconComponent={(props) => (
                        <IconButton {...props}>
                          <img src={IconChevronDown} alt='IconChevronDown' />
                        </IconButton>
                      )}
                      displayEmpty
                      renderValue={
                        formValues.bornCountry !== undefined || ''
                          ? () => formValues.bornCountry
                          : () => 'Select'
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200, // Adjust the value as needed
                          },
                        },
                      }}
                      value={formValues.bornCountry}
                      fullWidth
                      id='bornCountry'
                      name='bornCountry'
                      error={!!errors.bornCountry}
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      {countrySelect?.map((item: DynamicObj, index: number) => {
                        return (
                          <MenuItem key={index} value={item.name}>
                            {item.name}
                          </MenuItem>
                        )
                      })}
                    </StyledSelect>
                    <FormHelperText sx={{ mx: 1.5 }}>
                      {errors.bornCountry}
                    </FormHelperText>
                  </Grid>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label} htmlFor='BillingName'>
                      Could you please confirm that you are a U.S. citizen?
                      <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <StyledSelect
                      onChange={(e) => {
                        handleFieldChange(
                          e as React.ChangeEvent<HTMLInputElement>,
                        )
                      }}
                      className={Style.AnsTxt}
                      IconComponent={(props) => (
                        <IconButton {...props}>
                          <img src={IconChevronDown} alt='IconChevronDown' />
                        </IconButton>
                      )}
                      renderValue={
                        formValues.confirmUSACitizen !== undefined || ''
                          ? () => formValues.confirmUSACitizen
                          : () => 'Select'
                      }
                      value={formValues.confirmUSACitizen}
                      fullWidth
                      id='confirmUSACitizen'
                      name='confirmUSACitizen'
                      error={!!errors.confirmUSACitizen}
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value='Yes, I am a U.S.citizen'>
                        Yes, I am a U.S.citizen
                      </MenuItem>
                      <MenuItem value='No, I am not a U.S.citizen'>
                        No, I am not a U.S.citizen
                      </MenuItem>
                    </StyledSelect>
                    <FormHelperText sx={{ mx: 1.5 }}>
                      {errors.confirmUSACitizen}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </Box>
              {formValues?.confirmUSACitizen === 'Yes, I am a U.S.citizen' && (
                <>
                  <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                    <Grid width={'100%'}>
                      <InputLabel className={Style.label}>
                        Thanks for sharing that you're a U.S. citizen. Please
                        tell us how you qualified for U.S. citizenship.
                        <span style={{ color: 'red' }}>*</span>
                      </InputLabel>
                      <StyledSelect
                        onChange={(e) => {
                          handleFieldChange(
                            e as React.ChangeEvent<HTMLInputElement>,
                          )
                        }}
                        className={Style.AnsTxt}
                        IconComponent={(props) => (
                          <IconButton {...props}>
                            <img src={IconChevronDown} alt='IconChevronDown' />
                          </IconButton>
                        )}
                        renderValue={
                          formValues.qualifiedUSACitizenship !== ''
                            ? () => formValues.qualifiedUSACitizenship
                            : () => 'Select'
                        }
                        value={formValues.qualifiedUSACitizenship}
                        fullWidth
                        id='qualifiedUSACitizenship'
                        name='qualifiedUSACitizenship'
                        error={!!errors.qualifiedUSACitizenship}
                      >
                        <MenuItem value=''>
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value='U.S. Citizen Born Outside the U.S.'>
                          U.S. Citizen Born Outside the U.S.
                        </MenuItem>
                        <MenuItem value='Naturalized Citizen'>
                          Naturalized Citizen
                        </MenuItem>
                      </StyledSelect>
                      <FormHelperText sx={{ mx: 1.5 }}>
                        {errors.qualifiedUSACitizenship}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                  {formValues?.qualifiedUSACitizenship ===
                    'Naturalized Citizen' && (
                    <>
                      <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                        <Grid width={'100%'}>
                          <Calander
                            name='dateOfCitizenship'
                            value={formValues.dateOfCitizenship}
                            title="Got it. You're a naturalized citizen. What’s your date of citizenship?"
                            handleChange={(values) => {
                              handleFieldChange({
                                target: {
                                  name: values.name,
                                  value: values.value,
                                },
                              })
                            }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              )}
              {formValues?.confirmUSACitizen ===
                'No, I am not a U.S.citizen' && (
                <>
                  {/*
                      <Box
                        width={'100%'}
                        display={'flex'}
                        alignItems={'flex-start'}
                        sx={{
                          p: 2,
                          my: 4,
                          backgroundColor: '#ECF6FF',
                          borderRadius: '10px',
                        }}
                      >
                        <img src={star} className={Style.star} alt='' />
                        <Typography>
                          <i>
                            Thanks for sharing! Disability benefits are intended to assist
                            any U.S. citizen or resident who is expected to be out of work
                            due to an injury, illness, or condition.
                          </i>
                        </Typography>
                      </Box>
                    */}
                  <Box my={'60px'}>
                    <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                      <Grid width={'100%'}>
                        <InputLabel className={Style.label}>
                          Help us understand your situation. What's your country
                          of citizenship?
                          <span style={{ color: 'red' }}>*</span>
                        </InputLabel>
                        <FormControl fullWidth>
                          <StyledSelect
                            id='citizenshipCountryName'
                            name='citizenshipCountryName'
                            multiple
                            className={Style.AnsTxt}
                            value={
                              Array.isArray(formValues?.citizenshipCountryName)
                                ? formValues?.citizenshipCountryName
                                : []
                            } // Ensure it's an array
                            renderValue={() => (
                              <Box display='flex' flexWrap='wrap' gap={1}>
                                {Array.isArray(
                                  formValues?.citizenshipCountryName,
                                ) &&
                                  formValues?.citizenshipCountryName?.map(
                                    (value, index) => (
                                      <Chip
                                        key={index}
                                        className={Style.ChipItem}
                                        label={value}
                                        onMouseDown={(event) => {
                                          event.stopPropagation()
                                        }}
                                        onDelete={(e) => {
                                          e.stopPropagation()
                                          handleSelectChange(value)
                                          setFormValues((prevState) => ({
                                            ...prevState,
                                            citizenshipCountryName:
                                              prevState.citizenshipCountryName?.filter(
                                                (item) => item !== value,
                                              ),
                                          }))
                                        }}
                                        deleteIcon={
                                          <CloseIcon
                                            onClick={(e) => {
                                              e.stopPropagation()
                                              handleSelectChange(value)
                                              setFormValues((prevState) => ({
                                                ...prevState,
                                                citizenshipCountryName:
                                                  prevState.citizenshipCountryName?.filter(
                                                    (item) => item !== value,
                                                  ),
                                              }))
                                            }}
                                          />
                                        }
                                      />
                                    ),
                                  )}
                              </Box>
                            )}
                          >
                            <Box sx={{ height: '250px', overflow: 'auto' }}>
                              {countrySelect?.map(
                                (item: DynamicObj, index: number) => (
                                  <MenuItem
                                    key={index}
                                    onClick={() => {
                                      handleSelectChange(item.name)
                                    }}
                                    value={item.name}
                                  >
                                    {item.name}
                                  </MenuItem>
                                ),
                              )}
                            </Box>
                          </StyledSelect>

                          <FormHelperText sx={{ mx: 1.5 }}>
                            {errors.citizenshipCountryName}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                  <CommonRadioBox
                    title='Are you actively going through the process of becoming a U.S. citizen?'
                    commonState={
                      formValues?.isGoingThroughProcessOfUSACitizenship
                    }
                    setcommonState={(value: boolean) => {
                      setFormValues({
                        ...formValues,
                        isGoingThroughProcessOfUSACitizenship: value,
                      })
                    }}
                    YesTitle='I am actively going through the process of becoming a U.S. citizen'
                    NoTitle='I am not actively going through the process of becoming a U.S. citizen'
                  />
                  <FormHelperText sx={{ mx: 1.5 }}>
                    {errors.isGoingThroughProcessOfUSACitizenship}
                  </FormHelperText>

                  <CommonRadioBox
                    title='Are you a legal resident of the U.S.?'
                    commonState={formValues?.isLegalResidentOfUSA}
                    setcommonState={(value: boolean) => {
                      setFormValues({
                        ...formValues,
                        isLegalResidentOfUSA: value,
                      })
                    }}
                    YesTitle='I am a legal resident of the U.S.'
                    NoTitle='I am not a legal resident of the U.S.'
                  />
                  <FormHelperText sx={{ mx: 1.5 }}>
                    {errors.isLegalResidentOfUSA}
                  </FormHelperText>

                  {formValues?.isLegalResidentOfUSA === true && (
                    <>
                      <Box my={'60px'}>
                        <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                          <Grid width={'100%'}>
                            <InputLabel className={Style.label}>
                              What's your Permanent Resident Card number?
                              (optional)
                            </InputLabel>
                            <TextField
                              fullWidth
                              type='text'
                              id='residentCardNumber'
                              name='residentCardNumber'
                              className={Style.AnsTxt}
                              inputProps={{ maxLength: 20 }}
                              placeholder='Enter resident CardNumber'
                              value={formValues?.residentCardNumber}
                              onChange={handleFieldChange}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  )}
                </>
              )}
            </>
          )}
          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={handleEditToggle}
            >
              Discard
            </Button>
            <Button
              disabled={formValues?.isLegalResidentOfUSA === false}
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
            >
              Save & Update
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default Citizenship
