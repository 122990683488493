import {
  NotificationType,
  showNotification,
} from 'src/Redux/Slices/notificationSlice'
import { Button, InputLabel } from '@mui/material'
import Box from '@mui/material/Box'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import {
  ApplicationIntroBox,
  Calander,
  CommonDropdown,
  MultiSelectDropDown,
  Name,
  StarText,
} from 'src/Components/Fields'
import { DisabilityEmploymentType } from 'src/Redux/Services/Types/applicationType'
import { AddDisabilityEmployment } from 'src/Redux/Slices/ApplicationSlices/DisabilityDetailsSlice'
import { setDisabilityEmployment } from 'src/Redux/Slices/ApplicationStore/DisabilityDetailsStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import Style from './index.module.scss'
import {
  setDisqualification,
  setIsDisqualified,
} from 'src/Redux/Slices/ApplicationStore/ApplicationIdStore'
import { JsonData } from 'src/Redux/Slices/ApplicationSlices/chatbotSlice'
import { currentYear, previousYear } from 'src/Utils/Constants'

const DisabilityEmployment = ({
  chatForm,
  handleNext,
  handleBack,
}: {
  chatForm?: any
  handleBack: () => void
  handleNext: () => void
}) => {
  const dispatch = useAppDispatch()

  const disabilityId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.disabilityId,
  )
  const applicationId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )
  const DisabilityEmploymentState = useAppSelector(
    (state) => state?.applicationStore?.DisabilityDetails?.DisabilityEmployment,
  )

  const [formValues, setFormValues] = useState<DisabilityEmploymentType>({
    disabilityId,
    intendToWorkNextYear: undefined,
    affectingEmploymentDetail: '',
    employedDuringLast2YearDetail: [],
    reasonToStoppedWorking: '',
    explainStoppedWorking: '',
    dateOfStoppedWorking: '',
    isRequireWorkChanges: undefined,
    workChangeStartDate: '',
    haveMadeMoreMoneyInAnyMonth: undefined,
    disabilityKeepFromWorkingDate: '',
  })

  useEffect(() => {
    if (formValues?.haveMadeMoreMoneyInAnyMonth !== undefined) {
      dispatch(
        setDisqualification({
          haveMadeMoreMoneyInAnyMonth: formValues?.haveMadeMoreMoneyInAnyMonth,
        }),
      )
      dispatch(setIsDisqualified(formValues?.haveMadeMoreMoneyInAnyMonth))
    }
  }, [formValues?.haveMadeMoreMoneyInAnyMonth])

  const isBotOpen = useAppSelector(
    (state) => state.applicationStore.ApplicationStep.isBotOpen,
  )
  useEffect(() => {
    setFormValues({ disabilityId, ...DisabilityEmploymentState })
  }, [])
  useEffect(() => {
    if (isBotOpen)
      dispatch(
        JsonData({
          uuid: applicationId,
          data: {
            ...formValues,
            intendToWorkNextYear:
              formValues?.intendToWorkNextYear === undefined
                ? 'Boolean'
                : formValues?.intendToWorkNextYear,
            isRequireWorkChanges:
              formValues?.isRequireWorkChanges === undefined
                ? 'Boolean'
                : formValues?.isRequireWorkChanges,
            haveMadeMoreMoneyInAnyMonth:
              formValues?.haveMadeMoreMoneyInAnyMonth === undefined
                ? 'Boolean'
                : formValues?.haveMadeMoreMoneyInAnyMonth,
          },
        }),
      )
  }, [isBotOpen, disabilityId, DisabilityEmploymentState])

  useEffect(() => {
    if (chatForm)
      setFormValues((prevState) => ({
        ...prevState,
        ...chatForm,
      }))
  }, [chatForm])

  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const handleFieldChange = (data: {
    name: string
    value: string | number | string[]
  }) => {
    const { name, value } = data
    setFormValues({ ...formValues, [name]: value })
  }

  const NotNever =
    formValues.affectingEmploymentDetail !== 'I have never worked'
  const bothWorking =
    formValues.affectingEmploymentDetail === 'I am currently working' ||
    formValues.affectingEmploymentDetail === 'I have stopped working'
  const Stop = formValues.affectingEmploymentDetail === 'I have stopped working'
  const otherReasons =
    formValues.reasonToStoppedWorking === 'Because of other reasons'
  const injeryRadio = formValues.isRequireWorkChanges === true

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}
    const currentDate = new Date().toISOString().split('T')[0]
    if (formValues?.intendToWorkNextYear === undefined) {
      newErrors.intendToWorkNextYear = 'please select option'
      valid = false
    }

    if (NotNever) {
      if (formValues?.isRequireWorkChanges === undefined) {
        newErrors.isRequireWorkChanges = 'please select option'
        valid = false
      }
    }

    if (!formValues.affectingEmploymentDetail.trim()) {
      newErrors.affectingEmploymentDetail = 'please select option'
      valid = false
    }

    if (NotNever) {
      if (formValues.employedDuringLast2YearDetail.length === 0) {
        newErrors.employedDuringLast2YearDetail = 'please select option'
        valid = false
      }
    }

    if (injeryRadio) {
      if (formValues?.haveMadeMoreMoneyInAnyMonth === undefined) {
        newErrors.haveMadeMoreMoneyInAnyMonth = 'please select option'
        valid = false
      }

      if (formValues.workChangeStartDate === '') {
        newErrors.workChangeStartDate = 'please select date'
        valid = false
      }

      if (formValues?.workChangeStartDate > currentDate) {
        newErrors.workChangeStartDate = 'Please select valid Date'
        valid = false
      }
    }

    if (Stop) {
      if (!formValues.reasonToStoppedWorking.trim()) {
        newErrors.reasonToStoppedWorking = 'please select option'
        valid = false
      }

      if (formValues.dateOfStoppedWorking === '') {
        newErrors.dateOfStoppedWorking = 'please select date'
        valid = false
      }
      if (formValues?.dateOfStoppedWorking > currentDate) {
        newErrors.dateOfStoppedWorking = 'Please select valid Date'
        valid = false
      }
    }

    if (!NotNever) {
      if (formValues.disabilityKeepFromWorkingDate === '') {
        newErrors.disabilityKeepFromWorkingDate = 'please select date'
        valid = false
      }
      if (formValues?.disabilityKeepFromWorkingDate > currentDate) {
        newErrors.disabilityKeepFromWorkingDate = 'Please select valid Date'
        valid = false
      }
    }

    if (otherReasons) {
      if (!formValues.explainStoppedWorking.trim()) {
        newErrors.explainStoppedWorking = 'please select option'
        valid = false
      }
    }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const formValuesToSubmit: typeof formValues = {}
    formValuesToSubmit.disabilityId = disabilityId
    formValuesToSubmit.intendToWorkNextYear = formValues.intendToWorkNextYear

    formValuesToSubmit.affectingEmploymentDetail =
      formValues.affectingEmploymentDetail
    if (bothWorking) {
      formValuesToSubmit.isRequireWorkChanges = formValues.isRequireWorkChanges
      if (
        formValues.isRequireWorkChanges === false &&
        formValues.affectingEmploymentDetail === 'I have stopped working'
      ) {
        formValuesToSubmit.haveMadeMoreMoneyInAnyMonth =
          formValues.haveMadeMoreMoneyInAnyMonth
      }
      formValuesToSubmit.employedDuringLast2YearDetail =
        formValues.employedDuringLast2YearDetail
      if (formValues.isRequireWorkChanges === true && NotNever) {
        formValuesToSubmit.workChangeStartDate = formValues.workChangeStartDate
        formValuesToSubmit.haveMadeMoreMoneyInAnyMonth =
          formValues.haveMadeMoreMoneyInAnyMonth
      }
    }

    if (!NotNever) {
      formValuesToSubmit.intendToWorkNextYear = formValues.intendToWorkNextYear
      formValuesToSubmit.affectingEmploymentDetail =
        formValues.affectingEmploymentDetail
      formValuesToSubmit.disabilityKeepFromWorkingDate =
        formValues.disabilityKeepFromWorkingDate

      if (formValues.affectingEmploymentDetail === 'I am currently working') {
        if (formValues.isRequireWorkChanges === true) {
          formValuesToSubmit.workChangeStartDate =
            formValues.workChangeStartDate
          formValuesToSubmit.haveMadeMoreMoneyInAnyMonth =
            formValues.haveMadeMoreMoneyInAnyMonth
        }
      }
    }
    if (Stop) {
      formValuesToSubmit.affectingEmploymentDetail =
        formValues.affectingEmploymentDetail
      formValuesToSubmit.employedDuringLast2YearDetail =
        formValues.employedDuringLast2YearDetail
      formValuesToSubmit.reasonToStoppedWorking =
        formValues.reasonToStoppedWorking
      if (formValues.reasonToStoppedWorking === 'Because of other reasons') {
        formValuesToSubmit.explainStoppedWorking =
          formValues.explainStoppedWorking
      }
      formValuesToSubmit.dateOfStoppedWorking = formValues.dateOfStoppedWorking
      if (formValues.affectingEmploymentDetail === 'I have stopped working') {
        if (formValues.isRequireWorkChanges === true) {
          formValuesToSubmit.workChangeStartDate =
            formValues.workChangeStartDate
        }
      }
    }
    // if (formValues.isRequireWorkChanges === true) {
    //   formValuesToSubmit.workChangeStartDate = formValues.workChangeStartDate
    // }

    if (!validateForm()) {
      dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            'Some fields are incorrect or empty. Please review form again.',
        }),
      )
    } else {
      dispatch(setDisabilityEmployment(formValues))
      dispatch(AddDisabilityEmployment(formValuesToSubmit)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res?.payload?.success) {
            handleNext()
          }
        },
      )
    }
  }

  const selectOptions = [
    {
      value: `I was employed for any part of ${currentYear}`,
      name: `I was employed for any part of ${currentYear}`,
    },
    {
      value: `I was employed for any part of ${previousYear}`,
      name: `I was employed for any part of ${previousYear}`,
    },
  ]

  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <ApplicationIntroBox heading='In this section, tell us about your previous(or current) employment.' />

          <CommonDropdown
            name='affectingEmploymentDetail'
            value={formValues.affectingEmploymentDetail}
            label="Now, let's dig into how your disability is affecting your
                employment. Please select an answer."
            menulist={[
              {
                name: 'I am currently working',
                value: 'I am currently working',
              },
              {
                name: 'I have stopped working',
                value: 'I have stopped working',
              },
              {
                name: 'I have never worked',
                value: 'I have never worked',
              },
            ]}
            handleChange={handleFieldChange}
            error={errors.affectingEmploymentDetail}
          />

          {!NotNever ? (
            <>
              <Calander
                name='disabilityKeepFromWorkingDate'
                value={formValues.disabilityKeepFromWorkingDate}
                title='When do you believe that your conditions became severe enough
                  to prevent you from working?'
                handleChange={handleFieldChange}
                error={errors.disabilityKeepFromWorkingDate}
              />
              <StarText
                text={
                  'Please do not count sick leave, vacation, or disability pay.'
                }
              />
            </>
          ) : (
            <>
              <MultiSelectDropDown
                name='employedDuringLast2YearDetail'
                value={formValues.employedDuringLast2YearDetail}
                label='Help us understand when you were employed during the last two years. Check all that apply.'
                menulist={selectOptions}
                handleSelectChange={(data) => {
                  handleFieldChange({
                    name: 'employedDuringLast2YearDetail',
                    value: data,
                  })
                }}
                error={errors.employedDuringLast2YearDetail}
              />

              {formValues.affectingEmploymentDetail ===
              'I have stopped working' ? (
                <>
                  <InputLabel className={Style.label}>
                    Why did you stop working?
                    <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <CommonDropdown
                    name='reasonToStoppedWorking'
                    value={formValues.reasonToStoppedWorking}
                    label='I stopped working:'
                    menulist={[
                      {
                        name: 'Due to my condition(s)',
                        value: 'Due to my condition(s)',
                      },
                      {
                        name: 'Because of other reasons',
                        value: 'Because of other reasons',
                      },
                    ]}
                    handleChange={handleFieldChange}
                    error={errors.reasonToStoppedWorking}
                  />

                  {formValues.reasonToStoppedWorking ===
                  'Because of other reasons' ? (
                    <>
                      <Name
                        name='explainStoppedWorking'
                        value={formValues.explainStoppedWorking}
                        handleChange={handleFieldChange}
                        error={errors.explainStoppedWorking}
                        title='Please explain why you stopped working'
                        multiline
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  <Calander
                    name='dateOfStoppedWorking'
                    value={formValues.dateOfStoppedWorking}
                    title='When did your condition(s) become severe enough to keep you from working?'
                    handleChange={handleFieldChange}
                    error={errors.dateOfStoppedWorking}
                  />
                </>
              ) : (
                <></>
              )}

              <Box>
                <StarText
                  text={`We're trying to understand if you've had to adjust
                            your work responsibilities while you were working
                            due to your situation.`}
                />
                <CommonRadioBox
                  error={errors?.isRequireWorkChanges}
                  title='Did your condition, injury, or illness require changes to your work activity while you were working?'
                  commonState={formValues?.isRequireWorkChanges}
                  setcommonState={(value: boolean) => {
                    setFormValues({
                      ...formValues,
                      isRequireWorkChanges: value,
                    })
                  }}
                  YesTitle='My condition, injury, or illness did require changes to my work activity while I was working'
                  NoTitle='My condition, injury, or illness did not require changes to my work activity while I was working'
                />
              </Box>

              {formValues?.isRequireWorkChanges ? (
                <>
                  {formValues.isRequireWorkChanges ? (
                    <>
                      <Calander
                        name='workChangeStartDate'
                        value={formValues.workChangeStartDate}
                        title='When did these changes begin?'
                        handleChange={handleFieldChange}
                        error={errors.workChangeStartDate}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          )}

          {NotNever ? (
            <>
              <CommonRadioBox
                error={errors?.haveMadeMoreMoneyInAnyMonth}
                title={`Have you made more than $1,470 during any month in ${currentYear} since the date(s) you provided above?`}
                commonState={formValues?.haveMadeMoreMoneyInAnyMonth}
                setcommonState={(value: boolean) => {
                  setFormValues({
                    ...formValues,
                    haveMadeMoreMoneyInAnyMonth: value,
                  })
                }}
                YesTitle={`I have made more than $1,470 during some months in ${currentYear} since the date(s) I provided above`}
                NoTitle={`I have not made more than $1,470 during some months in ${currentYear} since the date(s) I provided above`}
              />
            </>
          ) : (
            <></>
          )}

          <CommonRadioBox
            error={errors?.intendToWorkNextYear}
            title={`Do you intend to work in ${currentYear}?`}
            commonState={formValues?.intendToWorkNextYear}
            setcommonState={(value: boolean) => {
              setFormValues({ ...formValues, intendToWorkNextYear: value })
            }}
            YesTitle='Yes I am'
            NoTitle='No I am not'
          />
          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={handleBack}
            >
              Previous
            </Button>
            <Button
              disabled={!!formValues?.haveMadeMoreMoneyInAnyMonth}
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
            >
              Next
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default DisabilityEmployment
