import {
  NotificationType,
  showNotification,
} from 'src/Redux/Slices/notificationSlice'
import { Button, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { Key, useEffect, useState } from 'react'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import { AddButton, ApplicationIntroBox, StarText } from 'src/Components/Fields'
import { addMedicine } from 'src/Redux/Slices/ApplicationSlices/MedicalDetailsSlice'
import { setMedicine } from 'src/Redux/Slices/ApplicationStore/MedicalDetailsStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import DetailBox from './DetailsBox'
import Style from './index.module.scss'
import { MedicineType } from 'src/Redux/Services/Types/applicationType'

const Medicine = ({
  handleEditToggle,
}: {
  setDetailId?: React.Dispatch<React.SetStateAction<string>>
  handleEditToggle: () => void
}) => {
  const dispatch = useAppDispatch()

  const MedicalDetailId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.MedicalDetailId,
  )

  const MedicineState = useAppSelector(
    (state) => state?.applicationStore?.MedicalDetails?.Medicine,
  )

  const [formValues, setFormValues] = useState<MedicineType>({
    medicalDetailId: MedicalDetailId,
    isMedication: undefined,
    medication: [
      {
        medicationName: '',
        suffix: '',
        doctorFirstName: '',
        doctorLastName: '',
        medicationReason: '',
        sideEffects: '',
      },
    ],
  })
  useEffect(() => {
    if (formValues?.isMedication === false) {
      setFormValues((prevValues) => ({
        ...prevValues,
        medication: [
          {
            medicationName: '',
            suffix: '',
            doctorFirstName: '',
            doctorLastName: '',
            medicationReason: '',
            sideEffects: '',
          },
        ],
      }))
    }
  }, [formValues?.isMedication])
  useEffect(() => {
    setFormValues({
      ...MedicineState,
      medicalDetailId: MedicalDetailId,
    })
  }, [])

  const [errors, setErrors] = useState<
    Record<string, string | Array<Record<string, string>>>
  >({
    medication: [{}],
  })

  // const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target
  //   setFormValues({ ...formValues, [name]: value })
  //   // You may perform validation here and update errors state accordingly
  // }

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string | Array<Record<string, string>>> = {
      medication: [{}],
    }

    if (formValues?.isMedication?.valueOf() === undefined) {
      newErrors.isMedication = 'Please select an option'
      valid = false
    }

    if (formValues?.isMedication) {
      for (let i = 0; i < formValues.medication.length; i++) {
        if (!formValues.medication[i].suffix.trim()) {
          newErrors.medication = newErrors.medication?.map(
            (error: any, index: number) => {
              if (i === index)
                return {
                  ...error,
                  suffix: 'Suffix is required',
                }
              return error
            },
          )
          valid = false
        }
        if (!formValues.medication[i].doctorFirstName.trim()) {
          newErrors.medication = newErrors.medication?.map(
            (error: any, index: number) => {
              if (i === index)
                return { ...error, doctorFirstName: 'First Name is required' }
              return error
            },
          )
          valid = false
        }
        if (!formValues.medication[i].doctorLastName.trim()) {
          newErrors.medication = newErrors.medication?.map(
            (error: any, index: number) => {
              if (i === index)
                return { ...error, doctorLastName: 'Last Name is required' }
              return error
            },
          )
          valid = false
        }
        if (!formValues.medication[i].medicationName.trim()) {
          newErrors.medication = newErrors.medication?.map(
            (error: any, index: number) => {
              if (i === index)
                return {
                  ...error,
                  medicationName: 'Medication Name is required',
                }
              return error
            },
          )
          valid = false
        }

        if (!formValues.medication[i].medicationReason.trim()) {
          newErrors.medication = newErrors.medication?.map(
            (error: any, index: number) => {
              if (i === index)
                return {
                  ...error,
                  medicationReason: 'Medication Reason is required',
                }
              return error
            },
          )
          valid = false
        }

        if (!formValues.medication[i].sideEffects.trim()) {
          newErrors.medication = newErrors.medication?.map(
            (error: any, index: number) => {
              if (i === index)
                return { ...error, sideEffects: 'Side Effects is required' }
              return error
            },
          )
          valid = false
        }
        newErrors.medication = [...newErrors.medication, {}]
      }
    }
    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    // const formValuesToSubmit =
    //   formValues.isMedication === false ? (formValues.isMedication, formValues.medicalDetailId) : (formValues)
    const formValuesToSubmit: typeof formValues = {}
    if (formValues.isMedication === false) {
      formValuesToSubmit.medicalDetailId = formValues.medicalDetailId
      formValuesToSubmit.isMedication = formValues.isMedication
    } else {
      formValuesToSubmit.medicalDetailId = formValues?.medicalDetailId
      formValuesToSubmit.isMedication = formValues?.isMedication
      formValuesToSubmit.medication = formValues?.medication
    }
    // Handle form submission here, you can access formValues to submit data
    if (!validateForm()) {
      dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            'Some fields are incorrect or empty. Please review form again.',
        }),
      )
    } else {
      dispatch(addMedicine(formValuesToSubmit)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res?.payload?.success) {
            dispatch(setMedicine(formValues))
            handleEditToggle()
          }
        },
      )
    }
  }

  const handleDetailBoxValuesChange = (
    index: number,
    data: { name: string; value: string },
  ) => {
    let newProfileForms = [...formValues.medication]
    newProfileForms = newProfileForms?.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [data.name]: data.value,
        }
      }
      return item
    })
    setFormValues({ ...formValues, medication: newProfileForms })
  }

  const handleDetailBoxRemove = (index: number) => {
    const newProfileForms = [...formValues.medication]
    newProfileForms.splice(index, 1)
    setFormValues({ ...formValues, medication: newProfileForms })
  }

  const addDetailBox = () => {
    if (formValues.medication?.length)
      setFormValues({
        ...formValues,
        medication: [
          ...formValues.medication,
          {
            suffix: '',
            medicationName: '',
            doctorFirstName: '',
            doctorLastName: '',
            medicationReason: '',
            sideEffects: '',
          },
        ],
      })
    else
      setFormValues({
        ...formValues,
        medication: [
          {
            suffix: '',
            medicationName: '',
            doctorFirstName: '',
            doctorLastName: '',
            medicationReason: '',
            sideEffects: '',
          },
        ],
      })
  }

  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <ApplicationIntroBox heading="Thanks for sharing! Now, let's discuss your medication details." />

          <CommonRadioBox
            error={errors?.isMedication}
            title='Are you taking any medication (i.e., prescribed or over-the-counter) for your illnesses, injuries, or conditions?'
            commonState={formValues.isMedication}
            setcommonState={(value: boolean) => {
              setFormValues({ ...formValues, isMedication: value })
            }}
            YesTitle='I am taking medication for my illnesses, injuries, or conditions, whether prescribed or over-the-counter'
            NoTitle='I am not taking any medication, whether prescribed or over-the-counter, for my illnesses, injuries, or conditions'
          />

          {formValues.isMedication ? (
            <>
              <StarText
                text={`Noted! We want to understand any medication that you are taking related to your illness, injury, or condition. Use the dropdown to find and select the over-the-counter or prescribed medication that you are taking related to your illness, injury, or condition. Repeat this step for each medication.`}
              />

              <Box
                style={{
                  marginTop: '20px',
                  marginBottom: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography className={Style.label}>
                  Please share the details related to this medication and the
                  medical professional who prescribed or recommended it.
                </Typography>

                {formValues?.medication?.map(
                  (form, index: Key | null | undefined) => (
                    <DetailBox
                      key={index}
                      realValue={formValues}
                      handleRemove={() => {
                        handleDetailBoxRemove(index)
                      }}
                      errors={errors.medication[index]}
                      index={index}
                      formValues={form}
                      changeHandler={(data: {
                        name: string
                        value: string
                      }) => {
                        handleDetailBoxValuesChange(index, data)
                      }}
                      setFormValues={setFormValues}
                    />
                  ),
                )}

                <AddButton
                  title='Provide More Information for Each Medication Here'
                  subTitle='For a comprehensive representation of your situation, it is important that all medications be included.'
                  button='Add Medication'
                  onClick={addDetailBox}
                />
              </Box>
            </>
          ) : (
            <></>
          )}

          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={() => {
                handleEditToggle()
              }}
            >
              Discard
            </Button>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
            >
              Save & Update
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default Medicine
