import {
  NotificationType,
  showNotification,
} from 'src/Redux/Slices/notificationSlice'
import {
  Button,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  styled,
} from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import IconChevronDown from 'src/Assets/Icons/IconChevronDown.svg'
import { ApplicationIntroBox } from 'src/Components/Fields'
import { GeneralHealthType } from 'src/Redux/Services/Types/applicationType'
import {
  AddGeneralHealth,
  EditGeneralHealth,
} from 'src/Redux/Slices/ApplicationSlices/MedicalDetailsSlice'
import { setMedicalDetailId } from 'src/Redux/Slices/ApplicationStore/ApplicationIdStore'
import { setGeneralHealth } from 'src/Redux/Slices/ApplicationStore/MedicalDetailsStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import Style from './index.module.scss'
import { JsonData } from 'src/Redux/Slices/ApplicationSlices/chatbotSlice'

const StyledSelect = styled(Select)({
  borderRadius: '10px',
})

const GeneralHealth = ({
  chatForm,
  handleNext,
  handleBack,
}: {
  chatForm: any
  handleBack: () => void
  handleNext: () => void
}) => {
  const dispatch = useAppDispatch()
  // const navigate = useNavigate()

  const GeneralHealthState = useAppSelector(
    (state) => state?.applicationStore?.MedicalDetails?.GeneralHealth,
  )

  const medicalDetailId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.MedicalDetailId,
  )

  const applicationId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )

  const [formValues, setFormValues] = useState<GeneralHealthType>({
    applicationId,
    dateOfBirth: '',
    gender: '',
    feet: 0,
    inch: 0,
    weight: 1,
  })

  const isBotOpen = useAppSelector(
    (state) => state.applicationStore.ApplicationStep.isBotOpen,
  )
  useEffect(() => {
    setFormValues({
      ...GeneralHealthState,
      applicationId,
    })
  }, [])
  useEffect(() => {
    if (isBotOpen)
      dispatch(
        JsonData({
          uuid: applicationId,
          data: { ...formValues },
        }),
      )
  }, [isBotOpen, medicalDetailId])

  useEffect(() => {
    if (chatForm)
      setFormValues((prevState) => ({
        ...prevState,
        ...chatForm,
      }))
  }, [chatForm])

  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
    // You may perform validation here and update errors state accordingly
    // const inputName = e.target.name
    // const inputValue = e.target.value
    // if (inputName === 'phone' && !/^\d{3}$/.test(inputValue)) {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     [inputName]: '',
    //   }))
    // }
  }

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}
    const currentDate = new Date().toISOString().split('T')[0]
    if (!formValues?.dateOfBirth) {
      newErrors.dateOfBirth = 'Date of Birth is required'
      valid = false
    }

    if (formValues?.dateOfBirth > currentDate) {
      newErrors.dateOfBirth = 'Please select valid date'
      valid = false
    }

    if (!formValues?.gender?.trim()) {
      newErrors.gender = 'Gender is required'
      valid = false
    }

    if (formValues?.feet <= 0) {
      newErrors.feet = 'Feet is required'
      valid = false
    }

    if (formValues?.inch <= 0) {
      newErrors.inch = 'Inches is required'
      valid = false
    }

    if (!formValues?.weight) {
      newErrors.weight = 'Weight is required'
      valid = false
    }

    if (formValues?.weight <= 0) {
      newErrors.weight = 'Weight is invalid'
      valid = false
    }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    if (!validateForm()) {
      dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            'Some fields are incorrect or empty. Please review form again.',
        }),
      )
    } else {
      dispatch(setGeneralHealth(formValues))
      if (!medicalDetailId) {
        dispatch(AddGeneralHealth(formValues)).then(
          (
            res:
              | PayloadAction<
                  any,
                  string,
                  { arg: any; requestId: string; requestStatus: 'fulfilled' },
                  never
                >
              | PayloadAction<
                  unknown,
                  string,
                  {
                    arg: any
                    requestId: string
                    requestStatus: 'rejected'
                    aborted: boolean
                    condition: boolean
                  } & (
                    | { rejectedWithValue: true }
                    | ({ rejectedWithValue: false } & {})
                  ),
                  SerializedError
                >,
          ) => {
            const data = res?.payload?.data
            if (res?.payload?.success) {
              dispatch(setMedicalDetailId(data?.medicalDetailId))
              handleNext()
            }
          },
        )
      } else {
        dispatch(EditGeneralHealth({ ...formValues, medicalDetailId })).then(
          (
            res:
              | PayloadAction<
                  any,
                  string,
                  { arg: any; requestId: string; requestStatus: 'fulfilled' },
                  never
                >
              | PayloadAction<
                  unknown,
                  string,
                  {
                    arg: any
                    requestId: string
                    requestStatus: 'rejected'
                    aborted: boolean
                    condition: boolean
                  } & (
                    | { rejectedWithValue: true }
                    | ({ rejectedWithValue: false } & {})
                  ),
                  SerializedError
                >,
          ) => {
            if (res?.payload?.success) {
              handleNext()
            }
          },
        )
      }
    }
  }

  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <ApplicationIntroBox
            heading={`You're doing great! Now, let's look at some questions related to
              your general health.`}
          />

          <Box my={'60px'}>
            <InputLabel className={Style.label}>
              Great! Now, let's gather some details so that we can make sure
              your benefits are deposited into the right account.
              <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
              <Grid width={'100%'}>
                <InputLabel className={Style.label}>
                  Please share your birthday:
                  <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-root': {
                        height: '58.88px', // Set the desired height
                      },
                      fieldset: {
                        borderColor: errors.dateOfBirth ? '#d32f2f' : undefined,
                      },
                    }}
                    value={
                      formValues?.dateOfBirth
                        ? dayjs(formValues?.dateOfBirth)
                        : null
                    } // Convert to Dayjs object
                    onChange={(date) => {
                      setFormValues({
                        ...formValues,
                        dateOfBirth: date?.format('YYYY-MM-DD'),
                      })
                    }}
                    maxDate={dayjs()} // Use Dayjs for maxDate as well
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        fullWidth
                        style={{ width: '100%' }}
                        id='dateOfBirth'
                        name='dateOfBirth'
                        placeholder='mm-DD-YYYY'
                        className={Style.AnsTxt}
                        InputProps={{
                          inputProps: { max: dayjs().format('YYYY-MM-DD') },
                        }}
                        error={!!errors.dateOfBirth}
                        helperText={errors.dateOfBirth}
                      />
                    )}
                  />
                </LocalizationProvider>
                <FormHelperText>{errors.dateOfBirth}</FormHelperText>
              </Grid>
              <Grid width={'100%'}>
                <InputLabel className={Style.label}>
                  Please tell us your gender:
                  <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <StyledSelect
                  size='medium'
                  onChange={(e) => {
                    handleFieldChange(e as React.ChangeEvent<HTMLInputElement>)
                  }}
                  className={Style.AnsTxt}
                  IconComponent={(props) => (
                    <IconButton {...props}>
                      <img src={IconChevronDown} alt='IconChevronDown' />
                    </IconButton>
                  )}
                  displayEmpty
                  renderValue={
                    formValues.gender !== ''
                      ? () => formValues.gender
                      : () => 'Select'
                  }
                  value={formValues.gender}
                  fullWidth
                  id='gender'
                  name='gender'
                  error={!!errors.gender}
                >
                  <MenuItem value=''>
                    <em>Select an Answer</em>
                  </MenuItem>
                  <MenuItem value='Female'>Female</MenuItem>
                  <MenuItem value='Male'>Male</MenuItem>
                  <MenuItem value='Transgender'>Transgender</MenuItem>
                </StyledSelect>
                <FormHelperText sx={{ mx: 1.5 }}>
                  {errors.gender}
                </FormHelperText>
              </Grid>
            </Grid>

            <Box mt={3}>
              <Grid xs={12} py={1} width={'100%'}>
                <InputLabel className={Style.label}>
                  How tall are you?
                  <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <Box>
                  <InputLabel className={Style.label}>
                    Feet<span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <Grid
                    display={'flex'}
                    gap={'10px'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    width={'fit-content'}
                  >
                    {[...Array(10)].map((_, index: number) => (
                      <Box
                        key={index}
                        className={
                          formValues?.feet === index + 1
                            ? Style.radioBox
                            : Style.radioBoxActive
                        }
                        onClick={() => {
                          setFormValues({ ...formValues, feet: index + 1 })
                        }}
                      >
                        <InputLabel className={Style.label}>
                          {index + 1}
                        </InputLabel>
                      </Box>
                    ))}
                  </Grid>
                  <FormHelperText sx={{ mx: 1.5 }}>
                    {errors?.feet}
                  </FormHelperText>
                </Box>

                <Box>
                  <InputLabel className={Style.label}>
                    Inches<span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <Grid
                    display={'flex'}
                    gap={'10px'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                  >
                    {[...Array(11)].map((_, index: number) => (
                      <Box
                        key={index}
                        className={
                          formValues?.inch === index + 1
                            ? Style.radioBox
                            : Style.radioBoxActive
                        }
                        onClick={() => {
                          setFormValues({ ...formValues, inch: index + 1 })
                        }}
                      >
                        <InputLabel className={Style.label}>
                          {index + 1}
                        </InputLabel>
                      </Box>
                    ))}
                  </Grid>
                  <FormHelperText sx={{ mx: 1.5 }}>
                    {errors?.inch}
                  </FormHelperText>
                </Box>
              </Grid>

              <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                <Grid width={'100%'}>
                  <InputLabel className={Style.label}>
                    How much do you weight (lbs)?
                    <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    type='number'
                    id='weight'
                    name='weight'
                    inputProps={{ maxLength: 3 }}
                    className={Style.AnsTxt}
                    placeholder='Enter weight'
                    value={formValues?.weight ?? ''}
                    onKeyDown={(evt) => {
                      ;['e', 'E', '+', '-'].includes(evt.key) &&
                        evt.preventDefault()
                    }}
                    onChange={(e) => {
                      if (
                        e.target.value.length <= 3 &&
                        /^[0-9]*$/.test(e.target.value)
                      ) {
                        handleFieldChange(
                          e as React.ChangeEvent<HTMLInputElement>,
                        )
                      }
                    }}
                    error={!!errors.weight}
                    helperText={errors.weight}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box className={Style.AddButton}>
            <div></div>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
            >
              Next
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default GeneralHealth
