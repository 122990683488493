import React, { useState } from 'react'
import { Box, Typography, Button, Grid } from '@mui/material'
import Styles from './index.module.scss'
import star from 'src/Assets/Icons/star.svg'
import DownloadPopUp from 'src/Components/DownloadPopUp'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { AddFinalSubmit } from 'src/Redux/Slices/ApplicationSlices/FinalSubmitFormsSlice'
const FinalSubmit: React.FunctionComponent = () => {
  const [open, setOpen] = useState(false)
  const dispatch = useAppDispatch()
  const applicationId: string = useAppSelector(
    (state) => state.applicationStore.ApplicationId?.applicationId,
  )

  const disqualification = useAppSelector(
    (state) => state.applicationStore.ApplicationId?.disqualification,
  )
  const isDisabled =
    disqualification &&
    Object.values(disqualification).some((value) => value === true)
  const handleSubmit = () => {
    dispatch(AddFinalSubmit({ applicationId }))
  }
  const handleOpen = () => {
    setOpen(true)
  }
  return (
    <>
      <Box className={Styles.right}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className={Styles.list}>
              <Typography variant='body1' className={Styles.textWrapper}>
                Well done on successfully completing the initial disability
                benefits application! We encourage you to download the full
                application to seamlessly initiate the submission process with
                the SSA and advance your client’s case with confidence.
              </Typography>
              {/* <Box className={Styles.alert}>
                <Box className={Styles.frame}>
                  <img src={star} alt='star' className={Styles.image} />
                </Box>
                <Typography
                  variant='body1'
                  className={Styles.readyToSubmitYour}
                >
                  Please reach out to your representative should you have any
                  questions beyond this point. Thank you! step.
                </Typography>
              </Box> */}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={Styles.main}>
              <Box className={Styles.action}>
                <Button
                  variant='contained'
                  onClick={handleSubmit}
                  disabled={isDisabled}
                  className={Styles.divWrapper}
                >
                  <Typography variant='button' className={Styles.textWrapper2}>
                    Submit Application
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <DownloadPopUp open={open} setOpen={setOpen} handleOpen={handleOpen} />
    </>
  )
}

export default FinalSubmit
