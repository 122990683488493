import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Typography,
  Button,
  Grid,
  Divider,
  CardContent,
} from '@mui/material'
import Styles from './index.module.scss'
import DescriptionIcon from '@mui/icons-material/Description'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import UploadDocuments from './EditForms/UploadDocuments'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { GetUploadDocument } from 'src/Redux/Slices/ApplicationSlices/uploadDocumentSlice'
const DocumentDetails: React.FunctionComponent = () => {
  const [editMode, setEditMode] = useState(false)
  const documentRef = useRef<HTMLDivElement>(null)

  const handleEditToggle = () => {
    setEditMode(!editMode)
    documentRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  const dispatch = useAppDispatch()

  const titles = [
    'Employer W2 (Up to 1 year prior ro being diagnosed or being out of work)',
    'Marriage Documentation',
    'Address Documentation',
    'Birth Documentation',
    'Employment Documentation',
    'Education or Training Documentation',
    'Citizenship Documentation (If U.S. resident or naturalized citizen)',
  ]
  const documentId: string = useAppSelector(
    (state) => state.applicationStore.ApplicationId?.uploadDocumentId,
  )

  const [formValues, setFormValues] = useState<any>({
    employerW2: [],
    marriage: [],
    address: [],
    birth: [],
    employment: [],
    educationOrTraining: [],
    citizenship: [],
  })
  const [getData, setGetData] = useState<{
    documentUrls?: Array<{
      url: string
      name: string
      isUpdated: boolean
      documentUrl: string
    }>
  }>({})

  useEffect(() => {
    const keys = Object.keys(formValues)
    const newData = { ...formValues }
    for (let index = 0; index < keys.length; index++) {
      const key = keys[index] as keyof typeof formValues
      newData[key] =
        getData.documentUrls
          ?.filter((item) => item.name === key)
          .map((item) => {
            return item.url
          }) ?? []
    }
    setFormValues(newData)
  }, [getData])

  useEffect(() => {
    if (documentId)
      dispatch(GetUploadDocument(documentId)).then((res) => {
        if (res.payload?.success) {
          setGetData(res.payload?.data)
        }
      })
  }, [editMode])

  const getIconForFile = (url: string, orgUrl: string) => {
    if (url.endsWith('.pdf')) {
      return <PictureAsPdfIcon style={{ fontSize: 100 }} />
    } else if (url.endsWith('.doc') || url.endsWith('.docx')) {
      return <DescriptionIcon style={{ fontSize: 100 }} />
    }
    return (
      <img
        src={orgUrl}
        alt='Document1'
        style={{
          height: '300px',
          width: '300px',
        }}
      />
    )
  }
  return (
    <>
      {!editMode ? (
        <>
          <>
            <div ref={documentRef} />
            <Box className={Styles.MainBox}>
              <Box className={Styles.title}>
                <Box className={Styles.main}>
                  <Typography variant='h6' className={Styles.textWrapper}>
                    Required Documents
                  </Typography>
                  <Button className={Styles.button} onClick={handleEditToggle}>
                    <Typography className={Styles.div}>Edit</Typography>
                  </Button>
                </Box>
                <div className={Styles.dottedLine} />
              </Box>

              {Object.keys(formValues).map((key, index) => {
                const matchedUrls = getData?.documentUrls?.filter((consent) =>
                  formValues[key as keyof typeof formValues].includes(
                    consent.url,
                  ),
                )
                return (
                  <div key={index}>
                    {matchedUrls?.length ? (
                      <>
                        <Box className={Styles.title}>
                          <Typography
                            variant='h6'
                            className={Styles.titleHeading}
                          >
                            {titles[index]}
                          </Typography>
                        </Box>
                        <div className={Styles.dottedLine} />

                        <CardContent className={Styles.cardContent}>
                          {matchedUrls?.map((consent) => (
                            <Box
                              key={consent.url}
                              display='flex'
                              justifyContent='center'
                              alignItems='center'
                              style={{
                                height: '300px',
                                width: '300px',
                                border: '1px solid #ccc',
                                marginBottom: '16px',
                              }}
                            >
                              {getIconForFile(consent.documentUrl, consent.url)}
                            </Box>
                          ))}
                        </CardContent>
                      </>
                    ) : null}
                  </div>
                )
              })}
              <Box sx={{ width: '100%' }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                ></Grid>
              </Box>
            </Box>

            {/* Additional Documents */}
            <Box className={Styles.MainBox}>
              <Box className={Styles.title}>
                <Box className={Styles.main}>
                  <Typography variant='h6' className={Styles.textWrapper}>
                    Additional Documents
                  </Typography>
                </Box>
                <div className={Styles.dottedLine} />
              </Box>

              {Object.keys(formValues).map((key, index) => {
                if (index === 0) {
                  // Skip the first iteration
                  return null
                }
                return (
                  <>
                    {formValues[key].length ? (
                      <>
                        <Box className={Styles.div2}>
                          <Typography variant='h6' className={Styles.SubTitle}>
                            {titles[index]}
                          </Typography>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            {formValues[key].map((document: any) => (
                              <>
                                <Grid item>
                                  <Box>
                                    <img
                                      src={document}
                                      alt='Document1'
                                      style={{
                                        height: '300px',
                                        width: '300px',
                                      }}
                                    />
                                  </Box>
                                </Grid>
                              </>
                            ))}
                          </Grid>
                        </Box>
                      </>
                    ) : null}
                  </>
                )
              })}
            </Box>
          </>
        </>
      ) : (
        <>
          <Grid m={2}>
            <Grid container m={2}>
              <Grid lg={10} className='alignItemCenter' display='flex'>
                <Typography variant='h1' className='FontBold'>
                  Upload Documents
                </Typography>
              </Grid>
              <Divider
                sx={{ width: '99%', marginTop: '8px', marginBottom: '8px' }}
              />
              <Grid container m={2} ml={0}>
                <UploadDocuments handleEditToggle={handleEditToggle} />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default DocumentDetails
