import {
  NotificationType,
  showNotification,
} from 'src/Redux/Slices/notificationSlice'
import CloseIcon from '@mui/icons-material/Close'
import {
  Button,
  Chip,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import IconChevronDown from 'src/Assets/Icons/IconChevronDown.svg'
import quote from 'src/Assets/Icons/quots.svg'
import star from 'src/Assets/Icons/star.svg'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import { VisionAndHearingType } from 'src/Redux/Services/Types/applicationType'
import { AddVisionAndHearing } from 'src/Redux/Slices/ApplicationSlices/MedicalDetailsSlice'
import { setVisionAndHearing } from 'src/Redux/Slices/ApplicationStore/MedicalDetailsStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import Style from '../index.module.scss'
import { JsonData } from 'src/Redux/Slices/ApplicationSlices/chatbotSlice'

interface OptionsType {
  value: string
  label: string
}

const StyledSelect = styled(Select)({
  borderRadius: '10px', // Set the border radius to your desired value
})

const VisionHearing = ({
  chatForm,
  handleNext,
  handleBack,
}: {
  chatForm: any
  handleBack: () => void
  handleNext: () => void
}) => {
  const dispatch = useAppDispatch()

  const MedicalDetailId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.MedicalDetailId,
  )
  const applicationId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )
  const VisionAndHearingState = useAppSelector(
    (state) => state?.applicationStore?.MedicalDetails?.VisionAndHearing,
  )

  const [formValues, setFormValues] = useState<VisionAndHearingType>({
    medicalDetailId: MedicalDetailId,
    zeroOrLessVision2020: undefined,
    visionCorrectorDetail: '',
    isImpairedVision: undefined,
    visionRelatedSymptoms: [],
    additionalVisionRelatedSymptoms: '',
    isPartialOrTotalDeaf: undefined,
    isUsingHearingAids: undefined,
    isImpairedHearing: undefined,
    hearingSymptoms: [],
    additionalHearingRelatedSymptoms: '',
  })

  const isBotOpen = useAppSelector(
    (state) => state.applicationStore.ApplicationStep.isBotOpen,
  )
  useEffect(() => {
    setFormValues({
      ...VisionAndHearingState,
      medicalDetailId: MedicalDetailId,
    })
  }, [])
  useEffect(() => {
    if (isBotOpen)
      dispatch(
        JsonData({
          uuid: applicationId,
          data: {
            ...formValues,
            zeroOrLessVision2020:
              formValues?.zeroOrLessVision2020 === undefined
                ? 'Boolean'
                : formValues?.zeroOrLessVision2020,
            isImpairedVision:
              formValues?.isImpairedVision === undefined
                ? 'Boolean'
                : formValues?.isImpairedVision,
            isPartialOrTotalDeaf:
              formValues?.isPartialOrTotalDeaf === undefined
                ? 'Boolean'
                : formValues?.isPartialOrTotalDeaf,
            isUsingHearingAids:
              formValues?.isUsingHearingAids === undefined
                ? 'Boolean'
                : formValues?.isUsingHearingAids,
            isImpairedHearing:
              formValues?.isImpairedHearing === undefined
                ? 'Boolean'
                : formValues?.isImpairedHearing,
          },
        }),
      )
  }, [isBotOpen])

  useEffect(() => {
    if (chatForm)
      setFormValues((prevState) => ({
        ...prevState,
        ...chatForm,
      }))
  }, [chatForm])

  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}

    if (formValues?.zeroOrLessVision2020 === undefined) {
      newErrors.zeroOrLessVision2020 = 'please select option'
      valid = false
    }

    if (formValues?.isPartialOrTotalDeaf === undefined) {
      newErrors.isPartialOrTotalDeaf = 'please select option'
      valid = false
    }

    if (formValues?.isUsingHearingAids === undefined) {
      newErrors.isUsingHearingAids = 'please select option'
      valid = false
    }

    if (formValues?.zeroOrLessVision2020 === false) {
      if (!formValues?.visionCorrectorDetail.trim()) {
        newErrors.visionCorrectorDetail = 'please enter description'
        valid = false
      }
      if (formValues?.visionCorrectorDetail !== 'None of the above') {
        if (formValues?.isImpairedVision === undefined) {
          newErrors.isImpairedVision = 'please select option'
          valid = false
        }
      }
      if (
        formValues.isImpairedVision &&
        formValues?.visionCorrectorDetail !== 'None of the above'
      ) {
        if (formValues.visionRelatedSymptoms.length === 0) {
          newErrors.visionRelatedSymptoms =
            'Please select at least one country for citizenship'
          valid = false
        }
        // if (!formValues.additionalVisionRelatedSymptoms.trim()) {
        //   newErrors.additionalVisionRelatedSymptoms = 'please enter description'
        //   valid = false
        // }
      }
    }

    if (formValues.isUsingHearingAids) {
      if (formValues?.isImpairedHearing === undefined) {
        newErrors.isImpairedHearing = 'please select option'
        valid = false
      }
      if (formValues.isImpairedHearing) {
        if (formValues.hearingSymptoms.length === 0) {
          newErrors.hearingSymptoms = 'Please select option'
          valid = false
        }
        // if (!formValues.additionalHearingRelatedSymptoms.trim()) {
        //   newErrors.additionalHearingRelatedSymptoms =
        //     'please enter description'
        //   valid = false
        // }
      }
    }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    // Handle form submission here, you can access formValues to submit data
    if (!validateForm()) {
      dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            'Some fields are incorrect or empty. Please review form again.',
        }),
      )
    } else {
      const formValuesToSubmit: typeof formValues = {}
      formValuesToSubmit.medicalDetailId = formValues.medicalDetailId
      formValuesToSubmit.zeroOrLessVision2020 = formValues.zeroOrLessVision2020
      formValuesToSubmit.isPartialOrTotalDeaf = formValues.isPartialOrTotalDeaf
      formValuesToSubmit.isUsingHearingAids = formValues.isUsingHearingAids
      if (formValues.zeroOrLessVision2020 === false) {
        formValuesToSubmit.visionCorrectorDetail =
          formValues.visionCorrectorDetail
        formValuesToSubmit.isImpairedVision = formValues.isImpairedVision
        if (
          formValues.isImpairedVision &&
          formValues?.visionCorrectorDetail !== 'None of the above'
        ) {
          formValuesToSubmit.visionRelatedSymptoms =
            formValues.visionRelatedSymptoms
          formValuesToSubmit.additionalVisionRelatedSymptoms =
            formValues.additionalVisionRelatedSymptoms
        }
      }
      if (formValues.isUsingHearingAids) {
        formValuesToSubmit.isImpairedHearing = formValues.isImpairedHearing
        if (formValues.isImpairedHearing) {
          formValuesToSubmit.hearingSymptoms = formValues.hearingSymptoms
          formValuesToSubmit.additionalHearingRelatedSymptoms =
            formValues.additionalHearingRelatedSymptoms
        }
      }

      dispatch(setVisionAndHearing(formValues))
      dispatch(AddVisionAndHearing(formValuesToSubmit)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res?.payload?.success) {
            handleNext()
          }
        },
      )
    }
  }

  const handleSelectChange = (name: string, type: string) => {
    // Check if the item is already selected
    const typeOfPays = formValues[type]?.includes(name)

    if (typeOfPays) {
      // Remove the item from the array
      setFormValues((prevValues) => ({
        ...prevValues,
        [type]: prevValues[type]?.filter((item: string) => item !== name),
      }))
    } else {
      // Add the item to the array
      setFormValues((prevValues) => ({
        ...prevValues,
        [type]: [...prevValues[type], name],
      }))
    }
  }

  const visionIssueOptions = [
    { value: 'Blind in my left eye', label: 'Blind in my left eye' },
    { value: 'Blind in my right eye', label: 'Blind in my right eye' },
    { value: 'Blurry vision', label: 'Blurry vision' },
    { value: 'Double vision', label: 'Double vision' },
    { value: 'Floaters', label: 'Floaters' },
    { value: 'Lack of depth perception', label: 'Lack of depth perception' },
    { value: 'Lack of peripheral vision', label: 'Lack of peripheral vision' },
    {
      value: 'Statutory blindness (i.e., legally blind)',
      label: 'Statutory blindness (i.e., legally blind)',
    },
    { value: 'Seeing halos', label: 'Seeing halos' },
    { value: 'Sensitivity to light', label: 'Sensitivity to light' },
    {
      value: 'Total blindness in both eyes',
      label: 'Total blindness in both eyes',
    },
    { value: 'Other', label: 'Other' },
  ]

  const hearingIssueOptions = [
    { value: 'Hearing loss in left ear', label: 'Hearing loss in left ear' },
    { value: 'Hearing loss in right ear', label: 'Hearing loss in right ear' },
    { value: 'Ringing in left ear', label: 'Ringing in left ear' },
    { value: 'Ringing in right ear', label: 'Ringing in right ear' },
    { value: 'Sensitivity to noise', label: 'Sensitivity to noise' },
    { value: 'Other', label: 'Other' },
  ]

  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'flex-start'}
            sx={{
              py: 2,
              my: 3,
              borderRadius: '10px',
            }}
          >
            <img src={quote} className={Style.star} alt='' />
            <Typography className={Style.Heading}>
              Next topic! It's important to know that having a condition related
              to vision may allow your application to be reviewed more quickly.
              Please help us understand whether your situation qualifies for
              this category.
            </Typography>
          </Box>

          {/* Common radio */}
          <Box>
            <CommonRadioBox
              Style={Style}
              title='Do you have 20/200 or less vision?'
              commonState={formValues?.zeroOrLessVision2020}
              setcommonState={(value: boolean) => {
                setFormValues({ ...formValues, zeroOrLessVision2020: value })
              }}
              YesTitle='I have 20/200 or less vision'
              NoTitle='I do not have 20/200 or less vision'
            />
            <FormHelperText sx={{ mx: 1.5 }}>
              {errors?.zeroOrLessVision2020}
            </FormHelperText>
          </Box>

          {!formValues?.zeroOrLessVision2020 ? (
            <>
              <Box my={'60px'}>
                <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      Please tell us whether you use any vision correctors.
                      <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <StyledSelect
                      onChange={(e) => {
                        handleFieldChange(
                          e as React.ChangeEvent<HTMLInputElement>,
                        )
                      }}
                      className={Style.AnsTxt}
                      IconComponent={(props) => (
                        <IconButton {...props}>
                          <img src={IconChevronDown} alt='IconChevronDown' />
                        </IconButton>
                      )}
                      renderValue={
                        formValues.visionCorrectorDetail !== ''
                          ? () => formValues.visionCorrectorDetail
                          : () => 'Select'
                      }
                      value={formValues.visionCorrectorDetail}
                      fullWidth
                      placeholder='Select'
                      id='visionCorrectorDetail'
                      name='visionCorrectorDetail'
                      error={!!errors.visionCorrectorDetail}
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value='Glasses'>Glasses</MenuItem>
                      <MenuItem value='Contacts'>Contacts</MenuItem>
                      <MenuItem value='None of the above'>
                        None of the above
                      </MenuItem>
                    </StyledSelect>
                    <FormHelperText sx={{ mx: 1.5 }}>
                      {errors.visionCorrectorDetail}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </Box>

              {formValues?.visionCorrectorDetail !== 'None of the above' ? (
                <>
                  <Box>
                    <CommonRadioBox
                      Style={Style}
                      title='Do you still experience impaired vision even with glasses or contacts?'
                      commonState={formValues?.isImpairedVision}
                      setcommonState={(value: boolean) => {
                        setFormValues({
                          ...formValues,
                          isImpairedVision: value,
                        })
                      }}
                      YesTitle='I still experience impaired vision even with glasses or contacts'
                      NoTitle='I do not still experience impaired vision even with glasses or contacts.'
                    />
                    <FormHelperText sx={{ mx: 1.5 }}>
                      {errors?.isImpairedVision}
                    </FormHelperText>
                  </Box>

                  {formValues.isImpairedVision ? (
                    <>
                      <Box my={'60px'}>
                        <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                          <Grid width={'100%'}>
                            <InputLabel className={Style.label}>
                              Tell us whether you experience any of the
                              following vision-related symptoms. Check all that
                              apply.<span style={{ color: 'red' }}>*</span>
                            </InputLabel>
                            <FormControl fullWidth>
                              <StyledSelect
                                // multiple
                                id='visionRelatedSymptoms'
                                name='visionRelatedSymptoms'
                                className={Style.AnsTxt}
                                value={formValues?.visionRelatedSymptoms}
                                renderValue={() => (
                                  <Box display='flex' flexWrap='wrap' gap={1}>
                                    {formValues?.visionRelatedSymptoms?.map(
                                      (value, index) => (
                                        <Chip
                                          key={index}
                                          className={Style.ChipItem}
                                          label={value}
                                          onMouseDown={(event) => {
                                            event.stopPropagation()
                                          }}
                                          onDelete={(e) => {
                                            e.stopPropagation()
                                            handleSelectChange(
                                              value,
                                              'visionRelatedSymptoms',
                                            )
                                            setFormValues((prevState) => ({
                                              ...prevState,
                                              visionRelatedSymptoms:
                                                prevState.visionRelatedSymptoms.filter(
                                                  (item: string) =>
                                                    item !== value,
                                                ),
                                            }))
                                          }}
                                          deleteIcon={
                                            <CloseIcon
                                              onClick={(e) => {
                                                e.stopPropagation()
                                                handleSelectChange(
                                                  value,
                                                  'visionRelatedSymptoms',
                                                )
                                                setFormValues((prevState) => ({
                                                  ...prevState,
                                                  visionRelatedSymptoms:
                                                    prevState.visionRelatedSymptoms.filter(
                                                      (item) => item !== value,
                                                    ),
                                                }))
                                              }}
                                            />
                                          }
                                        />
                                      ),
                                    )}
                                  </Box>
                                )}
                              >
                                <Box sx={{ height: '250px', overflow: 'auto' }}>
                                  {visionIssueOptions?.map(
                                    (item: OptionsType, index: number) => (
                                      <MenuItem
                                        key={index}
                                        onClick={() => {
                                          handleSelectChange(
                                            item.value,
                                            'visionRelatedSymptoms',
                                          )
                                        }}
                                        value={item.value}
                                      >
                                        {item.value}
                                      </MenuItem>
                                    ),
                                  )}
                                </Box>
                              </StyledSelect>
                              <FormHelperText sx={{ mx: 1.5 }}>
                                {errors.visionRelatedSymptoms}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Box>

                      <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                        <Grid width={'100%'}>
                          <InputLabel className={Style.label}>
                            Please provide any additional vision-related
                            symptoms here.
                            <span style={{ color: 'red' }}>*</span>
                          </InputLabel>
                          <TextField
                            fullWidth
                            type='text'
                            id='additionalVisionRelatedSymptoms'
                            name='additionalVisionRelatedSymptoms'
                            multiline
                            rows={4}
                            className={Style.AnsTxt}
                            placeholder='Other'
                            value={formValues?.additionalVisionRelatedSymptoms}
                            onChange={handleFieldChange}
                            error={!!errors.additionalVisionRelatedSymptoms}
                            helperText={errors.additionalVisionRelatedSymptoms}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          {/* <Box
            width={'100%'}
            display={'flex'}
            alignItems={'flex-start'}
            sx={{
              p: 2,
              my: 4,
              backgroundColor: '#ECF6FF',
              borderRadius: '10px',
            }}
          >
            <img src={star} className={Style.star} alt='' /> */}
          <Typography className={Style.label}>
            Now, help us understand your hearing situation.
          </Typography>
          {/* </Box> */}

          <Box>
            {/* Common radio */}
            <CommonRadioBox
              Style={Style}
              title='Are you partially or totally deaf?'
              commonState={formValues?.isPartialOrTotalDeaf}
              setcommonState={(value: boolean) => {
                setFormValues({
                  ...formValues,
                  isPartialOrTotalDeaf: value,
                })
              }}
              YesTitle='I am partially or totally deaf'
              NoTitle='I am not partially or totally deaf'
            />
            <FormHelperText sx={{ mx: 1.5 }}>
              {errors.isPartialOrTotalDeaf}
            </FormHelperText>
          </Box>

          <Box>
            {/* Common radio */}
            <CommonRadioBox
              Style={Style}
              title='Please share if you use any hearing aids.'
              commonState={formValues?.isUsingHearingAids}
              setcommonState={(value: boolean) => {
                setFormValues({ ...formValues, isUsingHearingAids: value })
              }}
              YesTitle='I use hearing aids'
              NoTitle='I do not use any hearing aids'
            />
            <FormHelperText sx={{ mx: 1.5 }}>
              {errors.isUsingHearingAids}
            </FormHelperText>
          </Box>

          {formValues.isUsingHearingAids ? (
            <>
              <Box>
                {/* Common radio */}
                <CommonRadioBox
                  Style={Style}
                  title='Do you still experience impaired hearing, even with hearing aids?'
                  commonState={formValues?.isImpairedHearing}
                  setcommonState={(value: boolean) => {
                    setFormValues({ ...formValues, isImpairedHearing: value })
                  }}
                  YesTitle='I still experience impaired hearing even with hearing aids'
                  NoTitle='I do not still experience impaired hearing, even with hearing aids'
                />
                <FormHelperText sx={{ mx: 1.5 }}>
                  {errors.isImpairedHearing}
                </FormHelperText>
              </Box>

              {formValues.isImpairedHearing ? (
                <>
                  <Box my={'60px'}>
                    <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                      <Grid width={'100%'}>
                        <InputLabel className={Style.label}>
                          Tell us whether you experience any of the following
                          hearing-related symptoms. Check all that apply.
                          <span style={{ color: 'red' }}>*</span>
                        </InputLabel>
                        <FormControl fullWidth>
                          <StyledSelect
                            // multiple
                            id='hearingSymptoms'
                            name='hearingSymptoms'
                            className={Style.AnsTxt}
                            value={formValues?.hearingSymptoms}
                            renderValue={() => (
                              <Box display='flex' flexWrap='wrap' gap={1}>
                                {formValues?.hearingSymptoms?.map(
                                  (value, index) => (
                                    <Chip
                                      key={index}
                                      className={Style.ChipItem}
                                      label={value}
                                      onMouseDown={(event) => {
                                        event.stopPropagation()
                                      }}
                                      onDelete={(e) => {
                                        e.stopPropagation()
                                        handleSelectChange(
                                          value,
                                          'hearingSymptoms',
                                        )
                                        setFormValues((prevState) => ({
                                          ...prevState,
                                          hearingSymptoms:
                                            prevState.hearingSymptoms.filter(
                                              (item) => item !== value,
                                            ),
                                        }))
                                      }}
                                      deleteIcon={
                                        <CloseIcon
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            handleSelectChange(
                                              value,
                                              'hearingSymptoms',
                                            )
                                            setFormValues((prevState) => ({
                                              ...prevState,
                                              hearingSymptoms:
                                                prevState.hearingSymptoms.filter(
                                                  (item) => item !== value,
                                                ),
                                            }))
                                          }}
                                        />
                                      }
                                    />
                                  ),
                                )}
                              </Box>
                            )}
                          >
                            <Box sx={{ height: '250px', overflow: 'auto' }}>
                              {hearingIssueOptions?.map(
                                (item: OptionsType, index: number) => (
                                  <MenuItem
                                    key={index}
                                    onClick={() => {
                                      handleSelectChange(
                                        item.value,
                                        'hearingSymptoms',
                                      )
                                    }}
                                    value={item.value}
                                  >
                                    {item.value}
                                  </MenuItem>
                                ),
                              )}
                            </Box>
                          </StyledSelect>
                          <FormHelperText sx={{ mx: 1.5 }}>
                            {errors.hearingSymptoms}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>

                  <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                    <Grid width={'100%'}>
                      <InputLabel className={Style.label}>
                        Please provide any additional hearing-related symptoms
                        here.<span style={{ color: 'red' }}>*</span>
                      </InputLabel>
                      <TextField
                        fullWidth
                        type='text'
                        id='additionalHearingRelatedSymptoms'
                        name='additionalHearingRelatedSymptoms'
                        multiline
                        rows={4}
                        className={Style.AnsTxt}
                        placeholder='Other'
                        value={formValues?.additionalHearingRelatedSymptoms}
                        onChange={handleFieldChange}
                        error={!!errors.additionalHearingRelatedSymptoms}
                        helperText={errors.additionalHearingRelatedSymptoms}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={handleBack}
            >
              Previous
            </Button>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
            >
              Next
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default VisionHearing
