import {
  NotificationType,
  showNotification,
} from 'src/Redux/Slices/notificationSlice'
import { Button, FormHelperText, InputLabel, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Style from './index.module.scss'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import quote from 'src/Assets/Icons/quots.svg'
import star from 'src/Assets/Icons/star.svg'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import ProfileBox from './ProfileBox'
import { AddDependents } from 'src/Redux/Slices/ApplicationSlices/FamilyDetailsSlice'
import { DependentsType } from 'src/Redux/Services/Types/applicationType'
import { setDependents } from 'src/Redux/Slices/ApplicationStore/FamilyDetailsStore'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { AddButton, ArraySelection, StarText } from 'src/Components/Fields'

const Dependents = ({ handleEditToggle }: { handleEditToggle: () => void }) => {
  const dispatch = useAppDispatch()

  const storeValues = useAppSelector(
    (state) => state?.applicationStore?.FamilyDetails?.Dependents,
  )
  const familyId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.familyId,
  )

  const [formValues, setFormValues] = useState<DependentsType>({
    familyId,
    haveAnyChildern: undefined,
    numberOfChildern: '',
    dependents: [
      {
        firstName: '',
        lastName: '',
        isYoungerThan17: undefined,
        isChildMarried: undefined,
        isChildDisabled: undefined,
        isChild1819SchoolUnmarried: undefined,
        isChildDisabledBeforeAge22: undefined,
      },
    ],
  })

  useEffect(() => {
    setFormValues({
      ...storeValues,
      familyId,
      // dependents: storeValues?.dependents?.map((dependent) => ({
      //   firstName: dependent?.firstName ?? '',
      //   lastName: dependent?.lastName ?? '',
      //   isYoungerThan17: dependent.isYoungerThan17 ?? undefined,
      //   isChildMarried: !dependent.isChild1819SchoolUnmarried ?? undefined,
      //   isChildDisabled: dependent.isChildDisabledBeforeAge22 ?? undefined,
      //   isChild1819SchoolUnmarried:
      //     dependent.isChild1819SchoolUnmarried ?? undefined,
      //   isChildDisabledBeforeAge22:
      //     dependent.isChildDisabledBeforeAge22 ?? undefined,
      // })),
    })
  }, [])

  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const validateForm = () => {
    let isValid = true
    const newErrors: Record<string, string> = {}

    if (formValues?.haveAnyChildern === undefined) {
      newErrors.haveAnyChildern = 'Please select an option'
      isValid = false
    }
    if (formValues?.haveAnyChildern) {
      formValues.dependents.forEach((legalName, index) => {
        const { firstName, lastName } = legalName

        const validationRules = [
          {
            field: `firstName[${index}]`,
            label: 'First Name',
            value: firstName.trim(),
          },
          {
            field: `lastName[${index}]`,
            label: 'Last Name',
            value: lastName.trim(),
          },
        ]

        const booleanValidationRules = [
          {
            field: `isYoungerThan17[${index}]`,
            label: 'Is younger than 17',
            value: formValues?.dependents[index].isYoungerThan17,
          },
        ]
        if (formValues?.dependents[index].isYoungerThan17) {
          booleanValidationRules.push(
            {
              field: `isChildMarried[${index}]`,
              label: 'Is child married',
              value: formValues?.dependents[index].isChildMarried,
            },
            {
              field: `isChildDisabled[${index}]`,
              label: 'Is child disabled',
              value: formValues?.dependents[index].isChildDisabled,
            },
          )
        } else {
          booleanValidationRules.push(
            {
              field: `isChild1819SchoolUnmarried[${index}]`,
              label: 'Is child 18-19 and in school unmarried',
              value: formValues?.dependents[index].isChild1819SchoolUnmarried,
            },
            {
              field: `isChildDisabledBeforeAge22[${index}]`,
              label: 'Is child disabled before age 22',
              value: formValues?.dependents[index].isChildDisabledBeforeAge22,
            },
          )
        }
        booleanValidationRules.forEach((rule) => {
          if (typeof rule.value === 'undefined') {
            newErrors[rule.field] = `${rule.label} is required`
            isValid = false
          }
        })
        if (formValues?.dependents) {
          validationRules.forEach((rule) => {
            if (!rule.value) {
              newErrors[rule.field] = `${rule.label} is required`
              isValid = false
            } else if (!/^[A-Za-z ]+$/.test(rule.value)) {
              newErrors[rule.field] = `${rule.label} must contain only letters`
              isValid = false
            } else {
              // Update error message to an empty string if value is valid
              newErrors[rule.field] = ''
            }
          })
        }
      })
    }

    setErrors(newErrors)
    return isValid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    // Handle form submission here, you can access formValues to submit data
    if (!validateForm()) {
      dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            'Some fields are incorrect or empty. Please review form again.',
        }),
      )
    } else {
      const formValuesToSubmit: typeof formValues = {}
      formValuesToSubmit.familyId = familyId
      formValuesToSubmit.haveAnyChildern = formValues.haveAnyChildern
      if (formValues.haveAnyChildern) {
        formValuesToSubmit.numberOfChildern =
          formValues.numberOfChildern?.toString()

        formValuesToSubmit.dependents = formValues.dependents.map(
          (dependent) => {
            if (dependent.isYoungerThan17)
              return {
                firstName: dependent.firstName,
                lastName: dependent.lastName,
                isYoungerThan17: dependent.isYoungerThan17,
                isChildMarried: dependent.isChildMarried,
                isChildDisabled: dependent.isChildDisabled,
              }
            return {
              firstName: dependent.firstName,
              lastName: dependent.lastName,
              isYoungerThan17: dependent.isYoungerThan17,
              isChild1819SchoolUnmarried: dependent.isChild1819SchoolUnmarried,
              isChildDisabledBeforeAge22: dependent.isChildDisabledBeforeAge22,
            }
          },
        )
      }

      dispatch(AddDependents(formValuesToSubmit)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res?.payload?.success) {
            dispatch(setDependents(formValues))
            handleEditToggle()
          }
        },
      )
    }
  }

  const handleValuesChange = (
    index: number,
    data: { name: string; value: string },
  ) => {
    const newProfileForms = formValues.dependents.map((dependent, i) => {
      if (i !== index) {
        return dependent
      }
      return {
        ...dependent,
        [data.name]: data.value,
      }
    })
    setFormValues({ ...formValues, dependents: newProfileForms })
  }

  const handleRemove = (index: number) => {
    const newProfileForms = [...formValues.dependents]
    newProfileForms.splice(index, 1)
    setFormValues({ ...formValues, dependents: newProfileForms })
  }

  const addProfileBox = () => {
    const newForm = {
      firstName: '',
      lastName: '',
      isYoungerThan17: undefined,
      isChildMarried: undefined,
      isChildDisabled: undefined,
      isChild1819SchoolUnmarried: undefined,
      isChildDisabledBeforeAge22: undefined,
    }
    setFormValues({
      ...formValues,
      dependents: [...formValues.dependents, newForm],
    })
  }

  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'flex-start'}
            sx={{
              py: 2,
              my: 3,
              borderRadius: '10px',
            }}
          >
            <img src={quote} className={Style.star} alt='' />
            <Typography className={Style.Heading}>
              Let's talk about dependents
            </Typography>
          </Box>

          {/* Common radio */}
          <CommonRadioBox
            error={errors?.haveAnyChildern}
            title='Do you have any children?'
            commonState={formValues?.haveAnyChildern}
            setcommonState={(value: boolean) => {
              setFormValues((prevState: any) => ({
                ...prevState,
                haveAnyChildern: value,
              }))
            }}
            YesTitle='children'
            NoTitle='I do not have any children'
          />
          <Box width={'100%'} height={'20px'} />

          {formValues?.haveAnyChildern && (
            <>
              <StarText
                text={`Note that this includes biological and adopted children.
                  All families are different, and we love them all equally!`}
              />

              <Box width={'100%'} height={'20px'} />

              <ArraySelection
                formValue={formValues.numberOfChildern ?? 0}
                values={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                title='How many biological and/or adopted children do you have?'
                handleItemClick={(value) => {
                  setFormValues({
                    ...formValues,
                    numberOfChildern: value,
                  })
                }}
              />

              <Box width={'100%'} height={'30px'} />

              {/* <StarText
                text={`All families are different, and we love them all equally!`}
              /> */}

              <Box width={'100%'} height={'20px'} />

              <Typography className={Style.label}>
                Dependent Information: Please provide information for each of
                your children from youngest to oldest.
              </Typography>

              {formValues?.dependents?.map((form, index) => (
                <ProfileBox
                  errors={errors}
                  key={index}
                  handleRemove={() => {
                    handleRemove(index)
                  }}
                  index={index}
                  formValues={form}
                  changeHandler={(data: { name: string; value: string }) => {
                    handleValuesChange(index, data)
                  }}
                />
              ))}
              <AddButton
                button='Add Child'
                title='Provide more information for each of your children'
                subTitle='For comprehensive representation of your situation, It is important that all children be included'
                onClick={addProfileBox}
              />
            </>
          )}

          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={() => {
                // setIsSubmitted(false)
                handleEditToggle()
              }}
              // sx={{ visibility: 'hidden' }}
            >
              Discard
            </Button>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
              // onClick={handleNext}
            >
              Save & Update
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default Dependents
