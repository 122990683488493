import {
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconChevronDown from 'src/Assets/Icons/IconChevronDown.svg'
import remove from 'src/Assets/Icons/remove.svg'
import { Calander } from 'src/Components/Fields'
import { PassApplicationType } from 'src/Redux/Services/Types/applicationType'
import { BodyPart, MedicalTest } from 'src/Utils/Constants'
import styled from 'styled-components'
import Style from './index.module.scss'

interface medicalProfessionalsType {
  medicalTestName: string
  suffix: string
  doctorFirstName: string
  doctorLastName: string
  testedBodyPart: string
  mostRecentDateTestPerformed: string
  futureDateTestPerform: string
}

const StyledSelect = styled(Select)({
  borderRadius: '10px',
})

const DetailBox = ({
  formValues,
  realValue,
  setFormValues,
  changeHandler,
  handleRemove,
  index,
  errors,
}: {
  formValues: medicalProfessionalsType
  realValue: PassApplicationType
  setFormValues: React.Dispatch<React.SetStateAction<PassApplicationType>>
  changeHandler: (data: { name: string; value: string }) => void
  handleRemove: () => void
  index: number
  errors: Record<string, string>
}) => {
  return (
    <>
      <Box className={Style.profileBox}>
        <Grid
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography className={Style.label}>Profile {index + 1}</Typography>
          {index > 0 && (
            <img
              src={remove}
              alt=''
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleRemove()
              }}
            />
          )}
        </Grid>
        <Grid width={'100%'}>
          <InputLabel className={Style.label}>
            Medical test<span style={{ color: 'red' }}>*</span>
          </InputLabel>
          <StyledSelect
            onChange={(e) => {
              changeHandler({ name: e.target.name, value: e.target.value })
            }}
            className={Style.AnsTxt}
            IconComponent={(props) => (
              <IconButton {...props}>
                <img src={IconChevronDown} alt='IconChevronDown' />
              </IconButton>
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200, // Adjust the value as needed
                },
              },
            }}
            renderValue={
              formValues?.medicalTestName !== ''
                ? () => formValues?.medicalTestName
                : () => 'Select'
            }
            value={formValues?.medicalTestName}
            fullWidth
            id='medicalTestName'
            name='medicalTestName'
            error={!!errors?.medicalTestName}
          >
            <MenuItem value=''>
              <em>Select an Answer</em>
            </MenuItem>
            {MedicalTest.map((item) => (
              <MenuItem key={item.value} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </StyledSelect>
          <FormHelperText sx={{ mx: 1.5 }}>
            {errors?.medicalTestName}
          </FormHelperText>
        </Grid>

        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Prefix<span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <StyledSelect
              onChange={(e) => {
                changeHandler({ name: e.target.name, value: e.target.value })
              }}
              className={Style.AnsTxt}
              IconComponent={(props) => (
                <IconButton {...props}>
                  <img src={IconChevronDown} alt='IconChevronDown' />
                </IconButton>
              )}
              displayEmpty
              renderValue={
                formValues?.suffix !== ''
                  ? () => formValues?.suffix
                  : () => 'Select'
              }
              value={formValues?.suffix}
              fullWidth
              id='suffix'
              name='suffix'
              error={!!errors?.suffix}
            >
              <MenuItem value=''>
                <em>Select an Answer</em>
              </MenuItem>
              <MenuItem value='Dr.'>Dr.</MenuItem>
              <MenuItem value='Ms.'>Ms.</MenuItem>
              <MenuItem value='Mrs.'>Mrs.</MenuItem>
              <MenuItem value='Mr.'>Mr.</MenuItem>
            </StyledSelect>
            <FormHelperText sx={{ mx: 1.5 }}>{errors?.suffix}</FormHelperText>
          </Grid>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Medical Professional's First Name
              <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              type='text'
              id='doctorFirstName'
              name='doctorFirstName'
              placeholder='Enter First Name'
              className={Style.AnsTxt}
              value={formValues?.doctorFirstName}
              onChange={(e) => {
                changeHandler({ name: e.target.name, value: e.target.value })
              }}
              error={!!errors?.doctorFirstName}
              helperText={errors?.doctorFirstName}
            />
          </Grid>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Medical Professional's Last Name
              <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              type='text'
              id='doctorLastName'
              name='doctorLastName'
              placeholder='Enter Last Name'
              className={Style.AnsTxt}
              value={formValues?.doctorLastName}
              onChange={(e) => {
                changeHandler({ name: e.target.name, value: e.target.value })
              }}
              error={!!errors?.doctorLastName}
              helperText={errors?.doctorLastName}
            />
          </Grid>
        </Grid>
        <Grid width={'100%'}>
          <InputLabel className={Style.label}>
            Select the body part on which this test was performed
            <span style={{ color: 'red' }}>*</span>
          </InputLabel>
          <StyledSelect
            onChange={(e) => {
              changeHandler({ name: e.target.name, value: e.target.value })
            }}
            className={Style.AnsTxt}
            IconComponent={(props) => (
              <IconButton {...props}>
                <img src={IconChevronDown} alt='IconChevronDown' />
              </IconButton>
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200, // Adjust the value as needed
                },
              },
            }}
            renderValue={
              formValues?.testedBodyPart !== ''
                ? () => formValues?.testedBodyPart
                : () => 'Select'
            }
            value={formValues?.testedBodyPart}
            fullWidth
            id='testedBodyPart'
            name='testedBodyPart'
            error={!!errors?.testedBodyPart}
          >
            <MenuItem value=''>
              <em>Select an Answer</em>
            </MenuItem>
            {BodyPart.map((item) => (
              <MenuItem key={item.value} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </StyledSelect>
          <FormHelperText sx={{ mx: 1.5 }}>
            {errors?.testedBodyPart}
          </FormHelperText>
        </Grid>

        <Grid
          xs={12}
          gap={2}
          py={1}
          width={'100%'}
          display={'flex'}
          sx={{ alignItems: 'end' }}
        >
          {/* <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Estimate the most recent date on which the test was performed
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: '100%' }}
                value={
                  formValues?.otherTypeOfTherapyDetails
                    ? dayjs(formValues?.otherTypeOfTherapyDetails)
                    : null
                } // Convert to Dayjs object
                onChange={(e) => {
                  changeHandler({
                    name: 'otherTypeOfTherapyDetails',
                    value: dayjs(e).format('YYYY-MM-DD'),
                  })
                }}
                maxDate={dayjs()} // Use Dayjs for maxDate as well
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    fullWidth
                    style={{ width: '100%' }}
                    id='otherTypeOfTherapyDetails'
                    name='otherTypeOfTherapyDetails'
                    placeholder='mm-DD-YYYY'
                    className={Style.AnsTxt}
                    InputProps={{
                      inputProps: { max: dayjs().format('YYYY-MM-DD') }, // Use Dayjs to format the max date
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid> */}

          <Calander
            title={
              'Estimate the most recent date on which the test was performed'
            }
            value={formValues.mostRecentDateTestPerformed}
            name={'mostRecentDateTestPerformed'}
            error={errors?.mostRecentDateTestPerformed}
            handleChange={changeHandler}
          />

          {/* <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              If this test will be completed again in the future, estimate the future date
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: '100%' }}
                value={
                  formValues?.futureDateTestPerform
                    ? dayjs(formValues?.futureDateTestPerform)
                    : null
                } // Convert to Dayjs object
                onChange={(e) => {
                  changeHandler({
                    name: 'futureDateTestPerform',
                    value: dayjs(e).format('YYYY-MM-DD'),
                  })
                }}
                maxDate={dayjs()} // Use Dayjs for maxDate as well
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    fullWidth
                    style={{ width: '100%' }}
                    id='futureDateTestPerform'
                    name='futureDateTestPerform'
                    placeholder='mm-DD-YYYY'
                    className={Style.AnsTxt}
                    InputProps={{
                      inputProps: { max: dayjs().format('YYYY-MM-DD') }, // Use Dayjs to format the max date
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid> */}

          <Calander
            title={
              'If this test will be completed again in the future, estimate the future date'
            }
            value={formValues.futureDateTestPerform}
            name={'futureDateTestPerform'}
            error={errors?.futureDateTestPerform}
            handleChange={changeHandler}
          />
        </Grid>
      </Box>
    </>
  )
}
export default DetailBox
