/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-constant-condition */
/* eslint-disable no-debugger */
// --- Font Family
import SFProDisplayLight from 'src/Assets/Fonts/SF_PRO_Display/SF-Pro-Display-Light.ttf'
import SFProDisplayMedium from 'src/Assets/Fonts/SF_PRO_Display/SF-Pro-Display-Medium.ttf'
import SFProDisplayRegular from 'src/Assets/Fonts/SF_PRO_Display/SF-Pro-Display-Regular.ttf'
import SFProDisplaySemiBold from 'src/Assets/Fonts/SF_PRO_Display/SF-Pro-Display-Semibold.ttf'
import SFUiDisplayBold from 'src/Assets/Fonts/SF_Ui_Display/SF-UI-Display-Bold.ttf'
import SFUiDisplayMedium from 'src/Assets/Fonts/SF_Ui_Display/SF-UI-Display-Medium.ttf'
import SFUiDisplayRegular from 'src/Assets/Fonts/SF_Ui_Display/SF-UI-Display-Regular.ttf'
import SFUiDisplaySemiBold from 'src/Assets/Fonts/SF_Ui_Display/SF-UI-Display-Semibold.ttf'
// --- SVG ICONS
import ApproveApplication from 'src/Assets/Icons/ApproveApplication.svg'
import Cancel from 'src/Assets/Icons/Cancel.svg'
import CompletedApplication from 'src/Assets/Icons/CompletedApplication.svg'
import DeniedApplication from 'src/Assets/Icons/DeniedApplication.svg'
import InReviewApplication from 'src/Assets/Icons/InReviewApplication.svg'
import Filter from 'src/Assets/Icons/Filter.svg'
import FlaggedApplication from 'src/Assets/Icons/App_Incomplete.svg'
import IconChevronDown from 'src/Assets/Icons/IconChevronDown.svg'
import IconChevronRight from 'src/Assets/Icons/IconChevronRight.svg'
import InProgressApplication from 'src/Assets/Icons/InProgressApplication.svg'
import LtsOrgDot from 'src/Assets/Icons/LtsOrgDot.svg'
import LtsOrgDot2 from 'src/Assets/Icons/LtsOrgDot2.svg'
import LtsOrgDot3 from 'src/Assets/Icons/LtsOrgDot3.svg'
import Plus from 'src/Assets/Icons/Plus.svg'
import Search from 'src/Assets/Icons/Search.svg'
import application from 'src/Assets/Icons/applications.svg'
import calander from 'src/Assets/Icons/calander.svg'
import dashboard from 'src/Assets/Icons/dashboard.svg'
import dollarBox from 'src/Assets/Icons/dollarBox.svg'
import dot from 'src/Assets/Icons/dot.svg'
import downSelect from 'src/Assets/Icons/down.svg'
import profile from 'src/Assets/Icons/dummy.svg'
import email from 'src/Assets/Icons/email.svg'
import employee from 'src/Assets/Icons/employee.svg'
import headerLogo from 'src/Assets/Icons/header.svg'
import help from 'src/Assets/Icons/help.svg'
import latestOrganizationIcon from 'src/Assets/Icons/latestOrganization.svg'
import location from 'src/Assets/Icons/location.svg'
import NewRegisteredOrg from 'src/Assets/Icons/newRegisteredOrg.svg'
import notification from 'src/Assets/Icons/notification.svg'
import organization from 'src/Assets/Icons/organization.svg'
import organizations from 'src/Assets/Icons/organizations.svg'
import phone from 'src/Assets/Icons/phone.svg'
import role from 'src/Assets/Icons/role.svg'
import setting from 'src/Assets/Icons/setting.svg'
import subscription from 'src/Assets/Icons/subscription.svg'
import circleDollar from 'src/Assets/Icons/circleDollar.svg'
import threeDot from 'src/Assets/Icons/threeDot.svg'
import TotalRevenueFromOrg from 'src/Assets/Icons/totalRevenueFromOrg.svg'
import upArrow from 'src/Assets/Icons/upArrow.svg'
import downArrow from 'src/Assets/Icons/downArrow.svg'
// --- File Path
import { AxiosResponse } from 'axios'
import secureLocalStorage from 'react-secure-storage'
import { ThunkDispatch } from 'redux-thunk'
import Dashboard from 'src/Components/Dashboard'
import AdminDashboard from 'src/Components/Dashboard/AdminCard'
import AdminApplicationAnalysis from 'src/Components/Dashboard/AdminCard/ApplicationAnalysis'
import AdminApplicationOverview from 'src/Components/Dashboard/AdminCard/ApplicationOverview'
import AdminAvarageTime from 'src/Components/Dashboard/AdminCard/AvarageTime'
import FutureForecastChart from 'src/Components/Dashboard/AdminCard/FutureForecastChart'
import LatestOrganization from 'src/Components/Dashboard/AdminCard/LatestOrganization'
import Card from 'src/Components/Dashboard/AdminCard/OrganizationDetails'
import PersonalDetail from 'src/Components/Dashboard/AdminCard/PersonalDetail'
import RecurringRevenueChart from 'src/Components/Dashboard/AdminCard/RecurringRevenueChart'
import LegalDashboard from 'src/Components/Dashboard/LegalCard'
import LegalApplicationAnalysis from 'src/Components/Dashboard/LegalCard/ApplicationAnalysis'
import LegalApplicationOverview from 'src/Components/Dashboard/LegalCard/ApplicationOverview'
import LegalAvarageTime from 'src/Components/Dashboard/LegalCard/AvarageTime'
import TotalNumberEmployee from 'src/Components/Dashboard/LegalCard/TotalNumberEmployee'
import Header from 'src/Components/Header'
import Main from 'src/Components/Main'
import SideBar from 'src/Components/Sidebar'
import AdminSidebar from 'src/Components/Sidebar/AdminSidebar'
import LegalSidebar from 'src/Components/Sidebar/LegalSidebar'
import PaymentFail from 'src/Components/SignUp/Billing/completed/fail'
import PaymentSuccess from 'src/Components/SignUp/Billing/completed/success'
import Applications from 'src/Pages/Applications'
import ApplicationDetail from 'src/Pages/Applications/ApplicationPage'
import Welcome from 'src/Pages/Applications/WelcomeApplication'
import MainDashboard from 'src/Pages/Dashboard'
import AdminEmployeeDetails from 'src/Pages/Employees/Admin/Details'
import AdminManageEmployees from 'src/Pages/Employees/Admin/Manage'
import LegalEmployeeDetails from 'src/Pages/Employees/Legal/Details'
import LegalManageEmployees from 'src/Pages/Employees/Legal/Manage'
import Role from 'src/Pages/Employees/Role'
import RoleDetails from 'src/Pages/Employees/RoleDetails'
import ForgotPassword from 'src/Pages/ForgotPassword'
import LocationInput from 'src/Pages/Location'
import Notification from 'src/Pages/Notification'
import Organizations from 'src/Pages/Organizations'
import OrganizationDetail from 'src/Pages/Organizations/OrganizationDetails'
import Profile from 'src/Pages/Profile'
import ResetPassword from 'src/Pages/ResetPassword'
import RolePermission from 'src/Pages/RolePermission'
import Setting from 'src/Pages/Setting'
import SignIn from 'src/Pages/SignIn'
import SignUp from 'src/Pages/SignUp'
import SubscriptionPlan from 'src/Pages/SubscriptionPlan/AdminPlan'
import EnhancedPlan from 'src/Pages/SubscriptionPlan/LegalPlan'
import { OrganizationListObj } from 'src/Redux/Services/Types/orgType'
import { SignUpFormType } from 'src/Redux/Services/Types/signupDetailType'
import {
  NotificationType,
  showNotification,
} from 'src/Redux/Slices/notificationSlice'
import { RootState } from 'src/Redux/store'
import { useEffect, useState } from 'react'
import { useAppSelector } from 'src/Redux/hooks'
import {
  BasicDetailsType,
  FamilyDetailsType,
} from 'src/Redux/Services/Types/applicationType'
import { ROUTES } from '../Routes'
import { LogoutRequest } from 'src/Redux/Slices/authSlice'

// Theme typography font weight
export const ALREADY_REGISTERED =
  'The mail you have entered was already Registered. Please try another mail id.'
export const FW_LIGHT = 300
export const FW_REGULAR = 400
export const FW_MEDIUM = 500
export const FW_SEMIMEDIUM = 600
export const FW_BOLD = 700

// Theme typography

export enum Roles {
  ADMIN = 'Admin',
  LEGAL = 'Legal',
}

export enum ORG_TAB_DATA {
  OVERVIEW = 'Overview',
  CONTACT_INFORMATION = 'contact Information',
  PAYMENT_SETTINGS = 'Payment Settings',
  EMPLOYEES = 'Employees',
}

export enum AdminPermissions {
  ADMIN_DASHBOARD = 'Admin Dashboard',
  ORGANIZATIONS = 'Organizations',
  ORGANIZATION_VIEW = 'Organization View',
  EMPLOYEES = 'Admin Employees',
  APPLICATIONS = 'Applications',
  PROFILE = 'Profile',
  SUBSCRIPTIONS = 'Subscriptions',
  ADMIN_USERS = 'Admin Users',
  ROLE_AND_PERMISSION = 'Role & Permission',
  ROLE_AND_PERMISSION_MAP = 'Role & Permission Map',
  NOTIFICATION = 'Notification',
}

export enum LegalPermissions {
  LEGAL_DASHBOARD = 'Legal Dashboard',
  ORGANIZATION_VIEW = 'Organization View',
  ENHANCED_SUBSCRIPTIONS = 'Enhanced Subscriptions',
  EMPLOYEES = 'Legal Employees',
  APPLICATIONS = 'Applications',
  PROFILE = 'Profile',
  ROLE_AND_PERMISSION_MAP = 'Role & Permission Map',
  NOTIFICATION = 'Notification',
}

export const FONT_BIG = '20px'
export const FONT_ONE = '18px'
export const FONT_TWO = '16px'
export const FONT_COMMON = '14px'
export const FONT_SMALL = '12px'

interface StepsType {
  description: string
  label: string
  title: string
}

export const STEPS: Record<string, StepsType> = {
  account: {
    title: 'account',
    label: 'Account Information',
    description: 'Setup your account settings',
  },
  organization: {
    title: 'organization',
    label: 'Organization Details',
    description: 'Setup your organization details',
  },
  personal: {
    title: 'personal',
    label: 'Personal Details',
    description: 'Provide your personal details',
  },
  subscription: {
    title: 'subscription',
    label: 'Subscription Plan',
    description: 'Select subscription plan',
  },
  billing: {
    title: 'billing',
    label: 'Billing',
    description: 'Provide payment details',
  },
}

interface ApplicationStepsType {
  description: string
  label: string
  title: string
  routes: string
}

export const APPLICATION_STEPS: ApplicationStepsType[] = [
  {
    title: 'basic details',
    label: 'Basic Details',
    description: 'Add your basic details',
    routes: ROUTES.BASIC_DETAILS,
  },
  {
    title: 'family details',
    label: 'Family Details',
    description: 'Add your family details',
    routes: ROUTES.FAMILY_DETAILS,
  },
  {
    title: 'legal names & documentation',
    label: 'Legal Names & Documentation',
    description: 'Add your Legal Names & Documentation details',
    routes: ROUTES.LEGAL_DETAILS,
  },
  {
    title: 'disability details',
    label: 'Disability Details',
    description: 'Add your disability details',
    routes: ROUTES.DISABILITY_DETAILS,
  },
  {
    title: 'education & employment',
    label: 'Education & Employment',
    description: 'Add your Education & Employment details',
    routes: ROUTES.EDUCATION_DETAILS,
  },
  {
    title: 'medical details',
    label: 'Medical Details',
    description: 'Add your medical details',
    routes: ROUTES.MEDICAL_DETAILS,
  },
  {
    title: 'upload documents',
    label: 'Upload Documents',
    description: 'Add your required documents',
    routes: ROUTES.UPLOAD_DOCUMENT,
  },
  {
    title: 'sign consent forms',
    label: 'Sign Consent Forms',
    description: 'Add your sign consent forms details',
    routes: ROUTES.SIGN_CONSENT_FORM,
  },
  {
    title: 'submit',
    label: 'Submit',
    description: 'Submit your profile',
    routes: ROUTES.SUBMIT,
  },
]

export const APPLICATION_STATUS = {
  PENDING: 'Pending',
  DRAFT: 'Draft',
  IN_PROGRESS: 'InProgress',
  SUBMITTED: 'Submitted',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  CANCELLED: 'Cancelled',
  COMPLETED: 'Completed',
  FLAGGED: 'Flagged',
}

export const SORTING = {
  ASCENDING: 'Ascending',
  DESCENDING: 'Descending',
}
// session time out periods in minutes

export const TIMER = 2 * 60

// this is for globle use of comman colors
const CMNCOLOR1 = '#FFFFFF' // COLOR_WHITE
const CMNCOLOR2 = '#1A202C' // COLOR_BLACK
const CMNCOLOR3 = '#9D9FA4' // COLOR_BLUE
const CMNCOLOR4 = '#F2F4F8' // COLOR_GRAY
const CMNCOLOR5 = '#C8C9CC' // COLOR_DARK_GRAY
const CMNCOLOR6 = '#6F737A' // COLOR_DARKER_GRAY
const CMNCOLOR7 = '#F5F5F5' // COLOR_WHITE_VARIANT_1
const CMNCOLOR8 = '#433535' // COLOR_COFFEE
const CMNCOLOR9 = '#F8F9FB' // COLOR_WHITE_VARIANT_2
const CMNCOLOR10 = '#E1E4EB' // COLOR_LIGHT_GRAY
const CMNCOLOR11 = '#C8C9CC88' // COLOR_WHITE_VARIANT_3

const COLOR_WHITE = '#FFFFFF'
const COLOR_BLACK = '#1A202C'
const COLOR_GRAY = '#F2F4F8'
const COLOR_DARK_GRAY = '#C8C9CC'
const COLOR_BLUE = '#9D9FA4'
const COLOR_RED = '#FF002D'
const COLOR_DARK_RED = '#d32f2f'
const COLOR_DARKER_GRAY = '#6F737A'
const COLOR_WHITE_VARIANT_1 = '#F5F5F5'
const COLOR_COFFEE = '#433535'
const COLOR_WHITE_VARIANT_2 = '#F8F9FB'
const COLOR_LIGHT_GRAY = '#C8C9CC88'
const MAINCOLOR = '#7752FE'

export const CMNCOLORS = {
  CMNCOLOR1,
  CMNCOLOR2,
  CMNCOLOR3,
  CMNCOLOR4,
  CMNCOLOR5,
  CMNCOLOR6,
  CMNCOLOR7,
  CMNCOLOR8,
  CMNCOLOR9,
  CMNCOLOR10,
  CMNCOLOR11,
}

export const COLORS = {
  COLOR_WHITE,
  COLOR_BLACK,
  COLOR_GRAY,
  COLOR_DARK_GRAY,
  COLOR_BLUE,
  COLOR_RED,
  COLOR_DARKER_GRAY,
  COLOR_WHITE_VARIANT_1,
  COLOR_COFFEE,
  COLOR_WHITE_VARIANT_2,
  COLOR_LIGHT_GRAY,
  COLOR_DARK_RED,
  MAINCOLOR,
}

// --- this is for globle use of comman font style
export const Fonts = {
  SFProDisplayRegular,
  SFProDisplaySemiBold,
  SFProDisplayLight,
  SFProDisplayMedium,
  SFUiDisplayBold,
  SFUiDisplayMedium,
  SFUiDisplayRegular,
  SFUiDisplaySemiBold,
}

// --- this is for globle use of comman svg Icons
export const Icons = {
  profile,
  dashboard,
  employee,
  application,
  subscription,
  circleDollar,
  setting,
  role,
  organization,
  organizations,
  TotalRevenueFromOrg,
  NewRegisteredOrg,
  CompletedApplication,
  FlaggedApplication,
  InProgressApplication,
  ApproveApplication,
  DeniedApplication,
  InReviewApplication,
  calander,
  LtsOrgDot,
  LtsOrgDot2,
  LtsOrgDot3,
  location,
  latestOrganizationIcon,
  dot,
  phone,
  email,
  threeDot,
  upArrow,
  downArrow,
  help,
  notification,
  headerLogo,
  downSelect,
  IconChevronRight,
  Search,
  Filter,
  Plus,
  IconChevronDown,
  Cancel,
  dollarBox,
}

export type DynamicObj = Record<string, boolean | any>

// --- this is for globle use of comman dashboard card file path
export const DashboardMain = {
  Header,
  Main,
  SideBar,
  AdminSidebar,
  LegalSidebar,
}

export const DashboardCard = {
  AdminDashboard,
  LegalDashboard,
  Card,
  TotalNumberEmployee,
  PersonalDetail,
  LatestOrganization,
  FutureForecastChart,
  RecurringRevenueChart,
  AdminApplicationOverview,
  LegalApplicationOverview,
  AdminApplicationAnalysis,
  AdminAvarageTime,
  LegalApplicationAnalysis,
  LegalAvarageTime,
}

export const MainComponent = {
  RolePermission,
  Organizations,
  LegalManageEmployees,
  AdminManageEmployees,
  LegalEmployeeDetails,
  AdminEmployeeDetails,
  Role,
  RoleDetails,
  Applications,
  SubscriptionPlan,
  EnhancedPlan,
  Setting,
  Dashboard,
  Profile,
  OrganizationDetail,
  Notification,
}

export const RouteComponent = {
  SignIn,
  ForgotPassword,
  ResetPassword,
  SignUp,
  MainDashboard,
  PaymentFail,
  PaymentSuccess,
  LocationInput,
}

export const ApplicationComponent = {
  Welcome,
  ApplicationDetail,
}

export const convertISOToDate = (isoDate: string | number | Date) => {
  const dateObj = new Date(isoDate)

  // Check if the date object is valid
  if (isNaN(dateObj.getTime())) {
    // Return empty string if the date is not valid
    return ''
  }

  // Options for formatting the date
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  }

  // Convert the date to a localized date string
  return dateObj.toLocaleDateString('en-US', options)
}
export interface ApplicationsDataType {
  ASSIGNED_BY: string
  TIME: string
  APPLICATION_TYPE: string
  STATUS: string
}

export function parseAddressComponents(addressComponents: any) {
  let streetAddress = ''
  let city = ''
  let state = ''
  let zipCode = ''
  let country = ''

  if (addressComponents) {
    const routeComponent = addressComponents?.find(
      (component: { types: string | string[] }) =>
        component.types.includes('route'),
    )
    const localityComponent = addressComponents.find(
      (component: { types: string | string[] }) =>
        component.types.includes('locality'),
    )
    const area1Component = addressComponents.find(
      (component: { types: string | string[] }) =>
        component.types.includes('administrative_area_level_1'),
    )
    const postalCodeComponent = addressComponents.find(
      (component: { types: string | string[] }) =>
        component.types.includes('postal_code'),
    )
    const countryComponent = addressComponents.find(
      (component: { types: string | string[] }) =>
        component.types.includes('country'),
    )

    if (routeComponent && localityComponent) {
      streetAddress = `${routeComponent.long_name}, ${localityComponent.long_name}`
    } else if (routeComponent) {
      streetAddress = routeComponent.long_name
    } else if (localityComponent) {
      streetAddress = localityComponent.long_name
    } else if (area1Component) {
      streetAddress = area1Component.long_name
    }

    city = localityComponent?.long_name ?? ''
    state = area1Component?.long_name ?? ''
    zipCode = postalCodeComponent?.long_name ?? ''
    country = countryComponent?.long_name ?? ''
  }

  return { streetAddress, city, state, zipCode, country }
}

// Temporary ApplicationsData array to simulate the Applications data on mapping
export const ApplicationsData: ApplicationsDataType[] = [
  {
    ASSIGNED_BY: 'Jessica Drew',
    TIME: '6 hours ago',
    APPLICATION_TYPE: 'Test',
    STATUS: 'In Progress',
  },
  {
    ASSIGNED_BY: 'Bill Gates',
    TIME: '6 hours ago',
    APPLICATION_TYPE: 'Test',
    STATUS: 'In Progress',
  },
  {
    ASSIGNED_BY: 'Elon Musk',
    TIME: '6 hours ago',
    APPLICATION_TYPE: 'Test',
    STATUS: 'Flagged',
  },
  {
    ASSIGNED_BY: 'Larry Page',
    TIME: '6 hours ago',
    APPLICATION_TYPE: 'Test',
    STATUS: 'Completed',
  },
  {
    ASSIGNED_BY: 'Jeff Bezos',
    TIME: '6 hours ago',
    APPLICATION_TYPE: 'Test',
    STATUS: 'Completed',
  },
  {
    ASSIGNED_BY: 'Mark Zuckerberg',
    TIME: '6 hours ago',
    APPLICATION_TYPE: 'Test',
    STATUS: 'In Progress',
  },
  {
    ASSIGNED_BY: 'Sundar Pichai',
    TIME: '6 hours ago',
    APPLICATION_TYPE: 'Test',
    STATUS: 'Completed',
  },
  {
    ASSIGNED_BY: 'Dave Beckham',
    TIME: '6 hours ago',
    APPLICATION_TYPE: 'Test',
    STATUS: 'Completed',
  },
  {
    ASSIGNED_BY: 'Satya Nadella',
    TIME: '6 hours ago',
    APPLICATION_TYPE: 'Test',
    STATUS: 'Rejected',
  },
  {
    ASSIGNED_BY: 'Sheryl Sandberg',
    TIME: '6 hours ago',
    APPLICATION_TYPE: 'Test',
    STATUS: 'In Progress',
  },
]

interface languageOptionsType {
  value: string
  name: string
}

export const LanguageOptions: languageOptionsType[] = [
  { value: 'arabic', name: 'Arabic, Egyptian Spoken' },
  { value: 'bengali', name: 'Bengali' },
  { value: 'bhojpuri', name: 'Bhojpuri' },
  { value: 'chinese-mandarin', name: 'Chinese, Mandarin' },
  { value: 'chinese-wu', name: 'Chinese, Wu' },
  { value: 'chinese-yue', name: 'Chinese, Yue' },
  { value: 'english', name: 'English' },
  { value: 'french', name: 'French' },
  { value: 'german', name: 'German' },
  { value: 'gujarati', name: 'Gujarati' },
  { value: 'housa', name: 'Hausa' },
  { value: 'hindi', name: 'Hindi (Excluding Urdu)' },
  { value: 'persian', name: 'Iranian Persian' },
  { value: 'italian', name: 'Italian' },
  { value: 'japanese', name: 'Japanese' },
  { value: 'javanese', name: 'Javanese' },
  { value: 'korean', name: 'Korean' },
  { value: 'malayalam', name: 'Malayalam' },
  { value: 'marathi', name: 'Marathi' },
  { value: 'polish', name: 'Polish' },
  { value: 'portuguese', name: 'Portuguese' },
  { value: 'russian', name: 'Russian' },
  { value: 'spanish', name: 'Spanish' },
  { value: 'tamil', name: 'Tamil' },
  { value: 'telugu', name: 'Telugu' },
  { value: 'turkish', name: 'Turkish' },
  { value: 'ukranian', name: 'Ukrainian' },
  { value: 'urdu', name: 'Urdu (Excl. Hindi)' },
  { value: 'vietnamese', name: 'Vietnamese' },
  { value: 'punjabi', name: 'Western Punjabi' },
  { value: 'other', name: 'Other' },
]

// This code snippet creates an array of notification objects with random dates and titles, using a specified length.
export const notificationsData = (length: number) =>
  Array.from({ length }, (_, _i) => {
    const randomDay = Math.floor(Math.random() * 28) + 1
    const randomMonth = Math.floor(Math.random() * 12)
    const randomYear = Math.floor(Math.random() * 10) + 2020
    const date = new Date(randomYear, randomMonth, randomDay)

    return {
      title: `${ApplicationsData[Math.floor(Math.random() * ApplicationsData.length)]?.ASSIGNED_BY} has submitted application`,
      subTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit,',
      date: date.toLocaleString('en-US', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      }),
    }
  })

export const CutString = (str: string, wordLimit: number) => {
  if (str.length > wordLimit) {
    return str.substring(0, wordLimit) + '...'
  } else {
    return str
  }
}

export function mapBusinessToJson(business: DynamicObj) {
  const json: Record<string, string> = {}
  for (const key in business) {
    json.isRemoved = business.removeProfile

    if (
      Object.prototype.hasOwnProperty.call(business, key) &&
      business[key] !== '' &&
      key.startsWith('bussiness')
    ) {
      if (key === 'bussinessProfileUrl') {
        json.file = business[key]
      } else {
        const jsonKey =
          key.replace('bussiness', '').charAt(0).toLowerCase() + key.slice(10)
        json[jsonKey] = business[key]
      }
    }
  }
  return json
}

export const tokenString = secureLocalStorage.getItem('token') as string

export function mapContactToJson(contact: DynamicObj) {
  const json: Record<string, string> = {}
  for (const key in contact) {
    if (
      Object.prototype.hasOwnProperty.call(contact, key) &&
      contact[key] !== '' &&
      key.startsWith('contact')
    ) {
      const jsonKey =
        key.replace('contact', '').charAt(0).toLowerCase() + key.slice(8)
      json[jsonKey] = contact[key]
    }
  }

  return json
}

const isEmptyValue = (value: any): boolean => {
  return (
    value === null ||
    value === undefined ||
    value === '' ||
    // value === false ||
    value === 0
  )
}

export const hasEmpty = (obj: any): boolean => {
  if (Array.isArray(obj)) {
    for (const item of obj) {
      if (!hasEmpty(item)) {
        return false
      }
    }
  } else if (typeof obj === 'object' && obj !== null) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (key.includes('Id')) {
          continue // Ignore keys containing "Id"
        }
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          if (!hasEmpty(obj[key])) {
            return false
          }
        } else if (!isEmptyValue(obj[key])) {
          return false
        }
      }
    }
  } else {
    // return isEmptyValue(obj)
    return false
  }
  return false

  // return true
}

export const useDivVisibility = (id: string) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const div = document.getElementById(id)

      if (div) {
        const rect = div.getBoundingClientRect()
        const windowHeight = window.innerHeight
        const scrollTop = window.scrollY || window.pageYOffset

        setIsVisible(rect.top >= 0 && rect.top <= windowHeight)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [id])

  return isVisible
}

export const extractDetailedApplicationBasicData = (
  basicdata: DynamicObj,
  assistancedata: DynamicObj,
  assigneeData: DynamicObj,
  flagdata: DynamicObj,
): DynamicObj | null => {
  // Check if response contains data

  if (basicdata) {
    const BasicDetails = {
      assistancedata,
      assigneeData,
      flagdata,
      ContactInformation: {
        firstName: basicdata?.contactInformation?.firstName ?? '',
        middleName: basicdata?.contactInformation?.middleName ?? '',
        lastName: basicdata?.contactInformation?.lastName ?? '',
        email: basicdata?.contactInformation?.emailId ?? '',
        isUSAPhoneNumber:
          basicdata?.contactInformation?.isUSAPhoneNumber ?? undefined,
        phone: basicdata?.contactInformation?.phoneNumber ?? '',
        physicalAddress: {
          streetAddress:
            basicdata?.contactInformation?.physicalAddress?.streetAddress ?? '',
          city: basicdata?.contactInformation?.physicalAddress?.city ?? '',
          state: basicdata?.contactInformation?.physicalAddress?.state ?? '',
          postalCode:
            basicdata?.contactInformation?.physicalAddress?.postalCode ?? '',
          country:
            basicdata?.contactInformation?.physicalAddress?.country ?? '',
        },
        isLiveAtPhysicalAddress:
          basicdata?.contactInformation?.isLiveAtPhysicalAddress ?? undefined,
        currentAddress: {
          streetAddress:
            basicdata?.contactInformation?.resideAddress?.streetAddress ?? '',
          city: basicdata?.contactInformation?.resideAddress?.city ?? '',
          state: basicdata?.contactInformation?.resideAddress?.state ?? '',
          postalCode:
            basicdata?.contactInformation?.resideAddress?.postalCode ?? '',
          country: basicdata?.contactInformation?.resideAddress?.country ?? '',
        },
        wantToReceiveMailAtPhysicalAddress:
          basicdata?.contactInformation?.wantToReceiveMailAtPhysicalAddress ??
          undefined,
        mailingAddress: {
          streetAddress:
            basicdata?.contactInformation?.mailAddress?.streetAddress ?? '',
          city: basicdata?.contactInformation?.mailAddress?.city ?? '',
          state: basicdata?.contactInformation?.mailAddress?.state ?? '',
          postalCode:
            basicdata?.contactInformation?.mailAddress?.postalCode ?? '',
          country: basicdata?.contactInformation?.mailAddress?.country ?? '',
        },
      },
      CommunicationPreference: {
        isPreferredLanguageEnglish:
          basicdata?.communication?.isPreferredLanguageEnglish ?? undefined,
        preferredReadingLanguage:
          basicdata?.communication?.preferredReadingLanguage ?? '',
        preferredSpeakingLanguage:
          basicdata?.communication?.preferredSpeakingLanguage ?? '',
        preferredWritingLanguage:
          basicdata?.communication?.preferredWritingLanguage ?? '',
        canReadEnglish: basicdata?.communication?.canReadEnglish ?? undefined,
        canWriteEnglish: basicdata?.communication?.canWriteEnglish ?? undefined,
        canSpeakEnglish: basicdata?.communication?.canSpeakEnglish ?? undefined,

        isAbleToReadWriteMoreThenName:
          basicdata?.communication?.isAbleToReadWriteMoreThenName ?? undefined,
      },
      Citizenship: {
        basicDetailId: basicdata?.basicId,
        wereBornInUSA: basicdata?.citizenship?.wereBornInUSA ?? undefined,
        wereBornIn: basicdata?.citizenship?.citizenshipCountryName?.[0] ?? '',

        citizenshipCountryName:
          basicdata?.citizenship?.citizenshipCountryName ?? [],
        isLegalResidentOfUSA:
          basicdata?.citizenship?.isLegalResidentOfUSA ?? undefined,
        residentCardNumber: basicdata?.citizenship?.residentCardNumber ?? '',
        isGoingThroughProcessOfUSACitizenship:
          basicdata?.citizenship?.isGoingThroughProcessOfUSACitizenship ??
          undefined,
        confirmUSACitizen: basicdata?.citizenship?.confirmUSACitizen ?? '',
        bornUSACityName: basicdata?.citizenship?.bornUSACityName ?? '',
        bornUSAStateName: basicdata?.citizenship?.bornUSAStateName ?? '',
        bornCountry: basicdata?.citizenship?.bornCountry ?? '',
        qualifiedUSACitizenship:
          basicdata?.citizenship?.qualifiedUSACitizenship ?? '',
        dateOfCitizenship: basicdata?.citizenship?.dateOfCitizenship ?? '',
      },
      MilitaryService: {
        typeOfBenefit: basicdata?.militaryService?.typeOfBenefit ?? '',
        isServedInMilitary:
          basicdata?.militaryService?.isServedInMilitary ?? undefined,
        serviceInformation: basicdata?.militaryService?.serviceInformation ?? [
          {
            typeOfDuty: '',
            branchOfService: '',
            startDate: '',
            endDate: '',
          },
        ],
        isReceivingFederalAgencyBenefit:
          basicdata?.militaryService?.isReceivingFederalAgencyBenefit ??
          undefined,
      },
      PassApplication: {
        haveAppliedForSSI:
          basicdata?.pastApplication?.haveAppliedForSSI ?? undefined,
        denialDateSSI: basicdata?.pastApplication?.denialDateSSI ?? '',
        denialDateSSDI: basicdata?.pastApplication?.denialDateSSDI ?? '',
        isSSDIDenial60Days:
          basicdata?.pastApplication?.isSSDIDenial60Days ?? undefined,
        isSSIDenial60Days:
          basicdata?.pastApplication?.isSSIDenial60Days ?? undefined,
        SSIApprovedEndDate:
          basicdata?.pastApplication?.SSIApprovedEndDate ?? '',
        statusOfSSI: basicdata?.pastApplication?.statusOfSSI ?? '',
        isReceivingSSI: basicdata?.pastApplication?.isReceivingSSI ?? undefined,
        isReceivingSSDI:
          basicdata?.pastApplication?.isReceivingSSDI ?? undefined,
        haveAppliedForSSDI:
          basicdata?.pastApplication?.haveAppliedForSSDI ?? undefined,
        haveAppliedWithSSN:
          basicdata?.pastApplication?.haveAppliedWithSSN ?? undefined,
        SSDIApprovedEndDate:
          basicdata?.pastApplication?.SSDIApprovedEndDate ?? '',
        statusOfPreviousSSDI:
          basicdata?.pastApplication?.statusOfPreviousSSDI ?? '',
        benefitAppliedSSNDetail: basicdata?.pastApplication
          ?.benefitAppliedSSNDetail ?? [
          {
            suffix: '',
            firstName: '',
            middleName: '',
            lastName: '',
            monthlyBenefitAmount: '',
            socialSecurityNumber: '',
          },
        ],
        participateProgramDetails: basicdata?.pastApplication
          ?.participateProgramDetails ?? [
          {
            organizationName: '',
            address: {
              streetAddress: '',
              city: '',
              state: '',
              postalCode: '',
              country: '',
            },
            contactName: '',
            phone: '',
            estimatedDate: '',
            description: '',
            stillParticipatedInProgram: undefined,
          },
        ],
        isReceivingMedicareBenefits:
          basicdata?.pastApplication?.isReceivingMedicareBenefits ?? undefined,
        appliedForAdditionalBenefits:
          basicdata?.pastApplication?.appliedForAdditionalBenefits ?? '',
        currentlyparticipatingPrograms:
          basicdata?.pastApplication?.currentlyparticipatingPrograms ?? '',
        haveAppliedForMedicareBenefits:
          basicdata?.pastApplication?.haveAppliedForMedicareBenefits ??
          undefined,
        statusOfPreviousMedicareBenefit:
          basicdata?.pastApplication?.statusOfPreviousMedicareBenefit ?? '',
      },
    }

    return BasicDetails
  }

  return null // Return null if data is not present in response
}

export const extractDetailedApplicationFamilyData = (
  familydata: DynamicObj,
  applicationId: string,
): DynamicObj | null => {
  // Check if response contains data
  if (familydata) {
    // Helper function to process dependents whether it's an array or object
    const processDependents = (dependents: any) => {
      if (Array.isArray(dependents)) {
        return dependents.map((dependent: any) => ({
          firstName: dependent?.firstName ?? '',
          lastName: dependent?.lastName ?? '',
          isYoungerThan17: dependent?.isYoungerThan17 ?? undefined,
          isChildMarried: dependent?.isChildMarried ?? undefined,
          isChildDisabled: dependent?.isChildDisabled ?? undefined,
          isChild1819SchoolUnmarried:
            dependent?.isChild1819SchoolUnmarried ?? undefined,
          isChildDisabledBeforeAge22:
            dependent?.isChildDisabledBeforeAge22 ?? undefined,
        }))
      } else if (dependents && typeof dependents === 'object') {
        return [
          {
            firstName: dependents?.firstName ?? '',
            lastName: dependents?.lastName ?? '',
            isYoungerThan17: dependents?.isYoungerThan17 ?? undefined,
            isChildMarried: dependents?.isChildMarried ?? undefined,
            isChildDisabled: dependents?.isChildDisabled ?? undefined,
            isChild1819SchoolUnmarried:
              dependents?.isChild1819SchoolUnmarried ?? undefined,
            isChildDisabledBeforeAge22:
              dependents?.isChildDisabledBeforeAge22 ?? undefined,
          },
        ]
      } else {
        return []
      }
    }

    const FamilyDetails = {
      CurrentSignificant: {
        havePriorMarriages: familydata?.havePriorMarriages ?? undefined,
        pastMarriage10Years: familydata?.pastMarriage10Years ?? undefined,
        isLegallyMarried: familydata?.isLegallyMarried ?? undefined,
        spouseFirstName:
          familydata?.currentSignificantOther?.spouseFirstName ?? '',
        spouseLastName:
          familydata?.currentSignificantOther?.spouseLastName ?? '',
        spouseDateOfBirth:
          familydata?.currentSignificantOther?.spouseDateOfBirth ?? '',
        spouseSSN: familydata?.currentSignificantOther?.spouseSSN ?? '',
        typeOfMarriage:
          familydata?.currentSignificantOther?.typeOfMarriage ?? '',
        dateOfMarriage:
          familydata?.currentSignificantOther?.dateOfMarriage ?? '',
        placeOfMarriage: {
          city:
            familydata?.currentSignificantOther?.placeOfMarriage?.city ?? '',
          state:
            familydata?.currentSignificantOther?.placeOfMarriage?.state ?? '',
          other:
            familydata?.currentSignificantOther?.placeOfMarriage?.other ?? '',
          country:
            familydata?.currentSignificantOther?.placeOfMarriage?.country ?? '',
        },
        applicationId,
      },
      Dependents: {
        familyId: familydata?.familyId,
        haveAnyChildern: familydata?.haveAnyChildern,
        numberOfChildern: familydata?.numberOfChildern ?? '',
        dependents: processDependents(
          familydata?.dependents ?? [
            {
              firstName: '',
              lastName: '',
              isYoungerThan17: undefined,
              isChildMarried: undefined,
              isChildDisabled: undefined,
              isChild1819SchoolUnmarried: undefined,
              isChildDisabledBeforeAge22: undefined,
            },
          ],
        ),
      } ?? {
        familyId: familydata?.familyId,
        haveAnyChildern: undefined,
        numberOfChildern: '',
        dependents: [
          {
            firstName: '',
            lastName: '',
            isYoungerThan17: undefined,
            isChildMarried: undefined,
            isChildDisabled: undefined,
            isChild1819SchoolUnmarried: undefined,
            isChildDisabledBeforeAge22: undefined,
          },
        ],
      },
      Parent: {
        familyId: familydata?.familyId,
        isParentReceiveOneHalfSupport:
          familydata?.isParentReceiveOneHalfSupport,
        firstParent: {
          suffix: familydata?.firstParent?.suffix ?? '',
          firstName: familydata?.firstParent?.firstName ?? '',
          lastName: familydata?.firstParent?.lastName ?? '',
          middleName: familydata?.firstParent?.middleName ?? '',
          address: {
            streetAddress:
              familydata?.firstParent?.address?.streetAddress ?? '',
            city: familydata?.firstParent?.address?.city ?? '',
            state: familydata?.firstParent?.address?.state ?? '',
            postalCode: familydata?.firstParent?.address?.postalCode ?? '',
            country: familydata?.firstParent?.address?.country ?? '',
          },
        },
        isSecondParentReceiveOneHalfSupport:
          familydata?.isSecondParentReceiveOneHalfSupport,
        secondParent: {
          suffix: familydata?.secondParent?.suffix ?? '',
          firstName: familydata?.secondParent?.firstName ?? '',
          lastName: familydata?.secondParent?.lastName ?? '',
          middleName: familydata?.secondParent?.middleName ?? '',
          address: {
            streetAddress:
              familydata?.secondParent?.address?.streetAddress ?? '',
            city: familydata?.secondParent?.address?.city ?? '',
            state: familydata?.secondParent?.address?.state ?? '',
            postalCode: familydata?.secondParent?.address?.postalCode ?? '',
            country: familydata?.secondParent?.address?.country ?? '',
          },
        },
      },
      PassSignificant: {
        familyId: familydata?.familyId,
        havePriorMarriages: familydata?.havePriorMarriages,
        pastMarriage10Years: familydata?.pastMarriage10Years,
        isPriorMarriageEndDuetoSpouseDeath:
          familydata?.isPriorMarriageEndDuetoSpouseDeath,
        pastSignificantOthers: familydata?.pastSignificantOthers?.map(
          (pastSO: any) => ({
            spouseFirstName: pastSO.spouseFirstName ?? '',
            spouseLastName: pastSO.spouseLastName ?? '',
            spouseDateOfBirth: pastSO.spouseDateOfBirth ?? '',
            spouseSSN: pastSO.spouseSSN ?? '',
            typeOfMarriage: pastSO.typeOfMarriage ?? '',
            dateOfMarriage: pastSO.dateOfMarriage ?? '',
            marriageEndDate: pastSO.marriageEndDate ?? '',
            howMarriageEnded: pastSO.howMarriageEnded ?? '',
            placeOfMarriage: {
              city: pastSO.placeOfMarriage?.city ?? '',
              state: pastSO.placeOfMarriage?.state ?? '',
              other: pastSO.placeOfMarriage?.other ?? '',
              country: pastSO.placeOfMarriage?.country ?? '',
            },
            placeOfMarriageEnded: {
              city: pastSO.placeOfMarriageEnded?.city ?? '',
              state: pastSO.placeOfMarriageEnded?.state ?? '',
              other: pastSO.placeOfMarriageEnded?.other ?? '',
              country: pastSO.placeOfMarriageEnded?.country ?? '',
            },
            pastSOEndInDeath: pastSO.pastSOEndInDeath ?? '',
            pastSODeathDate: pastSO.pastSODeathDate ?? '',
          }),
        ) || [
          {
            spouseFirstName: '',
            spouseLastName: '',
            spouseDateOfBirth: '',
            spouseSSN: '',
            typeOfMarriage: '',
            dateOfMarriage: '',
            marriageEndDate: '',
            howMarriageEnded: '',
            placeOfMarriage: {
              city: '',
              state: '',
              other: '',
              country: '',
            },
            placeOfMarriageEnded: {
              city: '',
              state: '',
              other: '',
              country: '',
            },
            pastSOEndInDeath: '',
            pastSODeathDate: '',
          },
        ],
      },
    }
    return FamilyDetails
  }

  return null // Return null if data is not present in response
}

function getRemarkByKey(flagdata: DynamicObj, key: string) {
  const entry = flagdata?.remark?.find(
    (item: { key: string }) => item.key === key,
  )
  if (entry) {
    if (entry.remark.length) {
      return entry.remark
    } else {
      return 'Remark not avilable!'
    }
  }
  return ''
}
export const extractFlagBasicData = (flagdata: any): any | null => {
  // Check if response contains data

  if (flagdata) {
    const flagBasicData = {
      Citizenship: {
        basicDetailId: '',
        wereBornInUSA: getRemarkByKey(
          flagdata,
          'BasicDetail.citizenship.wereBornInUSA',
        ),
        wereBornIn: '',
        confirmUSACitizen: getRemarkByKey(
          flagdata,
          'BasicDetail.citizenship.confirmUSACitizen',
        ),
        citizenshipCountryName: getRemarkByKey(
          flagdata,
          'BasicDetail.citizenship.citizenshipCountryName',
        ),
        isGoingThroughProcessOfUSACitizenship: getRemarkByKey(
          flagdata,
          'BasicDetail.citizenship.isGoingThroughProcessOfUSACitizenship',
        ),
        isLegalResidentOfUSA: getRemarkByKey(
          flagdata,
          'BasicDetail.citizenship.isLegalResidentOfUSA',
        ),
        residentCardNumber: getRemarkByKey(
          flagdata,
          'BasicDetail.citizenship.residentCardNumber',
        ),
      },
      ContactInformation: {
        firstName: getRemarkByKey(
          flagdata,
          'BasicDetail.contactInformation.firstName',
        ),
        middleName: getRemarkByKey(
          flagdata,
          'BasicDetail.contactInformation.middleName',
        ),
        lastName: getRemarkByKey(
          flagdata,
          'BasicDetail.contactInformation.lastName',
        ),
        email: getRemarkByKey(
          flagdata,
          'BasicDetail.contactInformation.emailId',
        ),
        isUSAPhoneNumber: getRemarkByKey(
          flagdata,
          'BasicDetail.contactInformation.isUSAPhoneNumber',
        ),
        phone: getRemarkByKey(
          flagdata,
          'BasicDetail.contactInformation.phoneNumber',
        ),
        physicalAddress: {
          streetAddress: getRemarkByKey(
            flagdata,
            'BasicDetail.contactInformation.physicalAddress.streetAddress',
          ),
          city: getRemarkByKey(
            flagdata,
            'BasicDetail.contactInformation.physicalAddress.city',
          ),
          state: getRemarkByKey(
            flagdata,
            'BasicDetail.contactInformation.physicalAddress.state',
          ),
          postalCode: getRemarkByKey(
            flagdata,
            'BasicDetail.contactInformation.physicalAddress.postalCode',
          ),
          country: getRemarkByKey(
            flagdata,
            'BasicDetail.contactInformation.physicalAddress.country',
          ),
        },
        isLiveAtPhysicalAddress: getRemarkByKey(
          flagdata,
          'BasicDetail.contactInformation.isLiveAtPhysicalAddress',
        ),
        currentAddress: {
          streetAddress: getRemarkByKey(
            flagdata,
            'BasicDetail.contactInformation.resideAddress.streetAddress',
          ),
          city: getRemarkByKey(
            flagdata,
            'BasicDetail.contactInformation.resideAddress.city',
          ),
          state: getRemarkByKey(
            flagdata,
            'BasicDetail.contactInformation.resideAddress.state',
          ),
          postalCode: getRemarkByKey(
            flagdata,
            'BasicDetail.contactInformation.resideAddress.postalCode',
          ),
          country: getRemarkByKey(
            flagdata,
            'BasicDetail.contactInformation.resideAddress.country',
          ),
        },
        wantToReceiveMailAtPhysicalAddress: getRemarkByKey(
          flagdata,
          'BasicDetail.contactInformation.wantToReceiveMailAtPhysicalAddress',
        ),
        mailingAddress: {
          streetAddress: getRemarkByKey(
            flagdata,
            'BasicDetail.contactInformation.mailAddress.streetAddress',
          ),
          city: getRemarkByKey(
            flagdata,
            'BasicDetail.contactInformation.mailAddress.city',
          ),
          state: getRemarkByKey(
            flagdata,
            'BasicDetail.contactInformation.mailAddress.state',
          ),
          postalCode: getRemarkByKey(
            flagdata,
            'BasicDetail.contactInformation.mailAddress.postalCode',
          ),
          country: getRemarkByKey(
            flagdata,
            'BasicDetail.contactInformation.mailAddress.country',
          ),
        },
      },
    }

    return flagBasicData
  }

  return null // Return null if data is not present in response
}

export const scrollToSection = (sectionId: string) => {
  const element = document.getElementById(sectionId)
  if (element) {
    element.scrollIntoView()
  }
}
export const extractFlagEducationData = (
  educationdetails: any,
  flagdata: any,
): any | null => {
  // Check if response contains data

  if (flagdata) {
    const flagEducationData = {
      SelfEmployement: {
        isSelfEmployed: getRemarkByKey(
          flagdata,
          'EducationAndEmploymentDetail.selfEmployment.isSelfEmployed',
        ),
        isSelfEmployedDuringLastYear: getRemarkByKey(
          flagdata,
          'EducationAndEmploymentDetail.selfEmployment.isSelfEmployedDuringLastYear',
        ),
        typeOfLastYearBusiness: getRemarkByKey(
          flagdata,
          'EducationAndEmploymentDetail.selfEmployment.typeOfLastYearBusiness',
        ),
        profitMoreAmountLastYear: getRemarkByKey(
          flagdata,
          'EducationAndEmploymentDetail.selfEmployment.profitMoreAmountLastYear',
        ),
        totalEarnedAmountLastYear: getRemarkByKey(
          flagdata,
          'EducationAndEmploymentDetail.selfEmployment.totalEarnedAmountLastYear',
        ),
        isSelfEmployedDuringCurrentYear: getRemarkByKey(
          flagdata,
          'EducationAndEmploymentDetail.selfEmployment.isSelfEmployedDuringCurrentYear',
        ),
        typeOfCurrentYearBusiness: getRemarkByKey(
          flagdata,
          'EducationAndEmploymentDetail.selfEmployment.typeOfCurrentYearBusiness',
        ),
        profitMoreAmountCurrentYear: getRemarkByKey(
          flagdata,
          'EducationAndEmploymentDetail.selfEmployment.profitMoreAmountCurrentYear',
        ),
        totalEarnedAmountCurrentYear: getRemarkByKey(
          flagdata,
          'EducationAndEmploymentDetail.selfEmployment.totalEarnedAmountCurrentYear',
        ),
        isSelfEmployedNextYear: getRemarkByKey(
          flagdata,
          'EducationAndEmploymentDetail.selfEmployment.isSelfEmployedNextYear',
        ),
        typeOfNextYearBusiness: '', // No matching key in  flagdata
        profitMoreAmountNextYear: undefined, // No matching key in  flagdata
      },
      Education: {
        highestGrade: getRemarkByKey(
          flagdata,
          'EducationAndEmploymentDetail.education.highestGrade',
        ),
        schoolName: getRemarkByKey(
          flagdata,
          'EducationAndEmploymentDetail.education.schoolName',
        ),
        city: getRemarkByKey(
          flagdata,
          'EducationAndEmploymentDetail.education.city',
        ),
        state: getRemarkByKey(
          flagdata,
          'EducationAndEmploymentDetail.education.state',
        ),
        country: getRemarkByKey(
          flagdata,
          'EducationAndEmploymentDetail.education.country',
        ),
        dateEducationCompleted: getRemarkByKey(
          flagdata,
          'EducationAndEmploymentDetail.education.dateEducationCompleted',
        ),
      },
      EmployerDetails: {
        employerDetail: Array.isArray(educationdetails?.employerDetail)
          ? educationdetails.employerDetail.map(
              (_employer: any, index: any) => ({
                companyName: getRemarkByKey(
                  flagdata,
                  `EducationAndEmploymentDetail.employerDetail[${index}].companyName`,
                ),
                typeOfBusiness: getRemarkByKey(
                  flagdata,
                  `EducationAndEmploymentDetail.employerDetail[${index}].typeOfBusiness`,
                ),
                address: {
                  streetAddress: getRemarkByKey(
                    flagdata,
                    `EducationAndEmploymentDetail.employerDetail[${index}].address.streetAddress`,
                  ),
                  city: getRemarkByKey(
                    flagdata,
                    `EducationAndEmploymentDetail.employerDetail[${index}].address.city`,
                  ),
                  state: getRemarkByKey(
                    flagdata,
                    `EducationAndEmploymentDetail.employerDetail[${index}].address.state`,
                  ),
                  postalCode: getRemarkByKey(
                    flagdata,
                    `EducationAndEmploymentDetail.employerDetail[${index}].address.postalCode`,
                  ),
                  country: getRemarkByKey(
                    flagdata,
                    `EducationAndEmploymentDetail.employerDetail[${index}].address.country`,
                  ),
                },
                startDate: getRemarkByKey(
                  flagdata,
                  `EducationAndEmploymentDetail.employerDetail[${index}].startDate`,
                ),
                endDate: getRemarkByKey(
                  flagdata,
                  `EducationAndEmploymentDetail.employerDetail[${index}].endDate`,
                ),
                isphysicallyWorkedLastDate: getRemarkByKey(
                  flagdata,
                  `EducationAndEmploymentDetail.employerDetail[${index}].isphysicallyWorkedLastDate`,
                ),
                lastPhysicallyWorkedDate: getRemarkByKey(
                  flagdata,
                  `EducationAndEmploymentDetail.employerDetail[${index}].lastPhysicallyWorkedDate`,
                ),
                jobTitle: getRemarkByKey(
                  flagdata,
                  `EducationAndEmploymentDetail.employerDetail[${index}].jobTitle`,
                ),
                averageHourPerDay: getRemarkByKey(
                  flagdata,
                  `EducationAndEmploymentDetail.employerDetail[${index}].averageHourPerDay`,
                ),
                averageDaysPerWeek: getRemarkByKey(
                  flagdata,
                  `EducationAndEmploymentDetail.employerDetail[${index}].averageDaysPerWeek`,
                ),
                payAmountPerPayPeriod: getRemarkByKey(
                  flagdata,
                  `EducationAndEmploymentDetail.employerDetail[${index}].payAmountPerPayPeriod`,
                ),
                payFrequency: getRemarkByKey(
                  flagdata,
                  `EducationAndEmploymentDetail.employerDetail[${index}].payFrequency`,
                ),
              }),
            )
          : [
              {
                address: {
                  city: getRemarkByKey(
                    flagdata,
                    'EducationAndEmploymentDetail.employerDetail[0].address.city',
                  ),
                  state: getRemarkByKey(
                    flagdata,
                    'EducationAndEmploymentDetail.employerDetail[0].address.state',
                  ),
                  country: getRemarkByKey(
                    flagdata,
                    'EducationAndEmploymentDetail.employerDetail[0].address.country',
                  ),
                  postalCode: getRemarkByKey(
                    flagdata,
                    'EducationAndEmploymentDetail.employerDetail[0].address.postalCode',
                  ),
                  streetAddress: getRemarkByKey(
                    flagdata,
                    'EducationAndEmploymentDetail.employerDetail[0].address.streetAddress',
                  ),
                },
                averageDaysPerWeek: getRemarkByKey(
                  flagdata,
                  'EducationAndEmploymentDetail.employerDetail[0].averageDaysPerWeek',
                ),
                averageHourPerDay: getRemarkByKey(
                  flagdata,
                  'EducationAndEmploymentDetail.employerDetail[0].averageHourPerDay',
                ),
                companyName: getRemarkByKey(
                  flagdata,
                  'EducationAndEmploymentDetail.employerDetail[0].companyName',
                ),
                jobTitle: getRemarkByKey(
                  flagdata,
                  'EducationAndEmploymentDetail.employerDetail[0].jobTitle',
                ),
                lastPhysicallyWorkedDate: getRemarkByKey(
                  flagdata,
                  'EducationAndEmploymentDetail.employerDetail[0].lastPhysicallyWorkedDate',
                ),
                payAmountPerPayPeriod: getRemarkByKey(
                  flagdata,
                  'EducationAndEmploymentDetail.employerDetail[0].payAmountPerPayPeriod',
                ),
                payFrequency: getRemarkByKey(
                  flagdata,
                  'EducationAndEmploymentDetail.employerDetail[0].payFrequency',
                ),
                startDate: getRemarkByKey(
                  flagdata,
                  'EducationAndEmploymentDetail.employerDetail[0].startDate',
                ),
                endDate: getRemarkByKey(
                  flagdata,
                  'EducationAndEmploymentDetail.employerDetail[0].endDate',
                ),
                isphysicallyWorkedLastDate: getRemarkByKey(
                  flagdata,
                  'EducationAndEmploymentDetail.employerDetail[0].isphysicallyWorkedLastDate',
                ),
                typeOfBusiness: getRemarkByKey(
                  flagdata,
                  'EducationAndEmploymentDetail.employerDetail[0].typeOfBusiness',
                ),
              },
            ],
      },
    }

    return flagEducationData
  }

  return null // Return null if data is not present in response
}

export const extractFlagMedicalData = (flagData: any): any | null => {
  // Check if response contains data

  if (flagData) {
    const flagMedicalData = {
      GeneralHealth: {
        dateOfBirth: getRemarkByKey(
          flagData,
          'MedicalDetail.generalHealth.dateOfBirth',
        ),
        gender: getRemarkByKey(flagData, 'MedicalDetail.generalHealth.gender'),
        feet: getRemarkByKey(
          flagData,
          'MedicalDetail.generalHealth.physique.feet',
        ),
        inch: getRemarkByKey(
          flagData,
          'MedicalDetail.generalHealth.physique.inch',
        ), // No flag data provided for inches, so it remains unchanged
        weight: getRemarkByKey(
          flagData,
          'MedicalDetail.generalHealth.physique.weight',
        ),
      },
    }

    return flagMedicalData
  }

  return null // Return null if data is not present in response
}

export const extractFlagFamilyData = (
  flagData: any,
  familyDetails: DynamicObj,
): any | null => {
  // Check if response contains data

  if (flagData) {
    const flagFamilyData = {
      CurrentSignificant: {
        havePriorMarriages: '',
        pastMarriage10Years: '',
        spouseFirstName: getRemarkByKey(
          flagData,
          'FamilyDetail.currentSignificantOther.spouseFirstName',
        ),
        isLegallyMarried: '',
        spouseLastName: getRemarkByKey(
          flagData,
          'FamilyDetail.currentSignificantOther.spouseLastName',
        ),
        spouseDateOfBirth: getRemarkByKey(
          flagData,
          'FamilyDetail.currentSignificantOther.spouseDateOfBirth',
        ),
        spouseSSN: getRemarkByKey(
          flagData,
          'FamilyDetail.currentSignificantOther.spouseSSN',
        ),
        typeOfMarriage: getRemarkByKey(
          flagData,
          'FamilyDetail.currentSignificantOther.typeOfMarriage',
        ),
        dateOfMarriage: getRemarkByKey(
          flagData,
          'FamilyDetail.currentSignificantOther.dateOfMarriage',
        ),
        placeOfMarriage: {
          city: getRemarkByKey(
            flagData,
            'FamilyDetail.currentSignificantOther.placeOfMarriage.city',
          ),
          state: getRemarkByKey(
            flagData,
            'FamilyDetail.currentSignificantOther.placeOfMarriage.state',
          ),
          other: getRemarkByKey(
            flagData,
            'FamilyDetail.currentSignificantOther.placeOfMarriage.other',
          ),
          country: getRemarkByKey(
            flagData,
            'FamilyDetail.currentSignificantOther.placeOfMarriage.country',
          ),
        },
      },
      PassSignificant: {
        familyId: '',
        havePriorMarriages: '',
        pastMarriage10Years: '',
        isPriorMarriageEndDuetoSpouseDeath: '',
        pastSignificantOthers: Array.isArray(
          familyDetails?.PassSignificant?.pastSignificantOthers,
        )
          ? familyDetails.PassSignificant?.pastSignificantOthers.map(
              (_pastSignificantOther: any, index: any) => ({
                spouseFirstName: getRemarkByKey(
                  flagData,
                  `FamilyDetail.pastSignificantOthers[${index}].spouseFirstName`,
                ),
                spouseLastName: getRemarkByKey(
                  flagData,
                  `FamilyDetail.pastSignificantOthers[${index}].spouseLastName`,
                ),
                spouseDateOfBirth: getRemarkByKey(
                  flagData,
                  `FamilyDetail.pastSignificantOthers[${index}].spouseDateOfBirth`,
                ),
                spouseSSN: getRemarkByKey(
                  flagData,
                  `FamilyDetail.pastSignificantOthers[${index}].spouseSSN`,
                ),
                typeOfMarriage: getRemarkByKey(
                  flagData,
                  `FamilyDetail.pastSignificantOthers[${index}].typeOfMarriage`,
                ),
                dateOfMarriage: getRemarkByKey(
                  flagData,
                  `FamilyDetail.pastSignificantOthers[${index}].dateOfMarriage`,
                ),
                marriageEndDate: getRemarkByKey(
                  flagData,
                  `FamilyDetail.pastSignificantOthers[${index}].marriageEndDate`,
                ),
                howMarriageEnded: getRemarkByKey(
                  flagData,
                  `FamilyDetail.pastSignificantOthers[${index}].howMarriageEnded`,
                ),
                pastSOEndInDeath: getRemarkByKey(
                  flagData,
                  `FamilyDetail.pastSignificantOthers[${index}].pastSOEndInDeath`,
                ),
                pastSODeathDate: getRemarkByKey(
                  flagData,
                  `FamilyDetail.pastSignificantOthers[${index}].pastSODeathDate`,
                ),
                placeOfMarriage: {
                  city: getRemarkByKey(
                    flagData,
                    `FamilyDetail.pastSignificantOthers[${index}].placeOfMarriage.city`,
                  ),
                  state: getRemarkByKey(
                    flagData,
                    `FamilyDetail.pastSignificantOthers[${index}].placeOfMarriage.state`,
                  ),
                  other: getRemarkByKey(
                    flagData,
                    `FamilyDetail.pastSignificantOthers[${index}].placeOfMarriage.other`,
                  ),
                  country: getRemarkByKey(
                    flagData,
                    `FamilyDetail.pastSignificantOthers[${index}].placeOfMarriage.country`,
                  ),
                },
                placeOfMarriageEnded: {
                  city: getRemarkByKey(
                    flagData,
                    `FamilyDetail.pastSignificantOthers[${index}].placeOfMarriageEnded.city`,
                  ),
                  state: getRemarkByKey(
                    flagData,
                    `FamilyDetail.pastSignificantOthers[${index}].placeOfMarriageEnded.state`,
                  ),
                  other: getRemarkByKey(
                    flagData,
                    `FamilyDetail.pastSignificantOthers[${index}].placeOfMarriageEnded.other`,
                  ),
                  country: getRemarkByKey(
                    flagData,
                    `FamilyDetail.pastSignificantOthers[${index}].placeOfMarriageEnded.country`,
                  ),
                },
              }),
            )
          : [
              {
                spouseFirstName: getRemarkByKey(
                  flagData,
                  'FamilyDetail.pastSignificantOthers[0].spouseFirstName',
                ),
                spouseLastName: getRemarkByKey(
                  flagData,
                  'FamilyDetail.pastSignificantOthers[0].spouseLastName',
                ),
                spouseDateOfBirth: getRemarkByKey(
                  flagData,
                  'FamilyDetail.pastSignificantOthers[0].spouseDateOfBirth',
                ),
                spouseSSN: getRemarkByKey(
                  flagData,
                  'FamilyDetail.pastSignificantOthers[0].spouseSSN',
                ),
                typeOfMarriage: getRemarkByKey(
                  flagData,
                  'FamilyDetail.pastSignificantOthers[0].typeOfMarriage',
                ),
                dateOfMarriage: getRemarkByKey(
                  flagData,
                  'FamilyDetail.pastSignificantOthers[0].dateOfMarriage',
                ),
                marriageEndDate: getRemarkByKey(
                  flagData,
                  'FamilyDetail.pastSignificantOthers[0].marriageEndDate',
                ),
                howMarriageEnded: getRemarkByKey(
                  flagData,
                  'FamilyDetail.pastSignificantOthers[0].howMarriageEnded',
                ),
                pastSOEndInDeath: getRemarkByKey(
                  flagData,
                  'FamilyDetail.pastSignificantOthers[0].pastSOEndInDeath',
                ),
                pastSODeathDate: getRemarkByKey(
                  flagData,
                  'FamilyDetail.pastSignificantOthers[0].pastSODeathDate',
                ),
                placeOfMarriage: {
                  city: getRemarkByKey(
                    flagData,
                    'FamilyDetail.pastSignificantOthers[0].placeOfMarriage.city',
                  ),
                  state: getRemarkByKey(
                    flagData,
                    'FamilyDetail.pastSignificantOthers[0].placeOfMarriage.state',
                  ),
                  other: getRemarkByKey(
                    flagData,
                    'FamilyDetail.pastSignificantOthers[0].placeOfMarriage.other',
                  ),
                  country: getRemarkByKey(
                    flagData,
                    'FamilyDetail.pastSignificantOthers[0].placeOfMarriage.country',
                  ),
                },
                placeOfMarriageEnded: {
                  city: getRemarkByKey(
                    flagData,
                    'FamilyDetail.pastSignificantOthers[0].placeOfMarriageEnded.city',
                  ),
                  state: getRemarkByKey(
                    flagData,
                    'FamilyDetail.pastSignificantOthers[0].placeOfMarriageEnded.state',
                  ),
                  other: getRemarkByKey(
                    flagData,
                    'FamilyDetail.pastSignificantOthers[0].placeOfMarriageEnded.other',
                  ),
                  country: getRemarkByKey(
                    flagData,
                    'FamilyDetail.pastSignificantOthers[0].placeOfMarriageEnded.country',
                  ),
                },
              },
            ],

        //    pastSignificantOthers: Array.isArray(familyDetails?.PassSignificant?.pastSignificantOthers)
        // ? familyDetails.PassSignificant?.pastSignificantOthers.map(
        //     (_pastSignificantOther: any, index: any) => ({

        //             country: getRemarkByKey(flagData, "FamilyDetail.pastSignificantOthers[1].placeOfMarriageEnded.country"),

        // }),
        // ),
      },
    }

    return flagFamilyData
  }

  return null // Return null if data is not present in response
}

export const extractDetailedApplicationLegalData = (
  legalData: DynamicObj,
  applicationId: string,
): DynamicObj | null => {
  // Check if response contains data

  if (legalData) {
    const LegalDetails = {
      LegalName: {
        applicationId,
        haveUsedOtherName: legalData?.haveUsedOtherName ?? undefined,
        legalNames: Array.isArray(legalData?.legalNames)
          ? legalData.legalNames.map((name: any) => ({
              firstName: name.firstName ?? '',
              middleName: name.middleName ?? '',
              lastName: name.lastName ?? '',
            }))
          : [
              {
                firstName: '',
                middleName: '',
                lastName: '',
              },
            ],
      },
      IdentificationNumbers: {
        legalId: legalData?.legalId,
        socialSecurityNumber:
          legalData?.identificationNumber?.socialSecurityNumber ?? '',
        otherSocialSecurityNumbers: legalData?.identificationNumber
          ?.otherSocialSecurityNumbers ?? [''],
        isUsingOtherSSN:
          legalData?.identificationNumber?.isUsingOtherSSN ?? undefined,
      },
      ReceivingDisability: {
        legalId: legalData?.legalId,
        isHavingBankAccount: legalData?.isHavingBankAccount ?? undefined,
        accountType:
          legalData?.receivingDisabilityBenefitPayment?.accountType ?? '',
        routingNumber:
          legalData?.receivingDisabilityBenefitPayment?.routingNumber ?? '',
        accountNumber:
          legalData?.receivingDisabilityBenefitPayment?.accountNumber ?? '',
      },
    }
    return LegalDetails
  }

  return null // Return null if data is not present in response
}

export const extractDetailedApplicationDisabledData = (
  disablitydata: DynamicObj,
  applicationId: string,
): DynamicObj | null => {
  // Check if response contains data

  if (disablitydata) {
    const DisabilityDetails = {
      DisabilityBackground: {
        applicationId,
        isAbleToWork:
          disablitydata?.disabilityBackground?.isAbleToWork ?? undefined,
        is14MonthsUnableToDoJob:
          disablitydata?.disabilityBackground?.is14MonthsUnableToDoJob ??
          undefined,
        disabilityRelatedToWork:
          disablitydata?.disabilityBackground?.disabilityRelatedToWork ??
          undefined,
        disabilityStartDate:
          disablitydata?.disabilityBackground?.disabilityStartDate ?? '',
        isAbleToPerformUsualJob:
          disablitydata?.disabilityBackground?.isAbleToPerformUsualJob ??
          undefined,
        isDisabilityExpected1YearOrLonger:
          disablitydata?.disabilityBackground
            ?.isDisabilityExpected1YearOrLonger ?? undefined,
        disabilityEndInDeath:
          disablitydata?.disabilityBackground?.disabilityEndInDeath ??
          undefined,
      },
      DisabilityEmployment: {
        disabilityId: disablitydata?.disabilityId ?? '',
        intendToWorkNextYear:
          disablitydata?.disabilityEmployment?.intendToWorkNextYear ??
          undefined,
        affectingEmploymentDetail:
          disablitydata?.disabilityEmployment?.affectingEmploymentDetail ?? '',
        employedDuringLast2YearDetail:
          disablitydata?.disabilityEmployment?.employedDuringLast2YearDetail ??
          [],
        reasonToStoppedWorking:
          disablitydata?.disabilityEmployment?.reasonToStoppedWorking ?? '',
        explainStoppedWorking:
          disablitydata?.disabilityEmployment?.explainStoppedWorking ?? '',
        dateOfStoppedWorking:
          disablitydata?.disabilityEmployment?.dateOfStoppedWorking ?? '',
        isRequireWorkChanges:
          disablitydata?.disabilityEmployment?.isRequireWorkChanges ??
          undefined,
        haveMadeMoreMoneyInAnyMonth:
          disablitydata?.disabilityEmployment?.haveMadeMoreMoneyInAnyMonth ??
          undefined,
        workChangeStartDate:
          disablitydata?.disabilityEmployment?.workChangeStartDate ?? '',
        disabilityKeepFromWorkingDate:
          disablitydata?.disabilityEmployment?.disabilityKeepFromWorkingDate ??
          '',
      },
      PayoutsSettlements: {
        disabilityId: disablitydata?.disabilityId ?? '',
        haveReceiveMoneyAfterDisability:
          disablitydata?.payoutsAndSettlements
            ?.haveReceiveMoneyAfterDisability ?? undefined,
        typeOfPays: disablitydata?.payoutsAndSettlements?.typeOfPays ?? [],
        otherPayExplain:
          disablitydata?.payoutsAndSettlements?.otherPayExplain ?? '',
        isExpectMoneyInFuture:
          disablitydata?.payoutsAndSettlements?.isExpectMoneyInFuture ??
          undefined,
        approxAmountExpected:
          disablitydata?.payoutsAndSettlements?.approxAmountExpected ?? 0,
        typeOfFuturePay:
          disablitydata?.payoutsAndSettlements?.typeOfFuturePay ?? [],
        futureOtherPayExplain:
          disablitydata?.payoutsAndSettlements?.futureOtherPayExplain ?? '',
        approxAmountFuturePayout:
          disablitydata?.payoutsAndSettlements?.approxAmountFuturePayout ?? 0,
      },
    }
    return DisabilityDetails
  }

  return null // Return null if data is not present in response
}

export const extractDetailedApplicationEducationData = (
  educationdetails: DynamicObj,
): DynamicObj | null => {
  // Check if response contains data

  if (educationdetails) {
    const EducationAndEmployement: DynamicObj = {
      Education: {
        highestGrade: educationdetails?.education?.highestGrade ?? '',
        schoolName: educationdetails?.education?.schoolName ?? '',
        city: educationdetails?.education?.city ?? '',
        state: educationdetails?.education?.state ?? '',
        country: educationdetails?.education?.country ?? '',
        dateEducationCompleted:
          educationdetails?.education?.dateEducationCompleted ?? '',
      },

      haveReceivedSpecialEducation:
        educationdetails?.haveReceivedSpecialEducation,
      haveSpecializedTraining: educationdetails?.haveReceivedSpecialEducation,
      SpecialEducation: {
        startDate: educationdetails?.specialEducation?.startDate ?? '',
        endDate: educationdetails?.specialEducation?.endDate ?? '',
        lastGrade: educationdetails?.specialEducation?.lastGrade ?? '',
        reasonForSpecialEducation:
          educationdetails?.specialEducation?.reasonForSpecialEducation ?? '',
        schoolName: educationdetails?.specialEducation?.schoolName ?? '',
        city: educationdetails?.specialEducation?.city ?? '',
        state: educationdetails?.specialEducation?.state ?? '',
        country: educationdetails?.specialEducation?.country ?? '',
      },
      TradeAndTraining: {
        haveSpecializedTraining:
          educationdetails?.haveSpecializedTraining ?? undefined,
        typeOfSpecializedTraining:
          educationdetails?.specializedTradeEducationAndTraining
            ?.typeOfSpecializedTraining ?? '',
        startDate:
          educationdetails?.specializedTradeEducationAndTraining?.startDate ??
          '',
        endDate:
          educationdetails?.specializedTradeEducationAndTraining?.endDate ?? '',
        schoolName:
          educationdetails?.specializedTradeEducationAndTraining?.schoolName ??
          '',
        city:
          educationdetails?.specializedTradeEducationAndTraining?.city ?? '',
        state:
          educationdetails?.specializedTradeEducationAndTraining?.state ?? '',
        country:
          educationdetails?.specializedTradeEducationAndTraining?.country ?? '',
      },
      EmployerDetails: {
        employerDetail: Array.isArray(educationdetails?.employerDetail)
          ? educationdetails.employerDetail.map(
              (employer: {
                companyName: any
                typeOfBusiness: any
                address: {
                  streetAddress: any
                  city: any
                  state: any
                  postalCode: any
                  country: any
                }
                startDate: any
                endDate: any
                isphysicallyWorkedLastDate: any
                lastPhysicallyWorkedDate: any
                jobTitle: any
                averageHourPerDay: any
                averageDaysPerWeek: any
                payAmountPerPayPeriod: any
                payFrequency: any
              }) => ({
                companyName: employer?.companyName ?? '',
                typeOfBusiness: employer?.typeOfBusiness ?? '',
                address: {
                  streetAddress: employer?.address?.streetAddress ?? '',
                  city: employer?.address?.city ?? '',
                  state: employer?.address?.state ?? '',
                  postalCode: employer?.address?.postalCode ?? '',
                  country: employer?.address?.country ?? '',
                },
                startDate: employer?.startDate ?? '',
                endDate: employer?.endDate ?? '',
                isphysicallyWorkedLastDate:
                  employer?.isphysicallyWorkedLastDate ?? undefined,
                lastPhysicallyWorkedDate:
                  employer?.lastPhysicallyWorkedDate ?? '',
                jobTitle: employer?.jobTitle ?? '',
                averageHourPerDay: employer?.averageHourPerDay ?? 0,
                averageDaysPerWeek: employer?.averageDaysPerWeek ?? 0,
                payAmountPerPayPeriod: employer?.payAmountPerPayPeriod ?? 0,
                payFrequency: employer?.payFrequency ?? '',
              }),
            )
          : [
              {
                address: {
                  city: '',
                  state: '',
                  country: '',
                  postalCode: '',
                  streetAddress: '',
                },
                averageDaysPerWeek: 0,
                averageHourPerDay: 0,
                companyName: '',
                jobTitle: '',
                lastPhysicallyWorkedDate: '',
                payAmountPerPayPeriod: 0,
                payFrequency: '',
                startDate: '',
                endDate: '',
                isphysicallyWorkedLastDate: undefined,
                typeOfBusiness: '',
              },
            ],
      },
      JobDetails: {
        oneOrMoreJobsIn15Years:
          educationdetails?.jobDetail?.oneOrMoreJobsIn15Years ?? '',
        jobDescriptionOf15Years:
          educationdetails?.jobDetail?.jobDescriptionOf15Years ?? '',
        applicableJobs: educationdetails?.jobDetail?.applicableJobs ?? [],
        hoursToPerform: {
          walk: educationdetails?.jobDetail?.hoursToPerform?.walk ?? 0,
          stand: educationdetails?.jobDetail?.hoursToPerform?.stand ?? 0,
          sit: educationdetails?.jobDetail?.hoursToPerform?.sit ?? 0,
          climb: educationdetails?.jobDetail?.hoursToPerform?.climb ?? 0,
          bend: educationdetails?.jobDetail?.hoursToPerform?.bend ?? 0,
          kneel: educationdetails?.jobDetail?.hoursToPerform?.kneel ?? 0,
          crounch: educationdetails?.jobDetail?.hoursToPerform?.crounch ?? 0,
          crawl: educationdetails?.jobDetail?.hoursToPerform?.crawl ?? 0,
          writeOrType:
            educationdetails?.jobDetail?.hoursToPerform?.writeOrType ?? 0,
          handleLargeObject:
            educationdetails?.jobDetail?.hoursToPerform?.handleLargeObject ?? 0,
          reachToThings:
            educationdetails?.jobDetail?.hoursToPerform?.reachToThings ?? 0,
          handleSmallObjects:
            educationdetails?.jobDetail?.hoursToPerform?.handleSmallObjects ??
            0,
        },
        isLifitingInJob:
          educationdetails?.jobDetail?.isLifitingInJob ?? undefined,
        howFarCarryItems: educationdetails?.jobDetail?.howFarCarryItems ?? '',
        heaviestWeightLifted:
          educationdetails?.jobDetail?.heaviestWeightLifted ?? '',
        howmuchWeightLiftedPerDay:
          educationdetails?.jobDetail?.howmuchWeightLiftedPerDay ?? '',
        didSuperviseOther:
          educationdetails?.jobDetail?.didSuperviseOther ?? undefined,
        percentageOfTimeSpentSupervising:
          educationdetails?.jobDetail?.percentageOfTimeSpentSupervising ?? '',
        howManyPeopleSupervise:
          educationdetails?.jobDetail?.howManyPeopleSupervise ?? 0,
        didHireOrFirePeople:
          educationdetails?.jobDetail?.didHireOrFirePeople ?? undefined,
        didLeadOrMentorOthers:
          educationdetails?.jobDetail?.didLeadOrMentorOthers ?? undefined,
        whatLiftedAndHowOften:
          educationdetails?.jobDetail?.whatLiftedAndHowOften ?? '',
      },
      ChildCare: {
        fulltimeChildcareUnder3Years:
          educationdetails?.childcare?.fulltimeChildcareUnder3Years ??
          undefined,
        fulltimeChildcareStartYear:
          educationdetails?.childcare?.fulltimeChildcareStartYear ?? '',
        fulltimeChildcareEndYear:
          educationdetails?.childcare?.fulltimeChildcareEndYear ?? '',
      },
      SelfEmployement: {
        isSelfEmployed:
          educationdetails?.selfEmployment?.isSelfEmployed ?? undefined,
        isSelfEmployedDuringLastYear:
          educationdetails?.selfEmployment?.isSelfEmployedDuringLastYear ??
          undefined,
        typeOfLastYearBusiness:
          educationdetails?.selfEmployment?.typeOfLastYearBusiness ?? '',
        profitMoreAmountLastYear:
          educationdetails?.selfEmployment?.profitMoreAmountLastYear ??
          undefined,
        totalEarnedAmountLastYear:
          educationdetails?.selfEmployment?.totalEarnedAmountLastYear ?? '',
        isSelfEmployedDuringCurrentYear:
          educationdetails?.selfEmployment?.isSelfEmployedDuringCurrentYear ??
          undefined,
        typeOfCurrentYearBusiness:
          educationdetails?.selfEmployment?.typeOfCurrentYearBusiness ?? '',
        profitMoreAmountCurrentYear:
          educationdetails?.selfEmployment?.profitMoreAmountCurrentYear ??
          undefined,
        totalEarnedAmountCurrentYear:
          educationdetails?.selfEmployment?.totalEarnedAmountCurrentYear ?? '',
        isSelfEmployedNextYear:
          educationdetails?.selfEmployment?.isSelfEmployedNextYear ?? undefined,
        typeOfNextYearBusiness:
          educationdetails?.selfEmployment?.typeOfNextYearBusiness ?? '',
        profitMoreAmountNextYear:
          educationdetails?.selfEmployment?.profitMoreAmountNextYear ??
          undefined,
      },
      ForeignWork: {
        haveWorkedOutSideUSA:
          educationdetails?.foreignWork?.haveWorkedOutSideUSA ?? undefined,
        isSalaryWageOrCurrentYearSelfEmployement:
          educationdetails?.foreignWork
            ?.isSalaryWageOrCurrentYearSelfEmployement ?? undefined,
        totalEarnedAmountCurrentYear:
          educationdetails?.foreignWork?.totalEarnedAmountCurrentYear ?? '',
        earningShowInSocialSecutiryStatementCurrentYear:
          educationdetails?.foreignWork
            ?.earningShowInSocialSecutiryStatementCurrentYear ?? undefined,
        isSalaryWageOrLastYearSelfEmployement:
          educationdetails?.foreignWork
            ?.isSalaryWageOrLastYearSelfEmployement ?? undefined,
        totalEarnedAmountLastYear:
          educationdetails?.foreignWork?.totalEarnedAmountLastYear ?? '',
        earningShowInSocialSecutiryStatementLastYear:
          educationdetails?.foreignWork
            ?.earningShowInSocialSecutiryStatementLastYear ?? undefined,
        eligibleForBenefitsUnderForeignSocialSecuritySystem:
          educationdetails?.foreignWork
            ?.eligibleForBenefitsUnderForeignSocialSecuritySystem ?? undefined,
        usaTaxesTakenOutCurrentYear:
          educationdetails?.foreignWork?.usaTaxesTakenOutCurrentYear ??
          undefined,
        usaTaxesNotTakenOutCurrentYearMonths:
          educationdetails?.foreignWork?.usaTaxesNotTakenOutCurrentYearMonths ??
          [],
        usaTaxesTakenOutPastYear:
          educationdetails?.foreignWork?.usaTaxesTakenOutPastYear ?? undefined,
        usaTaxesNotTakenOutPastYearMonths:
          educationdetails?.foreignWork?.usaTaxesNotTakenOutPastYearMonths ??
          [],
      },
      WorkTaxDetails: {
        didPaymentPaidAnotherYear:
          educationdetails?.workTaxDetail?.didPaymentPaidAnotherYear ??
          undefined,
        haveWorkedWhereTaxesNotDeducted:
          educationdetails?.workTaxDetail?.haveWorkedWhereTaxesNotDeducted ??
          undefined,
        isReceivingPensionOrAnnuity:
          educationdetails?.workTaxDetail?.isReceivingPensionOrAnnuity ??
          undefined,
        isPensionOrAnnuityBasedOnGovernmentEmployment:
          educationdetails?.workTaxDetail
            ?.isPensionOrAnnuityBasedOnGovernmentEmployment ?? undefined,
        isExpectPensionOrAnnuityInFuture:
          educationdetails?.workTaxDetail?.isExpectPensionOrAnnuityInFuture ??
          undefined,
        pensionAnnuityRailroadWork:
          educationdetails?.workTaxDetail?.pensionAnnuityRailroadWork ??
          undefined,
        pensionAnnuityFutureGovtWork:
          educationdetails?.workTaxDetail?.pensionAnnuityFutureGovtWork ??
          undefined,
        pensionAnnuityFutureStartDate:
          educationdetails?.workTaxDetail?.pensionAnnuityFutureStartDate ?? '',
        lumpSumNoTaxes:
          educationdetails?.workTaxDetail?.lumpSumNoTaxes ?? undefined,
      },
    }
    return EducationAndEmployement
  }

  return null // Return null if data is not present in response
}

const hospitalValues = {
  hospitalName: '',
  address: {
    streetAddress: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
  },
  phoneNumber: '',
  arrivedDate: '',
  leftDate: '',
  treatmentType: [],
  typeOfTherapyDoctorProvided: [],
  otherTreatment: '',
  otherTypeOfTherapyDetails: '',
  specialProcedureDetail: '',
}
const organizationValues = {
  organizationName: '',
  address: {
    streetAddress: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
  },
  phoneNumber: '',
  contactPersonFirstName: '',
  contactPersonLastName: '',
  arrivedDate: '',
  leftDate: '',
}

export const extractDetailedApplicationMedicalData = (
  medicaldata: DynamicObj,
  applicationId: string,
): DynamicObj | null => {
  // Check if response contains data

  if (medicaldata) {
    const MedicalDetails = {
      GeneralHealth: {
        applicationId,
        dateOfBirth: medicaldata?.generalHealth?.dateOfBirth ?? '',
        gender: medicaldata?.generalHealth?.gender ?? '',
        feet: medicaldata?.generalHealth?.physique?.feet ?? 0,
        inch: medicaldata?.generalHealth?.physique?.inch ?? 0,
        weight: parseInt(medicaldata?.generalHealth?.physique?.weight) ?? 0,
      },
      VisionAndHearing: {
        medicalDetailId: medicaldata?.medicalId ?? '',
        zeroOrLessVision2020:
          medicaldata?.visionAndHearing?.zeroOrLessVision2020 ?? undefined,
        visionCorrectorDetail:
          medicaldata?.visionAndHearing?.visionCorrectorDetail ?? '',
        isImpairedVision:
          medicaldata?.visionAndHearing?.isImpairedVision ?? undefined,
        visionRelatedSymptoms:
          medicaldata?.visionAndHearing?.visionRelatedSymptoms ?? [],
        additionalVisionRelatedSymptoms:
          medicaldata?.visionAndHearing?.additionalVisionRelatedSymptoms ?? '',
        isPartialOrTotalDeaf:
          medicaldata?.visionAndHearing?.isPartialOrTotalDeaf ?? undefined,
        isUsingHearingAids:
          medicaldata?.visionAndHearing?.isUsingHearingAids ?? undefined,
        isImpairedHearing:
          medicaldata?.visionAndHearing?.isImpairedHearing ?? undefined,
        hearingSymptoms: medicaldata?.visionAndHearing?.hearingSymptoms ?? [],
        additionalHearingRelatedSymptoms:
          medicaldata?.visionAndHearing?.additionalHearingRelatedSymptoms ?? '',
      },
      IllnessesInjuriesAndConditions: {
        medicalDetailId: medicaldata?.medicalId ?? '',
        muscleOrMemoryRelatedSymptoms:
          medicaldata?.illnessesInjuriesAndConditions
            ?.muscleOrMemoryRelatedSymptoms ?? [],
        energyOrSleepRelatedSymptoms:
          medicaldata?.illnessesInjuriesAndConditions
            ?.energyOrSleepRelatedSymptoms ?? [],
        feverOrSkinRelatedSymptoms:
          medicaldata?.illnessesInjuriesAndConditions
            ?.feverOrSkinRelatedSymptoms ?? [],
        breathingOrHeartRelatedSymptoms:
          medicaldata?.illnessesInjuriesAndConditions
            ?.breathingOrHeartRelatedSymptoms ?? [],
        speechRelatedSymptoms:
          medicaldata?.illnessesInjuriesAndConditions?.speechRelatedSymptoms ??
          [],
        memoryRelatedSymptoms:
          medicaldata?.illnessesInjuriesAndConditions?.memoryRelatedSymptoms ??
          [],
        mentalHealthRelatedSymptoms:
          medicaldata?.illnessesInjuriesAndConditions
            ?.mentalHealthRelatedSymptoms ?? [],
        urinarySymptoms:
          medicaldata?.illnessesInjuriesAndConditions?.urinarySymptoms ?? [],
        dizzinessOrHeadacheRelatedSymptoms:
          medicaldata?.illnessesInjuriesAndConditions
            ?.dizzinessOrHeadacheRelatedSymptoms ?? [],
        isSeizureRelatedSymptoms:
          medicaldata?.illnessesInjuriesAndConditions
            ?.isSeizureRelatedSymptoms ?? undefined,
        seizureRelatedSymptoms:
          medicaldata?.illnessesInjuriesAndConditions?.seizureRelatedSymptoms ??
          [],
        anotherSymptoms:
          medicaldata?.illnessesInjuriesAndConditions?.anotherSymptoms ?? '',
        howLongSymptomLast:
          medicaldata?.illnessesInjuriesAndConditions?.howLongSymptomLast ?? '',
        isAfterEffectSeizureExperience:
          medicaldata?.illnessesInjuriesAndConditions
            ?.isAfterEffectSeizureExperience ?? undefined,
        seizureAfterEffectExperience:
          medicaldata?.illnessesInjuriesAndConditions
            ?.seizureAfterEffectExperience ?? [],
        emotionalOrLearningProblems:
          medicaldata?.illnessesInjuriesAndConditions
            ?.emotionalOrLearningProblems ?? [],
        speechSymptomsDuration:
          medicaldata?.illnessesInjuriesAndConditions?.speechSymptomsDuration ??
          '',
        headachemigrainesymptoms:
          medicaldata?.illnessesInjuriesAndConditions
            ?.headachemigrainesymptoms ?? [],
        headachemigrainesymptomduration:
          medicaldata?.illnessesInjuriesAndConditions
            ?.headachemigrainesymptomduration ?? '',
        seizureaftereffectsymptomsduration:
          medicaldata?.illnessesInjuriesAndConditions
            ?.seizureaftereffectsymptomsduration ?? '',
      },
      MedicalProfessionalDetail: {
        medicalDetailId: medicaldata?.medicalId ?? '',
        isMentalIllness:
          medicaldata?.medicalProfessionals?.isMentalIllness ?? undefined,
        isPhysicalIllness:
          medicaldata?.medicalProfessionals?.isPhysicalIllness ?? undefined,
        mentalIllnessMedicalProfessionals:
          medicaldata?.medicalProfessionals?.mentalIllnessMedicalProfessionals?.map(
            (professional: {
              suffix: any
              doctorFirstName: any
              doctorLastName: any
              doctorMedicalSpecialty: any
              officeOrClinicName: any
              address: {
                streetAddress: any
                city: any
                state: any
                postalCode: any
                country: any
              }
              phoneNumber: any
              firstAppointmentDate: any
              lastAppointmentDate: any
              nextAppointmentDate: any
              treatmentType: any
              typeOfTherapyDoctorProvided: any
              otherTreatment: any
              otherTypeOfTherapyDetails: any
              specialProcedureDetail: any
            }) => ({
              suffix: professional.suffix ?? '',
              doctorFirstName: professional.doctorFirstName ?? '',
              doctorLastName: professional.doctorLastName ?? '',
              doctorMedicalSpecialty: professional.doctorMedicalSpecialty ?? '',
              officeOrClinicName: professional.officeOrClinicName ?? '',
              address: {
                streetAddress: professional.address?.streetAddress ?? '',
                city: professional.address?.city ?? '',
                state: professional.address?.state ?? '',
                postalCode: professional.address?.postalCode ?? '',
                country: professional.address?.country ?? '',
              },
              phoneNumber: professional.phoneNumber ?? '',
              firstAppointmentDate: professional.firstAppointmentDate ?? '',
              lastAppointmentDate: professional.lastAppointmentDate ?? '',
              nextAppointmentDate: professional.nextAppointmentDate ?? '',
              treatmentType: professional.treatmentType ?? [],
              typeOfTherapyDoctorProvided:
                professional.typeOfTherapyDoctorProvided ?? [],
              otherTreatment: professional.otherTreatment ?? '',
              otherTypeOfTherapyDetails:
                professional.otherTypeOfTherapyDetails ?? '',
              specialProcedureDetail: professional.specialProcedureDetail ?? '',
            }),
          ) ?? [
            {
              suffix: '',
              doctorFirstName: '',
              doctorLastName: '',
              doctorMedicalSpecialty: '',
              officeOrClinicName: '',
              address: {
                streetAddress: '',
                city: '',
                state: '',
                postalCode: '',
                country: '',
              },
              phoneNumber: '',
              firstAppointmentDate: '',
              lastAppointmentDate: '',
              nextAppointmentDate: '',
              treatmentType: [],
              otherTreatment: '',
              typeOfTherapyDoctorProvided: [],
              otherTypeOfTherapyDetails: '',
              specialProcedureDetail: '',
            },
          ],

        physicalIllnessMedicalProfessionals:
          medicaldata?.medicalProfessionals?.physicalIllnessMedicalProfessionals?.map(
            (professional: {
              suffix: any
              doctorFirstName: any
              doctorLastName: any
              doctorMedicalSpecialty: any
              officeOrClinicName: any
              address: {
                streetAddress: any
                city: any
                state: any
                postalCode: any
                country: any
              }
              phoneNumber: any
              firstAppointmentDate: any
              lastAppointmentDate: any
              nextAppointmentDate: any
              treatmentType: any
              typeOfTherapyDoctorProvided: any
              otherTreatment: any
              otherTypeOfTherapyDetails: any
              specialProcedureDetail: any
            }) => ({
              suffix: professional.suffix ?? '',
              doctorFirstName: professional.doctorFirstName ?? '',
              doctorLastName: professional.doctorLastName ?? '',
              doctorMedicalSpecialty: professional.doctorMedicalSpecialty ?? '',
              officeOrClinicName: professional.officeOrClinicName ?? '',
              address: {
                streetAddress: professional.address?.streetAddress ?? '',
                city: professional.address?.city ?? '',
                state: professional.address?.state ?? '',
                postalCode: professional.address?.postalCode ?? '',
                country: professional.address?.country ?? '',
              },
              phoneNumber: professional.phoneNumber ?? '',
              firstAppointmentDate: professional.firstAppointmentDate ?? '',
              lastAppointmentDate: professional.lastAppointmentDate ?? '',
              nextAppointmentDate: professional.nextAppointmentDate ?? '',
              treatmentType: professional.treatmentType ?? [],
              typeOfTherapyDoctorProvided:
                professional.typeOfTherapyDoctorProvided ?? [],
              otherTreatment: professional.otherTreatment ?? '',
              otherTypeOfTherapyDetails:
                professional.otherTypeOfTherapyDetails ?? '',
              specialProcedureDetail: professional.specialProcedureDetail ?? '',
            }),
          ) ?? [
            {
              suffix: '',
              doctorFirstName: '',
              doctorLastName: '',
              doctorMedicalSpecialty: '',
              officeOrClinicName: '',
              address: {
                streetAddress: '',
                city: '',
                state: '',
                postalCode: '',
                country: '',
              },
              phoneNumber: '',
              firstAppointmentDate: '',
              lastAppointmentDate: '',
              nextAppointmentDate: '',
              treatmentType: [],
              otherTreatment: '',
              typeOfTherapyDoctorProvided: [],
              otherTypeOfTherapyDetails: '',
              specialProcedureDetail: '',
            },
          ],
      },
      HospitalAndMedicalFacilityCare: {
        medicalDetailId: medicaldata?.medicalId ?? '',
        haveEmergencyRoomVisit:
          medicaldata?.hospitalAndMedicalFacilityCare?.haveEmergencyRoomVisit ??
          undefined,
        haveEmergencyRoomVisitMedicalFacility:
          medicaldata?.hospitalAndMedicalFacilityCare?.haveEmergencyRoomVisitMedicalFacility?.map(
            (facility: {
              hospitalName: any
              address: {
                streetAddress: any
                city: any
                state: any
                postalCode: any
                country: any
              }
              phoneNumber: any
              arrivedDate: any
              leftDate: any
              treatmentType: any
              typeOfTherapyDoctorProvided: any
              otherTreatment: any
              otherTypeOfTherapyDetails: any
              specialProcedureDetail: any
            }) => ({
              hospitalName: facility.hospitalName ?? '',
              address: {
                streetAddress: facility.address?.streetAddress ?? '',
                city: facility.address?.city ?? '',
                state: facility.address?.state ?? '',
                postalCode: facility.address?.postalCode ?? '',
                country: facility.address?.country ?? '',
              },
              phoneNumber: facility.phoneNumber ?? '',
              arrivedDate: facility.arrivedDate ?? '',
              leftDate: facility.leftDate ?? '',
              treatmentType: facility.treatmentType ?? [],
              typeOfTherapyDoctorProvided:
                facility.typeOfTherapyDoctorProvided ?? [],
              otherTreatment: facility.otherTreatment ?? '',
              otherTypeOfTherapyDetails:
                facility.otherTypeOfTherapyDetails ?? '',
              specialProcedureDetail: facility.specialProcedureDetail ?? '',
            }),
          ) ?? [hospitalValues],
        haveMedicalFacility:
          medicaldata?.hospitalAndMedicalFacilityCare?.haveMedicalFacility ??
          undefined,
        haveMedicalFacilityMedicalFacility:
          medicaldata?.hospitalAndMedicalFacilityCare?.haveMedicalFacilityMedicalFacility?.map(
            (facility: {
              hospitalName: any
              address: {
                streetAddress: any
                city: any
                state: any
                postalCode: any
                country: any
              }
              phoneNumber: any
              arrivedDate: any
              leftDate: any
              treatmentType: any
              typeOfTherapyDoctorProvided: any
              otherTreatment: any
              otherTypeOfTherapyDetails: any
              specialProcedureDetail: any
            }) => ({
              hospitalName: facility.hospitalName ?? '',
              address: {
                streetAddress: facility.address?.streetAddress ?? '',
                city: facility.address?.city ?? '',
                state: facility.address?.state ?? '',
                postalCode: facility.address?.postalCode ?? '',
                country: facility.address?.country ?? '',
              },
              phoneNumber: facility.phoneNumber ?? '',
              arrivedDate: facility.arrivedDate ?? '',
              leftDate: facility.leftDate ?? '',
              treatmentType: facility.treatmentType ?? [],
              typeOfTherapyDoctorProvided:
                facility.typeOfTherapyDoctorProvided ?? [],
              otherTreatment: facility.otherTreatment ?? '',
              otherTypeOfTherapyDetails:
                facility.otherTypeOfTherapyDetails ?? '',
              specialProcedureDetail: facility.specialProcedureDetail ?? '',
            }),
          ) ?? [hospitalValues],
        haveMentalHeathTreatment:
          medicaldata?.hospitalAndMedicalFacilityCare
            ?.haveMentalHeathTreatment ?? undefined,
        haveMentalHeathTreatmentMedicalFacility:
          medicaldata?.hospitalAndMedicalFacilityCare?.haveMentalHeathTreatmentMedicalFacility?.map(
            (facility: {
              hospitalName: any
              address: {
                streetAddress: any
                city: any
                state: any
                postalCode: any
                country: any
              }
              phoneNumber: any
              arrivedDate: any
              leftDate: any
              treatmentType: any
              typeOfTherapyDoctorProvided: any
              otherTreatment: any
              otherTypeOfTherapyDetails: any
              specialProcedureDetail: any
            }) => ({
              hospitalName: facility.hospitalName ?? '',
              address: {
                streetAddress: facility.address?.streetAddress ?? '',
                city: facility.address?.city ?? '',
                state: facility.address?.state ?? '',
                postalCode: facility.address?.postalCode ?? '',
                country: facility.address?.country ?? '',
              },
              phoneNumber: facility.phoneNumber ?? '',
              arrivedDate: facility.arrivedDate ?? '',
              leftDate: facility.leftDate ?? '',
              treatmentType: facility.treatmentType ?? [],
              typeOfTherapyDoctorProvided:
                facility.typeOfTherapyDoctorProvided ?? [],
              otherTreatment: facility.otherTreatment ?? '',
              otherTypeOfTherapyDetails:
                facility.otherTypeOfTherapyDetails ?? '',
              specialProcedureDetail: facility.specialProcedureDetail ?? '',
            }),
          ) ?? [hospitalValues],
        haveInpatientOrOverNightStay:
          medicaldata?.hospitalAndMedicalFacilityCare
            ?.haveInpatientOrOverNightStay ?? undefined,
        haveInpatientOrOverNightStayMedicalFacility:
          medicaldata?.hospitalAndMedicalFacilityCare?.haveInpatientOrOverNightStayMedicalFacility?.map(
            (facility: {
              hospitalName: any
              address: {
                streetAddress: any
                city: any
                state: any
                postalCode: any
                country: any
              }
              phoneNumber: any
              arrivedDate: any
              leftDate: any
              treatmentType: any
              typeOfTherapyDoctorProvided: any
              otherTreatment: any
              otherTypeOfTherapyDetails: any
              specialProcedureDetail: any
            }) => ({
              hospitalName: facility.hospitalName ?? '',
              address: {
                streetAddress: facility.address?.streetAddress ?? '',
                city: facility.address?.city ?? '',
                state: facility.address?.state ?? '',
                postalCode: facility.address?.postalCode ?? '',
                country: facility.address?.country ?? '',
              },
              phoneNumber: facility.phoneNumber ?? '',
              arrivedDate: facility.arrivedDate ?? '',
              leftDate: facility.leftDate ?? '',
              treatmentType: facility.treatmentType ?? [],
              typeOfTherapyDoctorProvided:
                facility.typeOfTherapyDoctorProvided ?? [],
              otherTreatment: facility.otherTreatment ?? '',
              otherTypeOfTherapyDetails:
                facility.otherTypeOfTherapyDetails ?? '',
              specialProcedureDetail: facility.specialProcedureDetail ?? '',
            }),
          ) ?? [hospitalValues],
        haveOutpatientOrSamedayVisit:
          medicaldata?.hospitalAndMedicalFacilityCare
            ?.haveOutpatientOrSamedayVisit ?? undefined,
        haveOutpatientOrSamedayVisitMedicalFacility:
          medicaldata?.hospitalAndMedicalFacilityCare?.haveOutpatientOrSamedayVisitMedicalFacility?.map(
            (facility: {
              hospitalName: any
              address: {
                streetAddress: any
                city: any
                state: any
                postalCode: any
                country: any
              }
              phoneNumber: any
              arrivedDate: any
              leftDate: any
              treatmentType: any
              typeOfTherapyDoctorProvided: any
              otherTreatment: any
              otherTypeOfTherapyDetails: any
              specialProcedureDetail: any
            }) => ({
              hospitalName: facility.hospitalName ?? '',
              address: {
                streetAddress: facility.address?.streetAddress ?? '',
                city: facility.address?.city ?? '',
                state: facility.address?.state ?? '',
                postalCode: facility.address?.postalCode ?? '',
                country: facility.address?.country ?? '',
              },
              phoneNumber: facility.phoneNumber ?? '',
              arrivedDate: facility.arrivedDate ?? '',
              leftDate: facility.leftDate ?? '',
              treatmentType: facility.treatmentType ?? [],
              typeOfTherapyDoctorProvided:
                facility.typeOfTherapyDoctorProvided ?? [],
              otherTreatment: facility.otherTreatment ?? '',
              otherTypeOfTherapyDetails:
                facility.otherTypeOfTherapyDetails ?? '',
              specialProcedureDetail: facility.specialProcedureDetail ?? '',
            }),
          ) ?? [hospitalValues],
        haveSurgeries:
          medicaldata?.hospitalAndMedicalFacilityCare?.haveSurgeries ??
          undefined,
        haveSurgeriesMedicalFacility:
          medicaldata?.hospitalAndMedicalFacilityCare?.haveSurgeriesMedicalFacility?.map(
            (facility: {
              hospitalName: any
              address: {
                streetAddress: any
                city: any
                state: any
                postalCode: any
                country: any
              }
              phoneNumber: any
              arrivedDate: any
              leftDate: any
              treatmentType: any
              typeOfTherapyDoctorProvided: any
              otherTreatment: any
              otherTypeOfTherapyDetails: any
              specialProcedureDetail: any
            }) => ({
              hospitalName: facility.hospitalName ?? '',
              address: {
                streetAddress: facility.address?.streetAddress ?? '',
                city: facility.address?.city ?? '',
                state: facility.address?.state ?? '',
                postalCode: facility.address?.postalCode ?? '',
                country: facility.address?.country ?? '',
              },
              phoneNumber: facility.phoneNumber ?? '',
              arrivedDate: facility.arrivedDate ?? '',
              leftDate: facility.leftDate ?? '',
              treatmentType: facility.treatmentType ?? [],
              typeOfTherapyDoctorProvided:
                facility.typeOfTherapyDoctorProvided ?? [],
              otherTreatment: facility.otherTreatment ?? '',
              otherTypeOfTherapyDetails:
                facility.otherTypeOfTherapyDetails ?? '',
              specialProcedureDetail: facility.specialProcedureDetail ?? '',
            }),
          ) ?? [hospitalValues],
        havePhysicalTherapy:
          medicaldata?.hospitalAndMedicalFacilityCare?.havePhysicalTherapy ??
          undefined,
        havePhysicalTherapyMedicalFacility:
          medicaldata?.hospitalAndMedicalFacilityCare?.havePhysicalTherapyMedicalFacility?.map(
            (facility: {
              hospitalName: any
              address: {
                streetAddress: any
                city: any
                state: any
                postalCode: any
                country: any
              }
              phoneNumber: any
              arrivedDate: any
              leftDate: any
              treatmentType: any
              typeOfTherapyDoctorProvided: any
              otherTreatment: any
              otherTypeOfTherapyDetails: any
              specialProcedureDetail: any
            }) => ({
              hospitalName: facility.hospitalName ?? '',
              address: {
                streetAddress: facility.address?.streetAddress ?? '',
                city: facility.address?.city ?? '',
                state: facility.address?.state ?? '',
                postalCode: facility.address?.postalCode ?? '',
                country: facility.address?.country ?? '',
              },
              phoneNumber: facility.phoneNumber ?? '',
              arrivedDate: facility.arrivedDate ?? '',
              leftDate: facility.leftDate ?? '',
              treatmentType: facility.treatmentType ?? [],
              typeOfTherapyDoctorProvided:
                facility.typeOfTherapyDoctorProvided ?? [],
              otherTreatment: facility.otherTreatment ?? '',
              otherTypeOfTherapyDetails:
                facility.otherTypeOfTherapyDetails ?? '',
              specialProcedureDetail: facility.specialProcedureDetail ?? '',
            }),
          ) ?? [hospitalValues],
        isDoctorInJail:
          medicaldata?.hospitalAndMedicalFacilityCare?.isDoctorInJail ??
          undefined,
        jailDoctorDetail:
          medicaldata?.hospitalAndMedicalFacilityCare?.jailDoctorDetail?.map(
            (facility: {
              organizationName: string
              address: {
                streetAddress: string
                city: string
                state: string
                postalCode: string
                country: string
              }
              phoneNumber: string
              contactPersonFirstName: string
              contactPersonLastName: string
              arrivedDate: string
              leftDate: string
            }) => ({
              organizationName: facility.organizationName ?? '',
              address: {
                streetAddress: facility.address?.streetAddress ?? '',
                city: facility.address?.city ?? '',
                state: facility.address?.state ?? '',
                postalCode: facility.address?.postalCode ?? '',
                country: facility.address?.country ?? '',
              },
              phoneNumber: facility.phoneNumber ?? '',
              contactPersonFirstName: facility.contactPersonFirstName ?? '',
              contactPersonLastName: facility.contactPersonLastName ?? '',
              arrivedDate: facility.arrivedDate ?? '',
              leftDate: facility.leftDate ?? '',
            }),
          ) ?? [organizationValues],
        isPublicWelfare:
          medicaldata?.hospitalAndMedicalFacilityCare?.isPublicWelfare ??
          undefined,
        welfareDetail:
          medicaldata?.hospitalAndMedicalFacilityCare?.welfareDetail?.map(
            (facility: {
              organizationName: string
              address: {
                streetAddress: string
                city: string
                state: string
                postalCode: string
                country: string
              }
              phoneNumber: string
              contactPersonFirstName: string
              contactPersonLastName: string
              arrivedDate: string
              leftDate: string
            }) => ({
              organizationName: facility.organizationName ?? '',
              address: {
                streetAddress: facility.address?.streetAddress ?? '',
                city: facility.address?.city ?? '',
                state: facility.address?.state ?? '',
                postalCode: facility.address?.postalCode ?? '',
                country: facility.address?.country ?? '',
              },
              phoneNumber: facility.phoneNumber ?? '',
              contactPersonFirstName: facility.contactPersonFirstName ?? '',
              contactPersonLastName: facility.contactPersonLastName ?? '',
              arrivedDate: facility.arrivedDate ?? '',
              leftDate: facility.leftDate ?? '',
            }),
          ) ?? [organizationValues],
        isRecordHeldByAttorney:
          medicaldata?.hospitalAndMedicalFacilityCare?.isRecordHeldByAttorney ??
          undefined,
        attorneyDetail:
          medicaldata?.hospitalAndMedicalFacilityCare?.attorneyDetail?.map(
            (facility: {
              organizationName: string
              address: {
                streetAddress: string
                city: string
                state: string
                postalCode: string
                country: string
              }
              phoneNumber: string
              contactPersonFirstName: string
              contactPersonLastName: string
              arrivedDate: string
              leftDate: string
            }) => ({
              organizationName: facility.organizationName ?? '',
              address: {
                streetAddress: facility.address?.streetAddress ?? '',
                city: facility.address?.city ?? '',
                state: facility.address?.state ?? '',
                postalCode: facility.address?.postalCode ?? '',
                country: facility.address?.country ?? '',
              },
              phoneNumber: facility.phoneNumber ?? '',
              contactPersonFirstName: facility.contactPersonFirstName ?? '',
              contactPersonLastName: facility.contactPersonLastName ?? '',
              arrivedDate: facility.arrivedDate ?? '',
              leftDate: facility.leftDate ?? '',
            }),
          ) ?? [organizationValues],
        haveDisabilityInsurance:
          medicaldata?.hospitalAndMedicalFacilityCare
            ?.haveDisabilityInsurance ?? undefined,
        insuranceDetail:
          medicaldata?.hospitalAndMedicalFacilityCare?.insuranceDetail?.map(
            (facility: {
              organizationName: string
              address: {
                streetAddress: string
                city: string
                state: string
                postalCode: string
                country: string
              }
              phoneNumber: string
              contactPersonFirstName: string
              contactPersonLastName: string
              arrivedDate: string
              leftDate: string
            }) => ({
              organizationName: facility.organizationName ?? '',
              address: {
                streetAddress: facility.address?.streetAddress ?? '',
                city: facility.address?.city ?? '',
                state: facility.address?.state ?? '',
                postalCode: facility.address?.postalCode ?? '',
                country: facility.address?.country ?? '',
              },
              phoneNumber: facility.phoneNumber ?? '',
              contactPersonFirstName: facility.contactPersonFirstName ?? '',
              contactPersonLastName: facility.contactPersonLastName ?? '',
              arrivedDate: facility.arrivedDate ?? '',
              leftDate: facility.leftDate ?? '',
            }),
          ) ?? [organizationValues],
        isVocationalRehabilitationService:
          medicaldata?.hospitalAndMedicalFacilityCare
            ?.isVocationalRehabilitationService ?? undefined,
        rehabilitationOrganizationDetail:
          medicaldata?.hospitalAndMedicalFacilityCare?.rehabilitationOrganizationDetail?.map(
            (facility: {
              organizationName: string
              address: {
                streetAddress: string
                city: string
                state: string
                postalCode: string
                country: string
              }
              phoneNumber: string
              contactPersonFirstName: string
              contactPersonLastName: string
              arrivedDate: string
              leftDate: string
            }) => ({
              organizationName: facility.organizationName ?? '',
              address: {
                streetAddress: facility.address?.streetAddress ?? '',
                city: facility.address?.city ?? '',
                state: facility.address?.state ?? '',
                postalCode: facility.address?.postalCode ?? '',
                country: facility.address?.country ?? '',
              },
              phoneNumber: facility.phoneNumber ?? '',
              contactPersonFirstName: facility.contactPersonFirstName ?? '',
              contactPersonLastName: facility.contactPersonLastName ?? '',
              arrivedDate: facility.arrivedDate ?? '',
              leftDate: facility.leftDate ?? '',
            }),
          ) ?? [organizationValues],
        isWorkersCompensation:
          medicaldata?.hospitalAndMedicalFacilityCare?.isWorkersCompensation ??
          undefined,
        workersCompensationDetail:
          medicaldata?.hospitalAndMedicalFacilityCare?.workersCompensationDetail?.map(
            (facility: {
              organizationName: string
              address: {
                streetAddress: string
                city: string
                state: string
                postalCode: string
                country: string
              }
              phoneNumber: string
              contactPersonFirstName: string
              contactPersonLastName: string
              arrivedDate: string
              leftDate: string
            }) => ({
              organizationName: facility.organizationName ?? '',
              address: {
                streetAddress: facility.address?.streetAddress ?? '',
                city: facility.address?.city ?? '',
                state: facility.address?.state ?? '',
                postalCode: facility.address?.postalCode ?? '',
                country: facility.address?.country ?? '',
              },
              phoneNumber: facility.phoneNumber ?? '',
              contactPersonFirstName: facility.contactPersonFirstName ?? '',
              contactPersonLastName: facility.contactPersonLastName ?? '',
              arrivedDate: facility.arrivedDate ?? '',
              leftDate: facility.leftDate ?? '',
            }),
          ) ?? [organizationValues],
        isOtherHaveMedicalRecords:
          medicaldata?.hospitalAndMedicalFacilityCare
            ?.isOtherHaveMedicalRecords ?? undefined,
        otherOrganizationDetail:
          medicaldata?.hospitalAndMedicalFacilityCare?.otherOrganizationDetail?.map(
            (facility: {
              organizationName: string
              address: {
                streetAddress: string
                city: string
                state: string
                postalCode: string
                country: string
              }
              phoneNumber: string
              contactPersonFirstName: string
              contactPersonLastName: string
              arrivedDate: string
              leftDate: string
            }) => ({
              organizationName: facility.organizationName ?? '',
              address: {
                streetAddress: facility.address?.streetAddress ?? '',
                city: facility.address?.city ?? '',
                state: facility.address?.state ?? '',
                postalCode: facility.address?.postalCode ?? '',
                country: facility.address?.country ?? '',
              },
              phoneNumber: facility.phoneNumber ?? '',
              contactPersonFirstName: facility.contactPersonFirstName ?? '',
              contactPersonLastName: facility.contactPersonLastName ?? '',
              arrivedDate: facility.arrivedDate ?? '',
              leftDate: facility.leftDate ?? '',
            }),
          ) ?? [organizationValues],
      },
      MedicalTest: {
        medicalDetailId: medicaldata?.medicalId ?? '',
        isMedicalTestCompleted:
          medicaldata?.medicalTest?.isMedicalTestCompleted ?? undefined,
        medicalTest: medicaldata?.medicalTest?.medicalTest?.map(
          (test: {
            medicalTestName: any
            suffix: any
            doctorFirstName: any
            doctorLastName: any
            testedBodyPart: any
            mostRecentDateTestPerformed: any
            futureDateTestPerform: any
          }) => ({
            medicalTestName: test.medicalTestName ?? '',
            suffix: test.suffix ?? '',
            doctorFirstName: test.doctorFirstName ?? '',
            doctorLastName: test.doctorLastName ?? '',
            testedBodyPart: test.testedBodyPart ?? '',
            mostRecentDateTestPerformed: test.mostRecentDateTestPerformed ?? '',
            futureDateTestPerform: test.futureDateTestPerform ?? '',
          }),
        ) ?? [
          {
            medicalTestName: '',
            suffix: '',
            doctorFirstName: '',
            doctorLastName: '',
            testedBodyPart: '',
            mostRecentDateTestPerformed: '',
            futureDateTestPerform: '',
          },
        ],
      },
      Medicine: {
        medicalDetailId: medicaldata?.medicalId ?? '',
        isMedication: medicaldata?.medicine?.isMedication ?? undefined,
        medication: medicaldata?.medicine?.medication?.map(
          (med: {
            medicationName: any
            suffix: any
            doctorFirstName: any
            doctorLastName: any
            medicationReason: any
            sideEffects: any
          }) => ({
            medicationName: med.medicationName ?? '',
            suffix: med.suffix ?? '',
            doctorFirstName: med.doctorFirstName ?? '',
            doctorLastName: med.doctorLastName ?? '',
            medicationReason: med.medicationReason ?? '',
            sideEffects: med.sideEffects ?? '',
          }),
        ) ?? [
          {
            medicationName: '',
            suffix: '',
            doctorFirstName: '',
            doctorLastName: '',
            medicationReason: '',
            sideEffects: '',
          },
        ],
      },
      AdditionalInformation: {
        medicalDetailId: medicaldata?.medicalId ?? '',
        additionalInformation: medicaldata?.additionalInformation ?? '',
      },
    }
    return MedicalDetails
  }

  return null // Return null if data is not present in response
}
export const extractSignUpFormData = (response: any): SignUpFormType | null => {
  // Check if response contains data

  if (response.data?.data) {
    // Extract data from response
    const jsonData = response.data
    const values: SignUpFormType = {
      firstName: jsonData?.data?.firstName ?? '',
      lastName: jsonData?.data?.lastName ?? '',
      phoneNumber: jsonData?.data?.phoneNumber ?? '',
      country: jsonData?.data?.country ?? '',
      emailId: jsonData?.data?.emailId ?? '',
      registration: jsonData?.data?.registration ?? '',
      orgName: jsonData?.data?.OrganizationData?.name ?? '',
      orgUuid: jsonData?.data?.OrganizationData?.uuid ?? '',
      orgCity: jsonData?.data?.OrganizationData?.address?.city ?? '',
      orgState: jsonData?.data?.OrganizationData?.address?.state ?? '',
      orgPostalCode:
        jsonData?.data?.OrganizationData?.address?.postalCode ?? '',
      orgStreetAddress:
        jsonData?.data?.OrganizationData?.address?.streetAddress ?? '',
      orgDescription: jsonData?.data?.OrganizationData?.description ?? '',
      subName: jsonData?.data?.SubscrioptionData?.name ?? '',
      subPlan: jsonData?.data?.SubscrioptionData?.plan ?? '',
      subUuid: jsonData?.data?.SubscrioptionData?.uuid ?? '',
      subAmount: jsonData?.data?.SubscrioptionData?.amount ?? 0,
      subDescription: jsonData?.data?.SubscrioptionData?.description ?? '',
      subNumberOfSeats: jsonData?.data?.SubscrioptionData?.numberOfseats ?? 0,
      subSubscriptionPeriod:
        jsonData?.data?.SubscrioptionData?.subscriptionPeriod ?? 0,
      subNumberOfApplications:
        jsonData?.data?.SubscrioptionData?.numberOfApplication ?? 0,
    }

    return values
  }

  return null // Return null if data is not present in response
}

export const extrctAppContactInfoData = (ContactInformation: DynamicObj) => {
  if (ContactInformation) {
    const contactInformationObj = {
      firstName: ContactInformation?.firstName,
      middleName: ContactInformation?.middleName,
      lastName: ContactInformation?.lastName,
      email: ContactInformation?.emailId,
      isUSAPhoneNumber: ContactInformation?.isUSAPhoneNumber,
      phone: ContactInformation?.phoneNumber,
      physicalAddress: {
        streetAddress: ContactInformation?.resideAddress?.streetAddress,
        city: ContactInformation?.resideAddress?.city,
        state: ContactInformation?.resideAddress?.state,
        postalCode: ContactInformation?.resideAddress?.postalCode,
        country: ContactInformation?.resideAddress?.country,
      },
      isLiveAtPhysicalAddress: ContactInformation?.isLiveAtPhysicalAddress,
      currentAddress: {
        streetAddress: ContactInformation?.address?.streetAddress,
        city: ContactInformation?.address?.city,
        state: ContactInformation?.address?.state,
        postalCode: ContactInformation?.address?.postalCode,
        country: ContactInformation?.address?.country,
      },

      wantToReceiveMailAtPhysicalAddress:
        ContactInformation?.wantToReceiveMailAtPhysicalAddress,
      mailingAddress: {
        streetAddress: ContactInformation?.mailAddress?.streetAddress,
        city: ContactInformation?.mailAddress?.city,
        state: ContactInformation?.mailAddress?.state,
        postalCode: ContactInformation?.mailAddress?.postalCode,
        country: ContactInformation?.mailAddress?.country,
      },
    }
    return contactInformationObj
  }
}

// export const extrctAppCitizenInfoData = (data: DynamicObj) => {
//   if (data) {
//     const obj = {

//       },
//     }
//     return obj
//   }
// }
export const extractOrganizationOverviewData = (
  response: DynamicObj,
): OrganizationListObj | null => {
  // Check if response contains data

  if (response.data?.data) {
    // Extract data from response
    const jsonData = response.data.data
    const values: OrganizationListObj = {
      bussinessName: jsonData?.name,
      bussinessDescription: jsonData?.description,
      bussinessStreetAddress: jsonData?.address.streetAddress,
      bussinessCity: jsonData?.address.city,
      bussinessCountry: jsonData?.address.country,
      bussinessState: jsonData?.address.state,
      bussinessPostalCode: jsonData?.address.postalCode,
      bussinessEmailId: jsonData?.emailId,
      bussinessAdminEmailId: jsonData?.adminEmailId,
      bussinessType: jsonData?.type,
      bussinessSpecialization: jsonData?.specialization,
      bussinessTrackingName: jsonData?.trackingName,
      bussinessProfileUrl: jsonData?.profileUrl,
      bussinessPhoneNumber: jsonData?.phoneNumber,
      bussinessSecondaryPhoneNumber: jsonData?.secondaryPhoneNumber,
      bussinessSecondaryEmailId: jsonData?.secondaryEmailId,
      isActive: jsonData?.isActive,
    }
    return values
  }

  return null // Return null if data is not present in response
}

export const extractOrganizationContactInfoData = (
  response: any,
): OrganizationListObj | null => {
  // Check if response contains data

  if (response.data.data) {
    // Extract data from response
    const jsonData = response.data.data
    const values: OrganizationListObj = {
      contactFirstName: jsonData?.firstName,
      contactLastName: jsonData?.lastName,
      contactEmailId: jsonData?.emailId,
      contactUuid: jsonData?.uuid,
    }

    return values
  }

  return null // Return null if data is not present in response
}

export const getAccessToken = (): string =>
  secureLocalStorage.getItem('token') as string

// JSON.parse(localStorage.getItem('okta-token-storage') ?? '')?.accessToken
//   ?.accessToken ?? ''

interface ResponseWithData {
  data: DynamicObj
}

interface ResponseWithResponseData {
  response: AxiosResponse<{
    message: string
  }>
}

interface HandleResponseData {
  response: ResponseWithData | ResponseWithResponseData
  dispatch: ThunkDispatch<RootState, any, any>
  onlySuccess?: boolean
  onlyFail?: boolean
}
export const handleResponse = ({
  response,
  dispatch,
  onlySuccess,
  onlyFail,
}: HandleResponseData) => {
  const isDataInResponse = 'data' in response && response.data
  const isResponseInResponse = 'response' in response && response.response
  const isResponseStatus = response?.response?.status

  // useEffect(() => {
  //   if (isResponseStatus === 403) {
  //     dispatch(LogoutRequest())
  //   }
  // }, [isResponseStatus])

  if (isDataInResponse && response?.data?.warn) {
    dispatch(
      showNotification({
        type: NotificationType.Warning,
        message: response.data.message,
      }),
    )
  } else {
    if (onlySuccess && isDataInResponse) {
      dispatch(
        showNotification({
          type: NotificationType.Success,
          message: response.data.message,
        }),
      )
    } else if (onlyFail && isResponseInResponse) {
      dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            response.response.data.message ??
            'Sorry, something went wrong there. Try again.',
        }),
      )
    } else if (!onlySuccess && !onlyFail) {
      if (isDataInResponse) {
        dispatch(
          showNotification({
            type: NotificationType.Success,
            message: response.data.message,
          }),
        )
      } else if (isResponseInResponse) {
        dispatch(
          showNotification({
            type: NotificationType.Error,
            message: response.response.data.message,
          }),
        )
      } else if (isResponseStatus === 403) {
        dispatch(
          showNotification({
            type: NotificationType.Error,
            message: response.response.data.message,
          }),
        )
        // dispatch(LogoutRequest())
      }
    }
  }
}

// type FieldType =
//   | 'text'
//   | 'email'
//   | 'number'
//   | 'radio'
//   | 'checkbox'
//   | 'phone'
//   | 'zip'
//   | 'alphabets'
//   | 'numeric'

interface ValidationResult {
  valid: boolean
  error: string
}

// Define validation functions (these are placeholders, you need to implement them)
export const validatePhoneNumber = (_value: string): ValidationResult => {
  // Validation logic for Phone number
  return { valid: true, error: '' } // Placeholder return value
}

export const validateZipCode = (_value: string): ValidationResult => {
  // Validation logic for zip code
  return { valid: true, error: '' } // Placeholder return value
}

export const validateAlphabetsOnly = (_value: string): ValidationResult => {
  // Validation logic for alphabets only
  return { valid: true, error: '' } // Placeholder return value
}

export const validateNumbersOnly = (_value: string): ValidationResult => {
  // Validation logic for numbers only
  return { valid: true, error: '' } // Placeholder return value
}
// export const validateInput = (
//   value: string,
//   fieldType: FieldType,
// ): ValidationResult => {
//   switch (fieldType) {
//     case 'text': {
//       if (!value.trim()) {
//         return { valid: false, error: 'This field is required' }
//       }
//       break
//     }

//     case 'email': {
//       const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
//       if (!emailRegex.test(value)) {
//         return { valid: false, error: 'Please enter a valid email address' }
//       }
//       break
//     }

//     case 'number': {
//       if (isNaN(Number(value))) {
//         return { valid: false, error: 'Please enter a valid number' }
//       }
//       break
//     }

//     case 'radio': {
//       if (!value) {
//         return { valid: false, error: 'Please select an option' }
//       }
//       break
//     }

//     case 'checkbox': {
//       if (!value) {
//         return { valid: false, error: 'Please check at least one option' }
//       }
//       break
//     }

//     case 'phone': {
//       const phoneRegex = /^\d{10}$/
//       if (!phoneRegex.test(value)) {
//         return {
//           valid: false,
//           error: 'Please enter a valid Phone number (10 digits)',
//         }
//       }
//       break
//     }
//     // Phone number validation (10 digits)

//     case 'zip': {
//       // Zip code validation (6 digits)
//       const zipRegex = /^\d{6}$/
//       if (!zipRegex.test(value)) {
//         return {
//           valid: false,
//           error: 'Please enter a valid zip code (till 6 digits)',
//         }
//       }
//       break
//     }

//     case 'alphabets': {
//       // Alphabets-only validation
//       const alphabetsRegex = /^[A-Za-z]+$/
//       if (!alphabetsRegex.test(value)) {
//         return { valid: false, error: 'Please enter only alphabets' }
//       }
//       break
//     }

//     case 'numeric': {
//       // Numbers-only validation
//       const numericRegex = /^\d+$/
//       if (!numericRegex.test(value)) {
//         return { valid: false, error: 'Please enter only numbers' }
//       }
//       break
//     }
//     default:
//       break
//   }

//   return { valid: true, error: '' }
// }

const validateText = (value: string): ValidationResult => {
  if (!value.trim()) {
    return { valid: false, error: 'This field is required' }
  }
  return { valid: true, error: '' }
}

// Validation function for email fields
const validateEmail = (value: string): ValidationResult => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  if (!emailRegex.test(value)) {
    return { valid: false, error: 'Please enter a valid email address' }
  }
  return { valid: true, error: '' }
}

// Validation function for number fields
const validateNumber = (value: string): ValidationResult => {
  if (isNaN(Number(value))) {
    return { valid: false, error: 'Please enter a valid number' }
  }
  return { valid: true, error: '' }
}

// Validation function for radio fields
const validateRadio = (value: string): ValidationResult => {
  if (!value) {
    return { valid: false, error: 'Please select an option' }
  }
  return { valid: true, error: '' }
}

// Validation function for checkbox fields
const validateCheckbox = (value: string): ValidationResult => {
  if (!value) {
    return { valid: false, error: 'Please check at least one option' }
  }
  return { valid: true, error: '' }
}

// Validation function for phone fields
const validatePhone = (value: string): ValidationResult => {
  const phoneRegex = /^\d{10}$/
  if (!phoneRegex.test(value)) {
    return {
      valid: false,
      error: 'Please enter a valid Phone number (10 digits)',
    }
  }
  return { valid: true, error: '' }
}

// Validation function for zip code fields
const validateZip = (value: string): ValidationResult => {
  const zipRegex = /^\d{5}$/
  if (!zipRegex.test(value)) {
    return { valid: false, error: 'Please enter a valid zip code (5 digits)' }
  }
  return { valid: true, error: '' }
}

// Validation function for alphabets-only fields
const validateAlphabets = (value: string): ValidationResult => {
  const alphabetsRegex = /^[A-Za-z]+$/
  if (!alphabetsRegex.test(value)) {
    return { valid: false, error: 'Please enter only alphabets' }
  }
  return { valid: true, error: '' }
}

// Validation function for numeric-only fields
const validateNumeric = (value: string): ValidationResult => {
  const numericRegex = /^\d+$/
  if (!numericRegex.test(value)) {
    return { valid: false, error: 'Please enter only numbers' }
  }
  return { valid: true, error: '' }
}

export const validateInput = (
  value: string,
  fieldType: string,
): ValidationResult => {
  switch (fieldType) {
    case 'text':
      if (!value.trim()) {
        return { valid: false, error: 'This field is required' }
      }
      return validateText(value)
    case 'email':
      if (!value.trim()) {
        return { valid: false, error: 'This field is required' }
      }
      return validateEmail(value)
    case 'number':
      if (!value.trim()) {
        return { valid: false, error: 'This field is required' }
      }
      return validateNumber(value)
    case 'radio':
      if (!value.trim()) {
        return { valid: false, error: 'Please select an option' }
      }
      return validateRadio(value)
    case 'checkbox':
      if (!value.trim()) {
        return { valid: false, error: 'Please check at least one option' }
      }
      return validateCheckbox(value)
    case 'phone':
      if (!value.trim()) {
        return { valid: false, error: 'This field is required' }
      }
      return validatePhone(value)
    case 'zip':
      if (!value.trim()) {
        return { valid: false, error: 'This field is required' }
      }
      return validateZip(value)
    case 'alphabets':
      if (!value.trim()) {
        return { valid: false, error: 'This field is required' }
      }
      return validateAlphabets(value)
    case 'numeric':
      if (!value.trim()) {
        return { valid: false, error: 'This field is required' }
      }
      return validateNumeric(value)
    default:
      return { valid: true, error: '' }
  }
}
export const MileStoneFour =
  process.env.REACT_APP_MILESTONE_4?.toLowerCase() === 'true'

export const HospitalAndMedicalType = {
  medicalDetailId: '',
  haveEmergencyRoomVisit: undefined,
  haveEmergencyRoomVisitMedicalFacility: [
    {
      hospitalName: '',
      address: {
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
      phoneNumber: '',
      arrivedDate: '',
      leftDate: '',
      typeOfTreatment: [],
      typeOfTherapyDoctorProvided: [],
    },
  ],
  haveMedicalFacility: undefined,
  haveMedicalFacilityMedicalFacility: [
    {
      hospitalName: '',
      address: {
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
      phoneNumber: '',
      arrivedDate: '',
      leftDate: '',
      typeOfTreatment: '',
    },
  ],
  haveMentalHeathTreatment: undefined,
  haveMentalHeathTreatmentMedicalFacility: [
    {
      hospitalName: '',
      address: {
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
      phoneNumber: '',
      arrivedDate: '',
      leftDate: '',
      typeOfTreatment: '',
    },
  ],
  haveInpatientOrOverNightStay: undefined,
  haveInpatientOrOverNightStayMedicalFacility: [
    {
      hospitalName: '',
      address: {
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
      phoneNumber: '',
      arrivedDate: '',
      leftDate: '',
      typeOfTreatment: '',
    },
  ],
  haveOutpatientOrSamedayVisit: undefined,
  haveOutpatientOrSamedayVisitMedicalFacility: [
    {
      hospitalName: '',
      address: {
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
      phoneNumber: '',
      arrivedDate: '',
      leftDate: '',
      typeOfTreatment: '',
    },
  ],
  haveSurgeries: undefined,
  haveSurgeriesMedicalFacility: [
    {
      hospitalName: '',
      address: {
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
      phoneNumber: '',
      arrivedDate: '',
      leftDate: '',
      typeOfTreatment: '',
    },
  ],
  havePhysicalTherapy: undefined,
  havePhysicalTherapyMedicalFacility: [
    {
      hospitalName: '',
      address: {
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
      phoneNumber: '',
      arrivedDate: '',
      leftDate: '',
      typeOfTreatment: '',
    },
  ],

  isDoctorInJail: undefined,
  jailDoctorDetail: [
    {
      organizationName: '',
      address: {
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
      phoneNumber: '',
      contactPersonFirstName: '',
      contactPersonLastName: '',
      arrivedDate: '',
      leftDate: '',
    },
  ],
  isPublicWelfare: undefined,
  welfareDetail: [
    {
      organizationName: '',
      address: {
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
      phoneNumber: '',
      contactPersonFirstName: '',
      contactPersonLastName: '',
      arrivedDate: '',
      leftDate: '',
    },
  ],
  isRecordHeldByAttorney: undefined,
  attorneyDetail: [
    {
      organizationName: '',
      address: {
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
      phoneNumber: '',
      contactPersonFirstName: '',
      contactPersonLastName: '',
      arrivedDate: '',
      leftDate: '',
    },
  ],
  haveDisabilityInsurance: undefined,
  insuranceDetail: [
    {
      organizationName: '',
      address: {
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
      phoneNumber: '',
      contactPersonFirstName: '',
      contactPersonLastName: '',
      arrivedDate: '',
      leftDate: '',
    },
  ],
  isVocationalRehabilitationService: undefined,
  rehabilitationOrganizationDetail: [
    {
      organizationName: '',
      address: {
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
      phoneNumber: '',
      contactPersonFirstName: '',
      contactPersonLastName: '',
      arrivedDate: '',
      leftDate: '',
    },
  ],
  isWorkersCompensation: undefined,
  workersCompensationDetail: [
    {
      organizationName: '',
      address: {
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
      phoneNumber: '',
      contactPersonFirstName: '',
      contactPersonLastName: '',
      arrivedDate: '',
      leftDate: '',
    },
  ],
  isOtherHaveMedicalRecords: undefined,
  otherOrganizationDetail: [
    {
      organizationName: '',
      address: {
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
      phoneNumber: '',
      contactPersonFirstName: '',
      contactPersonLastName: '',
      arrivedDate: '',
      leftDate: '',
    },
  ],
}

export const SymptomsOptions = [
  {
    name: 'Difficulty walking or moving limbs',
    value: 'Difficulty walking or moving limbs',
  },
  {
    name: 'Lack of balance',
    value: 'Lack of balance',
  },
  {
    name: 'Lack of grip strength',
    value: 'Lack of grip strength',
  },
  {
    name: 'Limited range of mobility',
    value: 'Limited range of mobility',
  },
  {
    name: 'Missing limbs',
    value: 'Missing limbs',
  },
  {
    name: 'Muscle spasms',
    value: 'Muscle spasms',
  },
  {
    name: 'Numbness',
    value: 'Numbness',
  },
  {
    name: 'Pain (e.g., cramping, stabbing, or aching)',
    value: 'Pain (e.g., cramping, stabbing, or aching)',
  },
  {
    name: 'Paralysis',
    value: 'Paralysis',
  },
  {
    name: 'Required to use a wheelchair, walker, or crutches',
    value: 'Required to use a wheelchair, walker, or crutches',
  },
  {
    name: 'Stiffness',
    value: 'Stiffness',
  },
  {
    name: 'Swelling',
    value: 'Swelling',
  },
  {
    name: 'Tingling',
    value: 'Tingling',
  },
  {
    name: 'Tremors or shakiness',
    value: 'Tremors or shakiness',
  },
  {
    name: 'Weakness',
    value: 'Weakness',
  },
  {
    name: 'None of the above',
    value: 'None of the above',
  },
]

export const FatigueOptions = [
  {
    name: 'Fatigue',
    value: 'Fatigue',
  },
  {
    name: 'Trouble sleeping or insomnia',
    value: 'Trouble sleeping or insomnia',
  },
  {
    name: 'Lack of stamina',
    value: 'Lack of stamina',
  },
  {
    name: 'None of the above',
    value: 'None of the above',
  },
]

export const FeverOptions = [
  {
    name: 'Fever',
    value: 'Fever',
  },
  {
    name: 'Sensitivity to heat',
    value: 'Sensitivity to heat',
  },
  {
    name: 'Skin problems (e.g., severe dry skin, sores, etc.)',
    value: 'Skin problems (e.g., severe dry skin, sores, etc.)',
  },
  {
    name: 'None of the above',
    value: 'None of the above',
  },
]

export const RespiratoryOptions = [
  {
    name: 'Shortness of breath',
    value: 'Shortness of breath',
  },
  {
    name: 'Tightness in chest (i.e., respiratory or cardiac)',
    value: 'Tightness in chest (i.e., respiratory or cardiac)',
  },
  {
    name: 'Difficulty swallowing',
    value: 'Difficulty swallowing',
  },
  {
    name: 'Coughing spells',
    value: 'Coughing spells',
  },
  {
    name: 'Irregular heartbeat',
    value: 'Irregular heartbeat',
  },
  {
    name: 'None of the above',
    value: 'None of the above',
  },
]

export const SpeechOptions = [
  {
    name: 'Difficulty speaking',
    value: 'Difficulty speaking',
  },
  {
    name: 'Stuttering',
    value: 'Stuttering',
  },
  {
    name: 'Slurred speech',
    value: 'Slurred speech',
  },
  {
    name: 'None of the above',
    value: 'None of the above',
  },
]

export const MemoryAndCognitionOptions = [
  {
    name: 'Short-term memory loss',
    value: 'Short-term memory loss',
  },
  {
    name: 'Long-term memory loss',
    value: 'Long-term memory loss',
  },
  {
    name: 'Difficulty understanding',
    value: 'Difficulty understanding',
  },
  {
    name: 'Difficulty following instructions',
    value: 'Difficulty following instructions',
  },
  {
    name: 'Trouble with concentration / thought process',
    value: 'Trouble with concentration / thought process',
  },
  {
    name: 'None of the above',
    value: 'None of the above',
  },
]

export const MentalHealthOptions = [
  {
    name: 'Anxiety',
    value: 'Anxiety',
  },
  {
    name: 'Depression',
    value: 'Depression',
  },
  {
    name: 'Flashbacks',
    value: 'Flashbacks',
  },
  {
    name: 'Irritability',
    value: 'Irritability',
  },
  {
    name: 'Mood swings',
    value: 'Mood swings',
  },
  {
    name: 'Nightmares',
    value: 'Nightmares',
  },
  {
    name: 'Obsessive behavior or thoughts',
    value: 'Obsessive behavior or thoughts',
  },
  {
    name: 'Panic attacks',
    value: 'Panic attacks',
  },
  {
    name: 'Paranoia',
    value: 'Paranoia',
  },
  {
    name: 'Auditory hallucinations',
    value: 'Auditory hallucinations',
  },
  {
    name: 'Visual hallucinations',
    value: 'Visual hallucinations',
  },
  {
    name: 'None of the above',
    value: 'None of the above',
  },
]

export const DigestiveAndExcretoryOptions = [
  {
    name: 'Diarrhea',
    value: 'Diarrhea',
  },
  {
    name: 'Vomiting',
    value: 'Vomiting',
  },
  {
    name: 'Bowel incontinence',
    value: 'Bowel incontinence',
  },
  {
    name: 'Nausea',
    value: 'Nausea',
  },
  {
    name: 'Sensitivity to chemicals',
    value: 'Sensitivity to chemicals',
  },
  {
    name: 'Constipation',
    value: 'Constipation',
  },
  {
    name: 'Urinary incontinence',
    value: 'Urinary incontinence',
  },
  {
    name: 'Frequent urination',
    value: 'Frequent urination',
  },
  {
    name: 'None of the above',
    value: 'None of the above',
  },
]

export const NeurologicalOptions = [
  {
    name: 'Dizziness',
    value: 'Dizziness',
  },
  {
    name: 'Fainting spells',
    value: 'Fainting spells',
  },
  {
    name: 'Headaches',
    value: 'Headaches',
  },
  {
    name: 'Lightheadedness',
    value: 'Lightheadedness',
  },
  {
    name: 'Migraines',
    value: 'Migraines',
  },
  {
    name: 'None of the above',
    value: 'None of the above',
  },
]

export const IllnessesInjuriesCondition = [
  {
    name: 'Abdominal aortic aneurysm',
    value: 'Abdominal aortic aneurysm',
  },
  {
    name: 'Acne',
    value: 'Acne',
  },
  {
    name: 'Acute cholecystitis',
    value: 'Acute cholecystitis',
  },
  {
    name: 'Acute lymphoblastic leukaemia',
    value: 'Acute lymphoblastic leukaemia',
  },
  {
    name: 'Acute myeloid leukaemia',
    value: 'Acute myeloid leukaemia',
  },
  {
    name: 'Acute pancreatitis',
    value: 'Acute pancreatitis',
  },
  {
    name: "Addison's disease",
    value: "Addison's disease",
  },
  {
    name: 'Alcohol-related liver disease',
    value: 'Alcohol-related liver disease',
  },
  {
    name: 'Allergic rhinitis',
    value: 'Allergic rhinitis',
  },
  {
    name: 'Allergies',
    value: 'Allergies',
  },
  {
    name: "Alzheimer's disease",
    value: "Alzheimer's disease",
  },
  {
    name: 'Anal cancer',
    value: 'Anal cancer',
  },
  {
    name: 'Anaphylaxis',
    value: 'Anaphylaxis',
  },
  {
    name: 'Angioedema',
    value: 'Angioedema',
  },
  {
    name: 'Ankylosing spondylitis',
    value: 'Ankylosing spondylitis',
  },
  {
    name: 'Anorexia nervosa',
    value: 'Anorexia nervosa',
  },
  {
    name: 'Anxiety',
    value: 'Anxiety',
  },
  {
    name: 'Appendicitis',
    value: 'Appendicitis',
  },
  {
    name: 'Arthritis',
    value: 'Arthritis',
  },
  {
    name: 'Asbestosis',
    value: 'Asbestosis',
  },
  {
    name: 'Asthma',
    value: 'Asthma',
  },
  {
    name: 'Atopic eczema',
    value: 'Atopic eczema',
  },
  {
    name: 'Attention deficit hyperactivity disorder (ADHD)',
    value: 'Attention deficit hyperactivity disorder (ADHD)',
  },
  {
    name: 'Autistic spectrum disorder (ASD)',
    value: 'Autistic spectrum disorder (ASD)',
  },
  {
    name: 'Bacterial vaginosis',
    value: 'Bacterial vaginosis',
  },
  {
    name: 'Benign prostate enlargement',
    value: 'Benign prostate enlargement',
  },
  {
    name: 'Bile duct cancer (cholangiocarcinoma)',
    value: 'Bile duct cancer (cholangiocarcinoma)',
  },
  {
    name: 'Binge eating',
    value: 'Binge eating',
  },
  {
    name: 'Bipolar disorder',
    value: 'Bipolar disorder',
  },
  {
    name: 'Bladder cancer',
    value: 'Bladder cancer',
  },
  {
    name: 'Blood poisoning (sepsis)',
    value: 'Blood poisoning (sepsis)',
  },
  {
    name: 'Bone cancer',
    value: 'Bone cancer',
  },
  {
    name: 'Bowel cancer',
    value: 'Bowel cancer',
  },
  {
    name: 'Bowel incontinence',
    value: 'Bowel incontinence',
  },
  {
    name: 'Bowel polyps',
    value: 'Bowel polyps',
  },
  {
    name: 'Brain stem death',
    value: 'Brain stem death',
  },
  {
    name: 'Brain tumours',
    value: 'Brain tumours',
  },
  {
    name: 'Breast cancer (female)',
    value: 'Breast cancer (female)',
  },
  {
    name: 'Breast cancer (male)',
    value: 'Breast cancer (male)',
  },
  {
    name: 'Bronchiectasis',
    value: 'Bronchiectasis',
  },
  {
    name: 'Bronchitis',
    value: 'Bronchitis',
  },
  {
    name: 'Bulimia',
    value: 'Bulimia',
  },
  {
    name: 'Bunion',
    value: 'Bunion',
  },
  {
    name: 'Carcinoid syndrome and carcinoid tumours',
    value: 'Carcinoid syndrome and carcinoid tumours',
  },
  {
    name: 'Catarrh',
    value: 'Catarrh',
  },
  {
    name: 'Cellulitis',
    value: 'Cellulitis',
  },
  {
    name: 'Cervical cancer',
    value: 'Cervical cancer',
  },
  {
    name: 'Chest infection',
    value: 'Chest infection',
  },
  {
    name: 'Chest pain',
    value: 'Chest pain',
  },
  {
    name: 'Chickenpox',
    value: 'Chickenpox',
  },
  {
    name: 'Chilblains',
    value: 'Chilblains',
  },
  {
    name: 'Chlamydia',
    value: 'Chlamydia',
  },
  {
    name: 'Chronic fatigue syndrome',
    value: 'Chronic fatigue syndrome',
  },
  {
    name: 'Chronic kidney disease',
    value: 'Chronic kidney disease',
  },
  {
    name: 'Chronic lymphocytic leukaemia',
    value: 'Chronic lymphocytic leukaemia',
  },
  {
    name: 'Chronic myeloid leukaemia',
    value: 'Chronic myeloid leukaemia',
  },
  {
    name: 'Chronic obstructive pulmonary disease',
    value: 'Chronic obstructive pulmonary disease',
  },
  {
    name: 'Chronic pain',
    value: 'Chronic pain',
  },
  {
    name: 'Chronic pancreatitis',
    value: 'Chronic pancreatitis',
  },
  {
    name: 'Cirrhosis',
    value: 'Cirrhosis',
  },
  {
    name: 'Clostridium difficile',
    value: 'Clostridium difficile',
  },
  {
    name: 'Coeliac disease',
    value: 'Coeliac disease',
  },
  {
    name: 'Cold sore',
    value: 'Cold sore',
  },
  {
    name: 'Coma',
    value: 'Coma',
  },
  {
    name: 'Common cold',
    value: 'Common cold',
  },
  {
    name: 'Common heart conditions',
    value: 'Common heart conditions',
  },
  {
    name: 'Congenital heart disease',
    value: 'Congenital heart disease',
  },
  {
    name: 'Conjunctivitis',
    value: 'Conjunctivitis',
  },
  {
    name: 'Constipation',
    value: 'Constipation',
  },
  {
    name: 'Coronavirus (COVID-19)',
    value: 'Coronavirus (COVID-19)',
  },
  {
    name: 'Cough',
    value: 'Cough',
  },
  {
    name: "Crohn's disease",
    value: "Crohn's disease",
  },
  {
    name: 'Croup',
    value: 'Croup',
  },
  {
    name: 'Cystic fibrosis',
    value: 'Cystic fibrosis',
  },
  {
    name: 'Cystitis',
    value: 'Cystitis',
  },
  {
    name: 'Deafblindness',
    value: 'Deafblindness',
  },
  {
    name: 'Deep vein thrombosis',
    value: 'Deep vein thrombosis',
  },
  {
    name: 'Dehydration',
    value: 'Dehydration',
  },
  {
    name: 'Dementia',
    value: 'Dementia',
  },
  {
    name: 'Dementia with Lewy bodies',
    value: 'Dementia with Lewy bodies',
  },
  {
    name: 'Dental abscess',
    value: 'Dental abscess',
  },
  {
    name: 'Depression',
    value: 'Depression',
  },
  {
    name: 'Dermatitis herpetiformis',
    value: 'Dermatitis herpetiformis',
  },
  {
    name: 'Diabetes',
    value: 'Diabetes',
  },
  {
    name: 'Diarrhoea',
    value: 'Diarrhoea',
  },
  {
    name: 'Discoid eczema',
    value: 'Discoid eczema',
  },
  {
    name: 'Diverticular disease and diverticulitis',
    value: 'Diverticular disease and diverticulitis',
  },
  {
    name: 'Dizziness (Lightheadedness)',
    value: 'Dizziness (Lightheadedness)',
  },
  {
    name: "Down's syndrome",
    value: "Down's syndrome",
  },
  {
    name: 'Dry mouth',
    value: 'Dry mouth',
  },
  {
    name: 'Dysphagia (swallowing problems)',
    value: 'Dysphagia (swallowing problems)',
  },
  {
    name: 'Dystonia',
    value: 'Dystonia',
  },
  {
    name: 'Earache',
    value: 'Earache',
  },
  {
    name: 'Earwax build-up',
    value: 'Earwax build-up',
  },
  {
    name: 'Ebola virus disease',
    value: 'Ebola virus disease',
  },
  {
    name: 'Ectopic pregnancy',
    value: 'Ectopic pregnancy',
  },
  {
    name: "Edwards' syndrome",
    value: "Edwards' syndrome",
  },
  {
    name: 'Endometriosis',
    value: 'Endometriosis',
  },
  {
    name: 'Epilepsy',
    value: 'Epilepsy',
  },
  {
    name: 'Erectile dysfunction (impotence)',
    value: 'Erectile dysfunction (impotence)',
  },
  {
    name: 'Escherichia coli (E. coli) O157',
    value: 'Escherichia coli (E. coli) O157',
  },
  {
    name: 'Ewing sarcoma',
    value: 'Ewing sarcoma',
  },
  {
    name: 'Eye cancer',
    value: 'Eye cancer',
  },
  {
    name: 'Febrile seizures',
    value: 'Febrile seizures',
  },
  {
    name: 'Fever',
    value: 'Fever',
  },
  {
    name: 'Fibroids',
    value: 'Fibroids',
  },
  {
    name: 'Fibromyalgia',
    value: 'Fibromyalgia',
  },
  {
    name: 'Flatulence',
    value: 'Flatulence',
  },
  {
    name: 'Flu',
    value: 'Flu',
  },
  {
    name: 'Foetal alcohol syndrome',
    value: 'Foetal alcohol syndrome',
  },
  {
    name: 'Food poisoning',
    value: 'Food poisoning',
  },
  {
    name: 'Functional neurological disorder (FND)',
    value: 'Functional neurological disorder (FND)',
  },
  {
    name: 'Fungal nail infection',
    value: 'Fungal nail infection',
  },
  {
    name: 'Gallbladder cancer',
    value: 'Gallbladder cancer',
  },
  {
    name: 'Gallstones',
    value: 'Gallstones',
  },
  {
    name: 'Ganglion cyst',
    value: 'Ganglion cyst',
  },
  {
    name: 'Gastroenteritis',
    value: 'Gastroenteritis',
  },
  {
    name: 'Gastro-oesophageal reflux disease (GORD)',
    value: 'Gastro-oesophageal reflux disease (GORD)',
  },
  {
    name: 'Genital herpes',
    value: 'Genital herpes',
  },
  {
    name: 'Genital warts',
    value: 'Genital warts',
  },
  {
    name: 'Germ cell tumours',
    value: 'Germ cell tumours',
  },
  {
    name: 'Glandular fever',
    value: 'Glandular fever',
  },
  {
    name: 'Gonorrhoea',
    value: 'Gonorrhoea',
  },
  {
    name: 'Gout',
    value: 'Gout',
  },
  {
    name: 'Gum disease',
    value: 'Gum disease',
  },
  {
    name: 'Haemorrhoids (piles)',
    value: 'Haemorrhoids (piles)',
  },
  {
    name: 'Hand, foot and mouth disease',
    value: 'Hand, foot and mouth disease',
  },
  {
    name: 'Hay fever',
    value: 'Hay fever',
  },
  {
    name: 'Head and neck cancer',
    value: 'Head and neck cancer',
  },
  {
    name: 'Head lice and nits',
    value: 'Head lice and nits',
  },
  {
    name: 'Headaches',
    value: 'Headaches',
  },
  {
    name: 'Hearing loss',
    value: 'Hearing loss',
  },
  {
    name: 'Heart failure',
    value: 'Heart failure',
  },
  {
    name: 'Hepatitis A',
    value: 'Hepatitis A',
  },
  {
    name: 'Hepatitis B',
    value: 'Hepatitis B',
  },
  {
    name: 'Hepatitis C',
    value: 'Hepatitis C',
  },
  {
    name: 'Hiatus hernia',
    value: 'Hiatus hernia',
  },
  {
    name: 'High cholesterol',
    value: 'High cholesterol',
  },
  {
    name: 'HIV',
    value: 'HIV',
  },
  {
    name: 'Hodgkin lymphoma',
    value: 'Hodgkin lymphoma',
  },
  {
    name: "Huntington's disease",
    value: "Huntington's disease",
  },
  {
    name: 'Hyperglycaemia (high blood sugar)',
    value: 'Hyperglycaemia (high blood sugar)',
  },
  {
    name: 'Hyperhidrosis',
    value: 'Hyperhidrosis',
  },
  {
    name: 'Hypoglycaemia (low blood sugar)',
    value: 'Hypoglycaemia (low blood sugar)',
  },
  {
    name: 'Idiopathic pulmonary fibrosis',
    value: 'Idiopathic pulmonary fibrosis',
  },
  {
    name: 'Impetigo',
    value: 'Impetigo',
  },
  {
    name: 'Indigestion',
    value: 'Indigestion',
  },
  {
    name: 'Ingrown toenail',
    value: 'Ingrown toenail',
  },
  {
    name: 'Inherited heart conditions',
    value: 'Inherited heart conditions',
  },
  {
    name: 'Insomnia',
    value: 'Insomnia',
  },
  {
    name: 'Iron deficiency anaemia',
    value: 'Iron deficiency anaemia',
  },
  {
    name: 'Irritable bowel syndrome (IBS)',
    value: 'Irritable bowel syndrome (IBS)',
  },
  {
    name: 'Irritable hip',
    value: 'Irritable hip',
  },
  {
    name: 'Itching',
    value: 'Itching',
  },
  {
    name: 'Itchy bottom',
    value: 'Itchy bottom',
  },
  {
    name: "Kaposi's sarcoma",
    value: "Kaposi's sarcoma",
  },
  {
    name: 'Kidney cancer',
    value: 'Kidney cancer',
  },
  {
    name: 'Kidney infection',
    value: 'Kidney infection',
  },
  {
    name: 'Kidney stones',
    value: 'Kidney stones',
  },
  {
    name: 'Labyrinthitis',
    value: 'Labyrinthitis',
  },
  {
    name: 'Lactose intolerance',
    value: 'Lactose intolerance',
  },
  {
    name: 'Laryngeal (larynx) cancer',
    value: 'Laryngeal (larynx) cancer',
  },
  {
    name: 'Laryngitis',
    value: 'Laryngitis',
  },
  {
    name: 'Leg cramps',
    value: 'Leg cramps',
  },
  {
    name: 'Lichen planus',
    value: 'Lichen planus',
  },
  {
    name: 'Liver cancer',
    value: 'Liver cancer',
  },
  {
    name: 'Liver disease',
    value: 'Liver disease',
  },
  {
    name: 'Liver tumours',
    value: 'Liver tumours',
  },
  {
    name: 'Loss of libido',
    value: 'Loss of libido',
  },
  {
    name: 'Lung cancer',
    value: 'Lung cancer',
  },
  {
    name: 'Lupus',
    value: 'Lupus',
  },
  {
    name: 'Lyme disease',
    value: 'Lyme disease',
  },
  {
    name: 'Lymphoedema',
    value: 'Lymphoedema',
  },
  {
    name: 'Lymphogranuloma venereum (LGV)',
    value: 'Lymphogranuloma venereum (LGV)',
  },
  {
    name: 'Malaria',
    value: 'Malaria',
  },
  {
    name: 'Malignant brain tumour (cancerous)',
    value: 'Malignant brain tumour (cancerous)',
  },
  {
    name: 'Malnutrition',
    value: 'Malnutrition',
  },
  {
    name: 'Measles',
    value: 'Measles',
  },
  {
    name: 'Meningitis',
    value: 'Meningitis',
  },
  {
    name: 'Menopause',
    value: 'Menopause',
  },
  {
    name: 'Mesothelioma',
    value: 'Mesothelioma',
  },
  {
    name: 'Middle ear infection (otitis media)',
    value: 'Middle ear infection (otitis media)',
  },
  {
    name: 'Migraine',
    value: 'Migraine',
  },
  {
    name: 'Miscarriage',
    value: 'Miscarriage',
  },
  {
    name: 'Motor neurone disease (MND)',
    value: 'Motor neurone disease (MND)',
  },
  {
    name: 'Mouth cancer',
    value: 'Mouth cancer',
  },
  {
    name: 'Mouth ulcer',
    value: 'Mouth ulcer',
  },
  {
    name: 'Multiple myeloma',
    value: 'Multiple myeloma',
  },
  {
    name: 'Multiple sclerosis (MS)',
    value: 'Multiple sclerosis (MS)',
  },
  {
    name: 'Mumps',
    value: 'Mumps',
  },
  {
    name: "Meniere's disease",
    value: "Meniere's disease",
  },
  {
    name: 'Myasthenia gravis',
    value: 'Myasthenia gravis',
  },
  {
    name: 'Nasal and sinus cancer',
    value: 'Nasal and sinus cancer',
  },
  {
    name: 'Nasopharyngeal cancer',
    value: 'Nasopharyngeal cancer',
  },
  {
    name: 'Neuroendocrine tumours',
    value: 'Neuroendocrine tumours',
  },
  {
    name: 'Non-alcoholic fatty liver disease (NAFLD)',
    value: 'Non-alcoholic fatty liver disease (NAFLD)',
  },
  {
    name: 'Non-Hodgkin lymphoma',
    value: 'Non-Hodgkin lymphoma',
  },
  {
    name: 'Norovirus',
    value: 'Norovirus',
  },
  {
    name: 'Nosebleed',
    value: 'Nosebleed',
  },
  {
    name: 'Obesity',
    value: 'Obesity',
  },
  {
    name: 'Obsessive compulsive disorder (OCD)',
    value: 'Obsessive compulsive disorder (OCD)',
  },
  {
    name: 'Obstructive sleep apnoea',
    value: 'Obstructive sleep apnoea',
  },
  {
    name: 'Oesophageal cancer',
    value: 'Oesophageal cancer',
  },
  {
    name: 'Oral thrush',
    value: 'Oral thrush',
  },
  {
    name: 'Osteoarthritis',
    value: 'Osteoarthritis',
  },
  {
    name: 'Osteoporosis',
    value: 'Osteoporosis',
  },
  {
    name: 'Osteosarcoma',
    value: 'Osteosarcoma',
  },
  {
    name: 'Otitis externa',
    value: 'Otitis externa',
  },
  {
    name: 'Ovarian cancer',
    value: 'Ovarian cancer',
  },
  {
    name: 'Ovarian cyst',
    value: 'Ovarian cyst',
  },
  {
    name: 'Overactive thyroid',
    value: 'Overactive thyroid',
  },
  {
    name: "Paget's disease of the nipple",
    value: "Paget's disease of the nipple",
  },
  {
    name: 'Pancreatic cancer',
    value: 'Pancreatic cancer',
  },
  {
    name: 'Panic disorder',
    value: 'Panic disorder',
  },
  {
    name: "Parkinson's disease",
    value: "Parkinson's disease",
  },
  {
    name: "Patau's syndrome",
    value: "Patau's syndrome",
  },
  {
    name: 'Pelvic inflammatory disease',
    value: 'Pelvic inflammatory disease',
  },
  {
    name: 'Pelvic organ prolapse',
    value: 'Pelvic organ prolapse',
  },
  {
    name: 'Penile cancer',
    value: 'Penile cancer',
  },
  {
    name: 'Peripheral neuropathy',
    value: 'Peripheral neuropathy',
  },
  {
    name: 'Personality disorder',
    value: 'Personality disorder',
  },
  {
    name: 'Pleurisy',
    value: 'Pleurisy',
  },
  {
    name: 'Pneumonia',
    value: 'Pneumonia',
  },
  {
    name: 'Polymyalgia rheumatica',
    value: 'Polymyalgia rheumatica',
  },
  {
    name: 'Post-polio syndrome',
    value: 'Post-polio syndrome',
  },
  {
    name: 'Post-traumatic stress disorder (PTSD)',
    value: 'Post-traumatic stress disorder (PTSD)',
  },
  {
    name: 'Postnatal depression',
    value: 'Postnatal depression',
  },
  {
    name: 'Pregnancy and baby',
    value: 'Pregnancy and baby',
  },
  {
    name: 'Pressure ulcers',
    value: 'Pressure ulcers',
  },
  {
    name: 'Prostate cancer',
    value: 'Prostate cancer',
  },
  {
    name: 'Psoriasis',
    value: 'Psoriasis',
  },
  {
    name: 'Psoriatic arthritis',
    value: 'Psoriatic arthritis',
  },
  {
    name: 'Psychosis',
    value: 'Psychosis',
  },
  {
    name: 'Pubic lice',
    value: 'Pubic lice',
  },
  {
    name: 'Rare tumours',
    value: 'Rare tumours',
  },
  {
    name: "Raynaud's phenomenon",
    value: "Raynaud's phenomenon",
  },
  {
    name: 'Reactive arthritis',
    value: 'Reactive arthritis',
  },
  {
    name: 'Restless legs syndrome',
    value: 'Restless legs syndrome',
  },
  {
    name: 'Rhabdomyosarcoma',
    value: 'Rhabdomyosarcoma',
  },
  {
    name: 'Rheumatoid arthritis',
    value: 'Rheumatoid arthritis',
  },
  {
    name: 'Ringworm and other fungal infections',
    value: 'Ringworm and other fungal infections',
  },
  {
    name: 'Rosacea',
    value: 'Rosacea',
  },
  {
    name: 'Scabies',
    value: 'Scabies',
  },
  {
    name: 'Scarlet fever',
    value: 'Scarlet fever',
  },
  {
    name: 'Schizophrenia',
    value: 'Schizophrenia',
  },
  {
    name: 'Scoliosis',
    value: 'Scoliosis',
  },
  {
    name: 'Septic shock',
    value: 'Septic shock',
  },
  {
    name: 'Shingles',
    value: 'Shingles',
  },
  {
    name: 'Shortness of breath',
    value: 'Shortness of breath',
  },
  {
    name: 'Sickle cell disease',
    value: 'Sickle cell disease',
  },
  {
    name: 'Sinusitis',
    value: 'Sinusitis',
  },
  {
    name: "Sjogren's syndrome",
    value: "Sjogren's syndrome",
  },
  {
    name: 'Skin cancer (melanoma)',
    value: 'Skin cancer (melanoma)',
  },
  {
    name: 'Skin cancer (non-melanoma)',
    value: 'Skin cancer (non-melanoma)',
  },
  {
    name: 'Slapped cheek syndrome',
    value: 'Slapped cheek syndrome',
  },
  {
    name: 'Soft tissue sarcomas',
    value: 'Soft tissue sarcomas',
  },
  {
    name: 'Sore throat',
    value: 'Sore throat',
  },
  {
    name: 'Spleen problems and spleen removal',
    value: 'Spleen problems and spleen removal',
  },
  {
    name: 'Stillbirth',
    value: 'Stillbirth',
  },
  {
    name: 'Stomach ache and abdominal pain',
    value: 'Stomach ache and abdominal pain',
  },
  {
    name: 'Stomach cancer',
    value: 'Stomach cancer',
  },
  {
    name: 'Stomach ulcer',
    value: 'Stomach ulcer',
  },
  {
    name: 'Streptococcus A (strep A)',
    value: 'Streptococcus A (strep A)',
  },
  {
    name: 'Stress, anxiety and low mood',
    value: 'Stress, anxiety and low mood',
  },
  {
    name: 'Stroke',
    value: 'Stroke',
  },
  {
    name: 'Sudden infant death syndrome (SIDS)',
    value: 'Sudden infant death syndrome (SIDS)',
  },
  {
    name: 'Suicide',
    value: 'Suicide',
  },
  {
    name: 'Sunburn',
    value: 'Sunburn',
  },
  {
    name: 'Swollen glands',
    value: 'Swollen glands',
  },
  {
    name: 'Syphilis',
    value: 'Syphilis',
  },
  {
    name: 'Testicular cancer',
    value: 'Testicular cancer',
  },
  {
    name: 'Testicular lumps and swellings',
    value: 'Testicular lumps and swellings',
  },
  {
    name: 'Thirst',
    value: 'Thirst',
  },
  {
    name: 'Threadworms',
    value: 'Threadworms',
  },
  {
    name: 'Thrush',
    value: 'Thrush',
  },
  {
    name: 'Thyroid cancer',
    value: 'Thyroid cancer',
  },
  {
    name: 'Tinnitus',
    value: 'Tinnitus',
  },
  {
    name: 'Tonsillitis',
    value: 'Tonsillitis',
  },
  {
    name: 'Tooth decay',
    value: 'Tooth decay',
  },
  {
    name: 'Toothache',
    value: 'Toothache',
  },
  {
    name: 'Transient ischaemic attack (TIA)',
    value: 'Transient ischaemic attack (TIA)',
  },
  {
    name: 'Trigeminal neuralgia',
    value: 'Trigeminal neuralgia',
  },
  {
    name: 'Tuberculosis (TB)',
    value: 'Tuberculosis (TB)',
  },
  {
    name: 'Type 1 diabetes',
    value: 'Type 1 diabetes',
  },
  {
    name: 'Type 2 diabetes',
    value: 'Type 2 diabetes',
  },
  {
    name: 'Trichomonas infection',
    value: 'Trichomonas infection',
  },
  {
    name: 'Transverse myelitis',
    value: 'Transverse myelitis',
  },
  {
    name: 'Ulcerative colitis',
    value: 'Ulcerative colitis',
  },
  {
    name: 'Underactive thyroid',
    value: 'Underactive thyroid',
  },
  {
    name: 'Urinary incontinence',
    value: 'Urinary incontinence',
  },
  {
    name: 'Urinary tract infection (UTI)',
    value: 'Urinary tract infection (UTI)',
  },
  {
    name: 'Urticaria (hives)',
    value: 'Urticaria (hives)',
  },
  {
    name: 'Vaginal cancer',
    value: 'Vaginal cancer',
  },
  {
    name: 'Varicose eczema',
    value: 'Varicose eczema',
  },
  {
    name: 'Venous leg ulcer',
    value: 'Venous leg ulcer',
  },
  {
    name: 'Vertigo',
    value: 'Vertigo',
  },
  {
    name: 'Vitamin B12 or folate deficiency anaemia',
    value: 'Vitamin B12 or folate deficiency anaemia',
  },
  {
    name: 'Vomiting',
    value: 'Vomiting',
  },
  {
    name: 'Vulval cancer',
    value: 'Vulval cancer',
  },
  {
    name: 'Warts and verrucas',
    value: 'Warts and verrucas',
  },
  {
    name: 'Whooping cough',
    value: 'Whooping cough',
  },
  {
    name: "Wilms' tumour",
    value: "Wilms' tumour",
  },
  {
    name: 'Womb (uterus) cancer',
    value: 'Womb (uterus) cancer',
  },
  {
    name: 'Yellow fever',
    value: 'Yellow fever',
  },
  {
    name: 'Other',
    value: 'Other',
  },
]

export const TreatmentOptions = [
  {
    name: 'Assistive device',
    value: 'Assistive device',
  },
  {
    name: 'Consultation',
    value: 'Consultation',
  },
  {
    name: 'Mental evaluation',
    value: 'Mental evaluation',
  },
  {
    name: 'Medical tests',
    value: 'Medical tests',
  },
  {
    name: 'Physical examination',
    value: 'Physical examination',
  },
  {
    name: 'Prescribes medication',
    value: 'Prescribes medication',
  },
  {
    name: 'Special procedures (e.g., steroid injections)',
    value: 'Special procedures (e.g., steroid injections)',
  },
  {
    name: 'Surgery',
    value: 'Surgery',
  },
  {
    name: 'Therapy',
    value: 'Therapy',
  },
  {
    name: 'Other',
    value: 'Other',
  },
]

export const Therapies = [
  {
    name: 'Aquatic therapy',
    value: 'Aquatic therapy',
  },
  {
    name: 'Mental health therapy',
    value: 'Mental health therapy',
  },
  {
    name: 'Occupational therapy',
    value: 'Occupational therapy',
  },
  {
    name: 'Physical therapy',
    value: 'Physical therapy',
  },
  {
    name: 'Speech therapy',
    value: 'Speech therapy',
  },
  {
    name: 'Other',
    value: 'Other',
  },
]

export const MedicalTest = [
  { name: 'A1C', value: 'A1C' },
  { name: 'Amniocentesis', value: 'Amniocentesis' },
  { name: 'Angiocardiography', value: 'Angiocardiography' },
  { name: 'Angiography', value: 'Angiography' },
  { name: 'Auscultation', value: 'Auscultation' },
  { name: 'Ballistocardiography', value: 'Ballistocardiography' },
  { name: 'Biopsy', value: 'Biopsy' },
  { name: 'Blood Tests (Non Hiv)', value: 'Blood Tests (Non Hiv)' },
  { name: 'Brain Scanning', value: 'Brain Scanning' },
  { name: 'Breathing Test', value: 'Breathing Test' },
  { name: 'Bronchoscopy', value: 'Bronchoscopy' },
  { name: 'Cardiac Catheterization', value: 'Cardiac Catheterization' },
  { name: 'CAT Scans', value: 'CAT Scans' },
  { name: 'Cholecystography', value: 'Cholecystography' },
  { name: 'Chorionic Villi Sampling ', value: 'Chorionic Villi Sampling ' },
  { name: 'Colonoscopy', value: 'Colonoscopy' },
  { name: 'Colposcopy', value: 'Colposcopy' },
  { name: 'Complementation Test', value: 'Complementation Test' },
  { name: 'Covid-19 Testing', value: 'Covid-19 Testing' },
  { name: 'CT Scans', value: 'CT Scans' },
  { name: 'Diagnostic Imaging', value: 'Diagnostic Imaging' },
  { name: 'Echocardiography', value: 'Echocardiography' },
  {
    name: 'Electrocardiography (ECG Or EKG)',
    value: 'Electrocardiography (ECG Or EKG)',
  },
  {
    name: 'Electroencephalography (EEG)',
    value: 'Electroencephalography (EEG)',
  },
  { name: 'Electromyography (EMG)', value: 'Electromyography (EMG)' },
  {
    name: 'Endoscopic Retrograde Cholangiopancreatoscopy',
    value: 'Endoscopic Retrograde Cholangiopancreatoscopy',
  },
  { name: 'Endoscopy', value: 'Endoscopy' },
  {
    name: 'Esophagogastroduodenoscopy (EGD) ',
    value: 'Esophagogastroduodenoscopy (EGD) ',
  },
  {
    name: 'Exercise Tests (Including Treadmill) ',
    value: 'Exercise Tests (Including Treadmill) ',
  },
  { name: 'Fetal Ultrasound ', value: 'Fetal Ultrasound ' },
  {
    name: 'Fluorescence In Situ Hybridization',
    value: 'Fluorescence In Situ Hybridization',
  },
  { name: 'Gastric Fluid Analysis', value: 'Gastric Fluid Analysis' },
  { name: 'Genetic Testing', value: 'Genetic Testing' },
  { name: 'Gynecological Examination', value: 'Gynecological Examination' },
  { name: 'Hearing Tests', value: 'Hearing Tests' },
  { name: 'Hemoglobin A1C ', value: 'Hemoglobin A1C ' },
  { name: 'HIV Tests', value: 'HIV Tests' },
  { name: 'Kidney Biopsy ', value: 'Kidney Biopsy ' },
  { name: 'Kidney Function Tests', value: 'Kidney Function Tests' },
  { name: 'Kidney Tests', value: 'Kidney Tests' },
  { name: 'Laparoscopy', value: 'Laparoscopy' },
  { name: 'Liver Function Test', value: 'Liver Function Test' },
  { name: 'Lumbar Puncture', value: 'Lumbar Puncture' },
  {
    name: 'Lung Ventilation/Perfusion Scan',
    value: 'Lung Ventilation/Perfusion Scan',
  },
  {
    name: 'Magnetic Resonance Imaging (MRI Scans)',
    value: 'Magnetic Resonance Imaging (MRI Scans)',
  },
  {
    name: 'Magnetic Resonance Spectroscopy',
    value: 'Magnetic Resonance Spectroscopy',
  },
  { name: 'Malabsorption Test', value: 'Malabsorption Test' },
  { name: 'Mammography', value: 'Mammography' },
  { name: 'Mediastinoscopy', value: 'Mediastinoscopy' },
  { name: 'Myelography', value: 'Myelography' },
  { name: 'Nasopharyngolaryngoscopy', value: 'Nasopharyngolaryngoscopy' },
  { name: 'Nuclear Scans', value: 'Nuclear Scans' },
  { name: 'Palpation', value: 'Palpation' },
  { name: 'Pap Smear', value: 'Pap Smear' },
  { name: 'Paternity Testing ', value: 'Paternity Testing ' },
  { name: 'Percussion', value: 'Percussion' },
  { name: 'PET Scans ', value: 'PET Scans ' },
  { name: 'Phenolsulfonphthalein Test', value: 'Phenolsulfonphthalein Test' },
  { name: 'Phonocardiography', value: 'Phonocardiography' },
  { name: 'Pregnancy Test', value: 'Pregnancy Test' },
  {
    name: 'Preimplantation Genetic Diagnosis',
    value: 'Preimplantation Genetic Diagnosis',
  },
  { name: 'Prenatal Testing', value: 'Prenatal Testing' },
  { name: 'Protein-Bound Iodine Test', value: 'Protein-Bound Iodine Test' },
  { name: 'Pulmonary Function Test', value: 'Pulmonary Function Test' },
  { name: 'Radionuclide Scans ', value: 'Radionuclide Scans ' },
  { name: 'Roentgen Rays ', value: 'Roentgen Rays ' },
  { name: 'Rubin’s Test', value: 'Rubin’s Test' },
  { name: 'Semen Analysis', value: 'Semen Analysis' },
  { name: 'Sigmoidoscopy ', value: 'Sigmoidoscopy ' },
  { name: 'Skin Test', value: 'Skin Test' },
  { name: 'Speech/Language Tests', value: 'Speech/Language Tests' },
  { name: 'Syphilis Test', value: 'Syphilis Test' },
  { name: 'Thoracentesis', value: 'Thoracentesis' },
  { name: 'Thyroid Function Test', value: 'Thyroid Function Test' },
  { name: 'Thyroid Tests', value: 'Thyroid Tests' },
  { name: 'Tomography', value: 'Tomography' },
  { name: 'Toxicological Examination', value: 'Toxicological Examination' },
  { name: 'Toxicology Test', value: 'Toxicology Test' },
  { name: 'Ultrasound', value: 'Ultrasound' },
  { name: 'Urinalysis/Uroscopy', value: 'Urinalysis/Uroscopy' },
  { name: 'Urography', value: 'Urography' },
  { name: 'Uroscopy', value: 'Uroscopy' },
  { name: 'Vision Tests', value: 'Vision Tests' },
  { name: 'X-Rays', value: 'X-Rays' },
]

export const BodyPart = [
  { name: 'Hair', value: 'Hair' },
  { name: 'Head', value: 'Head' },
  { name: 'Ears', value: 'Ears' },
  { name: 'Eyes', value: 'Eyes' },
  { name: 'Nose', value: 'Nose' },
  { name: 'Mouth', value: 'Mouth' },
  { name: 'Chin', value: 'Chin' },
  { name: 'Neck', value: 'Neck' },
  { name: 'Shoulders', value: 'Shoulders' },
  { name: 'Chest', value: 'Chest' },
  { name: 'Back', value: 'Back' },
  { name: 'Arm(s)', value: 'Arm(s)' },
  { name: 'Hand(s)', value: 'Hand(s)' },
  { name: 'Stomach', value: 'Stomach' },
  { name: 'Leg(s)', value: 'Leg(s)' },
  { name: 'Foot', value: 'Foot' },
  { name: 'Toes', value: 'Toes' },
]

export const DoctorSpeciality = [
  {
    name: 'Allergy and immunology',
    value: 'Allergy and immunology',
  },
  {
    name: 'Anesthesiology',
    value: 'Anesthesiology',
  },
  {
    name: 'Dermatology',
    value: 'Dermatology',
  },
  {
    name: 'Diagnostic radiology',
    value: 'Diagnostic radiology',
  },
  {
    name: 'Emergency medicine',
    value: 'Emergency medicine',
  },
  {
    name: 'Family medicine',
    value: 'Family medicine',
  },
  {
    name: 'Internal medicine',
    value: 'Internal medicine',
  },
  {
    name: 'Medical genetics',
    value: 'Medical genetics',
  },
  {
    name: 'Neurology',
    value: 'Neurology',
  },
  {
    name: 'Nuclear medicine',
    value: 'Nuclear medicine',
  },
  {
    name: 'Obstetrics and gynecology',
    value: 'Obstetrics and gynecology',
  },
  {
    name: 'Ophthalmology',
    value: 'Ophthalmology',
  },
  {
    name: 'Pathology',
    value: 'Pathology',
  },
  {
    name: 'Pediatrics',
    value: 'Pediatrics',
  },
  {
    name: 'Physical medicine and rehabilitation',
    value: 'Physical medicine and rehabilitation',
  },
  {
    name: 'Preventive medicine',
    value: 'Preventive medicine',
  },
  {
    name: 'Psychiatry',
    value: 'Psychiatry',
  },
  {
    name: 'Radiation oncology',
    value: 'Radiation oncology',
  },
  {
    name: 'Surgery',
    value: 'Surgery',
  },
  {
    name: 'Urology',
    value: 'Urology',
  },
]

export const visionIssueOptions = [
  { name: 'Blind in my left eye', value: 'Blind in my left eye' },
  { name: 'Blind in my right eye', value: 'Blind in my right eye' },
  { name: 'Blurry vision', value: 'Blurry vision' },
  { name: 'Double vision', value: 'Double vision' },
  { name: 'Floaters', value: 'Floaters' },
  { name: 'Lack of depth perception', value: 'Lack of depth perception' },
  { name: 'Lack of peripheral vision', value: 'Lack of peripheral vision' },
  {
    name: 'Statutory blindness (i.e., legally blind)',
    value: 'Statutory blindness (i.e., legally blind)',
  },
  { name: 'Seeing halos', value: 'Seeing halos' },
  { name: 'Sensitivity to light', value: 'Sensitivity to light' },
  {
    name: 'Total blindness in both eyes',
    value: 'Total blindness in both eyes',
  },
  { name: 'Other', value: 'Other' },
]

// U.S. States, U.S. Territories, and Armed Forces Regions
export const UsStates = [
  { name: 'Alabama', value: 'Alabama' },
  { name: 'Alaska', value: 'Alaska' },
  { name: 'Arizona', value: 'Arizona' },
  { name: 'Arkansas', value: 'Arkansas' },
  { name: 'California', value: 'California' },
  { name: 'Colorado', value: 'Colorado' },
  { name: 'Connecticut', value: 'Connecticut' },
  { name: 'Delaware', value: 'Delaware' },
  { name: 'Florida', value: 'Florida' },
  { name: 'Georgia', value: 'Georgia' },
  { name: 'Hawaii', value: 'Hawaii' },
  { name: 'Idaho', value: 'Idaho' },
  { name: 'Illinois', value: 'Illinois' },
  { name: 'Indiana', value: 'Indiana' },
  { name: 'Iowa', value: 'Iowa' },
  { name: 'Kansas', value: 'Kansas' },
  { name: 'Kentucky', value: 'Kentucky' },
  { name: 'Louisiana', value: 'Louisiana' },
  { name: 'Maine', value: 'Maine' },
  { name: 'Maryland', value: 'Maryland' },
  { name: 'Massachusetts', value: 'Massachusetts' },
  { name: 'Michigan', value: 'Michigan' },
  { name: 'Minnesota', value: 'Minnesota' },
  { name: 'Mississippi', value: 'Mississippi' },
  { name: 'Missouri', value: 'Missouri' },
  { name: 'Montana', value: 'Montana' },
  { name: 'Nebraska', value: 'Nebraska' },
  { name: 'Nevada', value: 'Nevada' },
  { name: 'New Hampshire', value: 'New Hampshire' },
  { name: 'New Jersey', value: 'New Jersey' },
  { name: 'New Mexico', value: 'New Mexico' },
  { name: 'New York', value: 'New York' },
  { name: 'North Carolina', value: 'North Carolina' },
  { name: 'North Dakota', value: 'North Dakota' },
  { name: 'Ohio', value: 'Ohio' },
  { name: 'Oklahoma', value: 'Oklahoma' },
  { name: 'Oregon', value: 'Oregon' },
  { name: 'Pennsylvania', value: 'Pennsylvania' },
  { name: 'Rhode Island', value: 'Rhode Island' },
  { name: 'South Carolina', value: 'South Carolina' },
  { name: 'South Dakota', value: 'South Dakota' },
  { name: 'Tennessee', value: 'Tennessee' },
  { name: 'Texas', value: 'Texas' },
  { name: 'Utah', value: 'Utah' },
  { name: 'Vermont', value: 'Vermont' },
  { name: 'Virginia', value: 'Virginia' },
  { name: 'Washington', value: 'Washington' },
  { name: 'West Virginia', value: 'West Virginia' },
  { name: 'Wisconsin', value: 'Wisconsin' },
  { name: 'Wyoming', value: 'Wyoming' },
  { name: 'American Samoa', value: 'American Samoa' },
  { name: 'District of Columbia', value: 'District of Columbia' },
  { name: 'Guam', value: 'Guam' },
  { name: 'Northern Mariana Islands', value: 'Northern Mariana Islands' },
  { name: 'Puerto Rico', value: 'Puerto Rico' },
  { name: 'U.S. Minor Outlying Islands', value: 'U.S. Minor Outlying Islands' },
  { name: 'U.S. Virgin Islands', value: 'U.S. Virgin Islands' },
  { name: 'Armed Forces Americas', value: 'Armed Forces Americas' },
  {
    name: 'Armed Forces Europe, Canada, Middle East, Africa',
    value: 'Armed Forces Europe, Canada, Middle East, Africa',
  },
  { name: 'Armed Forces Pacific', value: 'Armed Forces Pacific' },
]

export const currentYear: number = new Date().getFullYear()
export const previousYear: number = currentYear - 1
export const nextYear: number = currentYear + 1
